<template>
  <vx-card title="Off Route">
    <div class="vx-row">
      <!-- title -->
      <div class="vx-col">
        <h4>Filter</h4>
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Collection Type</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" value="Off Route" disabled />
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Operating Unit</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="selectedOperatingUnit"
          :options="optionOperatingUnit"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :allow-empty="false"
          @select="onSelectOperatingUnit"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="selectedTerritory"
          :options="optionTerritory"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :allow-empty="false"
          @select="onSelectTerritory"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="selectedCustomers"
          :options="optionCustomers"
          :multiple="true"
          placeholder="Type to search customer"
          track-by="id"
          :custom-label="customLabelCustomer"
          :max-height="125"
          :allow-empty="true"
          @search-change="onSearchSelectCustomers"
        />
      </div>
    </div>

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Invoice Code</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vue-tags-input
          v-model="invoice_code"
          :tags="invoice_codes"
          placeholder="search by code"
          @tags-changed="(newValues) => (invoice_codes = newValues)"
        />
      </div>
    </div>

    <!--  -->
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Search by Keyword</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="searching_bar" placeholder="search by customer code or name or invoice number" />
      </div>
    </div>
    <!--  -->

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Due Date</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="date" v-model="due_date" />
      </div>
    </div>

    <div class="vx-row my-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Set Collection Date</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="date" v-model="date" />
      </div>
    </div>

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col">
        <vs-button color="primary" @click="onSubmitFilter()">
          Filter
        </vs-button>
      </div>
      <div class="vx-col">
        <vs-button color="warning" @click="resetFilter"> Reset </vs-button>
      </div>
    </div>

    <hr />
    <div class="vx-row mb-6 w-1/2 mt-4">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Total Value</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input
          class="w-full"
          readonly
          :value="formatNumberV2(total_value)"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="core vx-col w-full mb-base">
        <!-- search bar -->
        <!-- <div>
          <vs-input
            class="w-1/2 my-2"
            placeholder="search by customer code or name or invoice number"
            v-model="searchBar"
          />
        </div> -->
        <div>
          <vs-table
            stripe
            border
            description
            :data="table.data"
            :max-items="table.data.length"
            search
            :total="table.total"
            :sst="false"
            multiple
            v-model="selecteds"
            @search="onSearchTable"
            @change="handleSelectionChange"
          >
            <template slot="header">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="mb-3 p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{ this.table.start }} - {{ this.table.length }} of
                    {{ this.table.total }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="item in table.limits"
                    :key="item"
                    @click="handleChangelength(item)"
                  >
                    <span>{{ item }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </template>
            <template slot="thead">
              <vs-th>No</vs-th>
              <vs-th>Customer</vs-th>
              <vs-th>Ship To</vs-th>
              <vs-th>Invoice No</vs-th>
              <vs-th>Invoice Date</vs-th>
              <vs-th>Due Date</vs-th>
              <vs-th>Invoice Value</vs-th>
              <vs-th>Remaining Value</vs-th>

              <!-- <vs-th><vs-checkbox /> Invoice</vs-th> -->
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ table.row_number_start + indextr }}</vs-td>
                <vs-td>{{ tr.customer }}</vs-td>
                <vs-td>{{ tr.ship_to }}</vs-td>
                <vs-td>{{ tr.invoice_no }}</vs-td>
                <vs-td>{{ tr.invoice_date | formatDate }}</vs-td>
                <vs-td>{{ tr.due_date | formatDate }}</vs-td>
                <vs-td>{{ tr.invoice_value | formatNumber }}</vs-td>
                <vs-td>{{ tr.remaining_value | formatNumber }}</vs-td>
                <!-- <vs-td
                  ><vs-checkbox
                    v-model="tr.use_invoice"
                    :val="tr.id"
                    :key="indextr"
                    :name="indextr"
                  ></vs-checkbox
                ></vs-td> -->
              </vs-tr>
            </template>
          </vs-table>

          <vs-pagination
            style="padding-top: 5px"
            :total="table.totalPage"
            v-model="setPage"
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Notes</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-textarea v-model="notes" />
      </div>
    </div>

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Assign to Sales</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="selectedSales"
          :options="optionSales"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :allow-empty="false"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="vx-row mt-">
      <div class="centerx">
        <vs-alert
          :active.sync="activeAlert"
          closable
          close-icon="close"
          color="danger"
        >
          {{ alertMessage }}
        </vs-alert>
      </div>
    </div>

    <div class="vx-row mt-4">
      <div class="vs-col">
        <vs-button
          @click="onSaveForm"
          size="medium"
          color="success"
          icon-pack="feather"
          icon="icon-check"
          style="margin-right: 5px"
          >Save</vs-button
        >
      </div>
      <div class="vs-col">
        <vs-button
          @click="() => $router.push('/collection-plan-route')"
          size="medium"
          color="danger"
          icon-pack="feather"
          icon="icon-x"
          style="margin-right: 5px"
          >Close</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import { formatNumber, uniqueArray } from "../../../../utils/common";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: {
    VueTagsInput,
  },
  data() {
    return {
      activeAlert: false,
      alertMessage: "",
      selectedOperatingUnit: null,
      optionOperatingUnit: [],
      selectedTerritory: null,
      optionTerritory: [],
      selectedSales: null,
      optionSales: [],
      date: null,
      allChecked: false,

      table: this.tableDefaultState(),
      baseUrl: "/api/sfa/v1/collection-plan/list-route",
      selecteds: [],

      all_billing_order_ids: [],
      check_billing_order_ids: [],
      is_show_all_first: true,
      total_value: 0,
      selectedRow: true,
      selectedInvoice: null,
      selectedFaktur: null,
      due_date: null,
      searching_bar: "",
      invoice_code: "",
      invoice_codes: [],

      // select customer
      selectedCustomers: [],
      optionCustomers: [],

      // search bar
      searchBar: "",
      notes: "",
      isCheckAll: false,
    };
  },
  mounted() {
    this.getOptionOperatingUnit({
      length: 0,
    });
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    tablesData() {
      if (this.searchBar) {
        // return this.table.data.filter((item) => {
        //   if (
        //     item.customer
        //       .toLowerCase()
        //       .includes(this.searchBar.toLowerCase()) ||
        //     item.invoice_no.toLowerCase().includes(this.searchBar.toLowerCase())
        //   ) {
        //     return item;
        //   }
        // });
        console.log("this.searchBar", this.searchBar)
        this.handleSearch(this.searchBar);
        return false;
      } else {
        // return this.table.data;
        console.log("this.searchBar", this.searchBar)
        this.handleSearch("");
        return false;
      }
    },
    checkAll: {
      get() {
        return this.selectedRow;
      },
      set(value) {
        this.selectedRow = value;
        if (value) {
          this.table.data.forEach((item) => {
            item.check = true;
          });
        } else {
          this.table.data.forEach((item) => {
            item.check = false;
          });
        }
      },
    },
    checkAllInvoice: {
      get() {
        return this.selectedInvoice;
      },
      set(value) {
        this.selectedInvoice = value;
        if (value) {
          this.table.data.forEach((item) => {
            item.use_invoice = true;
          });
        } else {
          this.table.data.forEach((item) => {
            item.use_invoice = false;
          });
        }
      },
    },
    checkAllFaktur: {
      get() {
        return this.selectedFaktur;
      },
      set(value) {
        this.selectedFaktur = value;
        if (value) {
          this.table.data.forEach((item) => {
            item.use_faktur = true;
          });
        } else {
          this.table.data.forEach((item) => {
            item.use_faktur = false;
          });
        }
      },
    },
  },
  methods: {
    onSearchTable(query) {
      let params = this.paramsGetData();
      params.search = query;
      params.page = 1;
      this.getData(params);
    },
    handleSelected(row) {
      let id = row.id;
      // check if exist on check_billing_order_ids do remove, else push id
      if (this.check_billing_order_ids.includes(id)) {
        this.check_billing_order_ids = this.check_billing_order_ids.filter(
          (item) => item !== id
        );
        console.log("remove id ", id);
      } else {
        this.check_billing_order_ids.push(id);
        console.log("push id ", id);
      }
    },
    resetFilter() {
      this.selectedOperatingUnit = null;
      this.optionTerritory = [];
      this.selectedSales = null;
      this.optionSales = [];
      this.date = null;
      this.allChecked = null;
      this.table = this.tableDefaultState();
      this.all_billing_order_ids = [];
      this.check_billing_order_ids = [];
      this.total_value = 0;
      this.selectedRow = true;
      this.selectedInvoice = null;
      this.selectedFaktur = null;
      this.due_date = null;
      this.searching_bar = "";
      this.invoice_code = null;
      this.selectedCustomers = [];
      this.optionCustomers = [];
    },
    onSearchSelectCustomers(query) {
      this.setSelectedCustomer({
        territory_id: this.selectedTerritory.territory_id,
        search: query,
      });
    },
    setSelectedCustomer(params) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/customer-address/list", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.optionCustomers = resp.data.records;
            } else {
              this.optionCustomers = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    async onSaveForm() {
      this.activeAlert = false;
      const data = {
        date: this.date,
        type: 2,
        territory_id: this.selectedTerritory.territory_id,
        sales_personal_id: this.selectedSales.personal_id,
        notes: this.notes,
      };

      data.all_billing_order_ids = this.all_billing_order_ids;

      let routes = [];
      let check_billing_order_ids = [];
      this.selecteds.forEach((item) => {
        let route = {
          id: item.id,
          use_invoice: true,
          use_faktur: true,
        };
        if (!routes.some(r => r.id === route.id)) {
          routes.push(route);
        }
        check_billing_order_ids.push(item.id);
      });
      check_billing_order_ids = uniqueArray(check_billing_order_ids);

      data.check_billing_order_ids = check_billing_order_ids;
      data.routes = routes;

      if (this.table.data.length == this.selecteds.length) {
        data.check_billing_order_ids = data.all_billing_order_ids;
        data.routes = [];
        data.routes = data.all_billing_order_ids.map((val) => {
          return {
            id: val,
            use_invoice: true,
            use_faktur: true,
          };
        });
      }

      this.$vs.loading();

      const response = await this.$http.post(
        "/api/sfa/v1/collection-plan/save-route",
        data
      );

      if (response.code === 200) {
        this.$vs.notify({
          title: "Success",
          text: "Save Success",
          color: "success",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
        this.$vs.loading.close();
        this.$router.push("/collection-plan-route");
      } else {
        // check message contains "because salesman have invoice"
        if (response.message.includes("because salesman have invoice")) {
          this.activeAlert = true;
          this.alertMessage = response.message;
        } else {
          this.$vs.notify({
            title: "Error",
            text: response.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
        this.$vs.loading.close();
      }
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    async onSubmitFilter() {
      this.check_billing_order_ids = [];
      this.selecteds = [];
      this.activeAlert = false;
      if (this.date == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Set Collection Date is required",
          color: "warning",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      if (this.selectedTerritory == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Set Territory is required",
          color: "warning",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      this.is_show_all_first = true
      let params = this.paramsGetData();
      params.page = 1;

      this.getData(params);
    },
    paramsGetData() {
      this.check_billing_order_ids = [];

      let params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        territory_id: this.selectedTerritory.territory_id,
        dafin_type: "off-route",
        date: this.date,
        is_show_all_first: this.is_show_all_first,
      };

      if (this.due_date != null) {
        params.due_date = this.due_date;
      }

      if (this.searching_bar != "") {
        params.search = this.searching_bar;
      }

      if (this.selectedTerritory != null) {
        params.territory_id = this.selectedTerritory.territory_id;
      }

      if (this.selectedCustomers.length > 0) {
        params.customer_ids = this.selectedCustomers.map(
          (item) => item.customer_id
        );
      }

      if (this.invoice_code != null) {
        params.invoice_code = this.invoice_code;
      }

      if (this.invoice_codes.length > 0) {
        params.invoice_codes = this.invoice_codes.map((item) => item.text);
      }

      return params;
    },

    async onSearchChangeOperatingUnit(query) {
      if (query.length >= 3) {
        let response = await this.getOptionOperatingUnit({
          params: {
            search: query,
          },
        });
        if (response.data.records != null) {
          this.optionOperatingUnit = response.data.records;
        }
      } else {
        this.optionOperatingUnit = [];
      }
    },
    async getOptionSales(params) {
      this.$vs.loading();
      const response = await this.$http.get(
        "/api/sfa/v1/visit-plan-generate/sales",
        params
      );

      this.optionSales = response.data.records ? response.data.records : [];
      this.$vs.loading.close();
    },
    async onSelectTerritory(data) {
      const territory_id = data.territory_id;

      this.setSelectedCustomer({
        territory_id: territory_id,
      });

      // set sales option
      this.getOptionSales({
        params: {
          territory_id: territory_id,
        },
      });
    },
    async getOptionTerritory(params) {
      this.$vs.loading();
      const response = await this.$http.get(
        "/api/sfa/v1/territory/operating-unit-territories",
        params
      );
      this.optionTerritory = response.data.records;
      this.$vs.loading.close();
    },
    async onSelectOperatingUnit(data) {
      const operating_unit_id = data.id;
      await this.getOptionTerritory({
        params: {
          operating_unit_id: operating_unit_id,
        },
      });
    },
    async getOptionOperatingUnit(params) {
      this.$vs.loading();
      const response = await this.$http.get("/api/sfa/v1/operating-unit", {
        params: params,
      });
      this.optionOperatingUnit = response.data.records;
      this.$vs.loading.close();
    },
    customLabelCustomer(option) {
      if (option) {
        return `${option.customer_code} - ${option.name}`;
      }
    },

    // table needs
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.date == null) {
        return;
      }

      if(page==1) {
        this.is_show_all_first = true
      } else {
        this.is_show_all_first = false
      }
      let params = this.paramsGetData();

      params.length = this.table.length;
      params.page = this.table.page;
      params.search = this.table.search == "" ? this.searching_bar : this.table.search;
      params.dafin_type = "off-route";
      params.date = this.date;

      if (this.selectedTerritory != null) {
        params.territory_id = this.selectedTerritory.territory_id;
      }

      this.getData(params);
    },
    handleSearch(searching) {
      console.log("this.searchBar", this.searchBar)
      console.log("searching", searching)

      this.table.search = searching;
      this.table.page = 1;
      let params = this.paramsGetData();
      params.length = this.table.length;
      params.search = this.table.search == "" ? this.searching_bar : this.table.search;
      params.dafin_type = "off-route";
      params.date = this.date;
      params.territory_id = this.selectedTerritory.territory_id;
      console.log("params", params)
      this.getData(params);
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      let params = this.paramsGetData();
      params.length = this.table.length;
      params.page = this.table.page;
      params.search = this.table.search == "" ? this.searching_bar : this.table.search;
      params.dafin_type = "off-route";
      params.date = this.date;
      params.territory_id = this.selectedTerritory.territory_id;

      this.getData(params);
    },
    getDataAll(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.status == "OK") {
            if (resp.data.records.routes == null) {
              this.table.data = [];
            } else {
              let dataAll = resp.data.records.routes;
              // this.selecteds = this.table.data;
              if (resp.data.records.is_show_all_first) {
                this.all_billing_order_ids = resp.data.records.all_billing_order_ids;
              }

              this.selecteds = dataAll;
            }
            this.setStartEnd();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          }

          this.$vs.loading.close();
        });
    },
    getData(params) {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.row_number_start = resp.data.row_number_start;
            if (resp.data.records.routes == null) {
              this.table.data = [];
            } else {
              this.table.data = resp.data.records.routes;
              // this.selecteds = this.table.data;
              if (resp.data.records.is_show_all_first) {
                this.all_billing_order_ids = resp.data.records.all_billing_order_ids;
                this.total_value = 0
              }

              this.selecteds.forEach(selectedRow => {
                const matchingRow = this.table.data.find(row => row.id === selectedRow.id);
                if (matchingRow) {
                  matchingRow.selected = true; // Mark as selected if already in `selecteds`
                }
              });
            }

            if (this.isCheckAll) {
              this.selecteds = this.table.data;
            }

            this.setStartEnd();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          }

          this.$vs.loading.close();
        });
    },
    formatNumberV2(value) {
      if (value) {
        return formatNumber(value);
      }
    },
    handleSelectionChange(selectedRowsOnPage) {
      // Remove deselected rows from `selecteds` (i.e., rows not present in the current selection)
      console.log("selectedRowsOnPage", selectedRowsOnPage)
      console.log("selecteds before", this.selecteds)
      this.selecteds = this.selecteds.filter(row =>
      selectedRowsOnPage.includes(row) || !this.table.data.some(dataRow => dataRow.id === row.id)
    );
    console.log("selecteds After", this.selecteds)

      // Add newly selected rows from the current page
      selectedRowsOnPage.forEach(row => {
        if (!this.selecteds.some(selectedRow => selectedRow.id === row.id)) {
          this.selecteds.push(row); // Add new selections
        }
      });
    },
  },
  filters: {
    formatNumber(value) {
      if (value) {
        return formatNumber(value);
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
  watch: {
    selecteds: function (newValue, oldValue) {
      console.log("oldValue", oldValue)
      console.log("newValue", newValue)
      // if(this.is_show_all_first) {
      //   return
      // }
      // if(oldValue.length == 0) {
      //   return
      // }
      // let totalOld = 0;
      // oldValue.forEach((item) => {
      //   totalOld += item.remaining_value;
      // });

      // let totalNew = 0;
      // newValue.forEach((item) => {
      //   totalNew += item.remaining_value;
      // });

      // let margin = totalNew - totalOld
      // this.total_value += margin;
    },
    checkAllInvoice: function (val) {
      if (this.table.data != null) {
        this.table.data.forEach((item) => {
          item.use_invoice = val;
        });
      }
    },
  },
};
</script>
