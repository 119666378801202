<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th
          ><vs-checkbox
            v-model="selectAll"
            @input="handleSelectAll"
          />ALL</vs-th
        >
        <vs-th>Action</vs-th>
        <vs-th sort-key="code">Code</vs-th>
        <vs-th sort-key="flag">Flag</vs-th>
        <vs-th sort-key="valid_from">Valid From - To</vs-th>
        <vs-th sort-key="name_territory">Territory</vs-th>
        <vs-th sort-key="type_salesman">Salesman Type</vs-th>
        <vs-th sort-key="customer_category">Distance Channel</vs-th>
        <vs-th v-if="this.flag == 1" sort-key="territory">Customer Group</vs-th>
        <vs-th v-if="this.flag == 2" sort-key="territory"
          >Customer Group 4</vs-th
        >
        <vs-th sort-key="name_work_positions">Salesman Subtype </vs-th>
        <vs-th sort-key="created_at">Created On</vs-th>
        <vs-th sort-key="created_by">Created By</vs-th>
        <vs-th sort-key="updated_at">Updated On</vs-th>
        <vs-th sort-key="updated_by">Updated By</vs-th>
        <vs-th sort-key="status">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-checkbox
                v-model="exportID"
                color="success"
                :vs-value="data[indextr].code"
                @input="$emit('selected-items', exportID)"
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                @click.stop="handleEdit(data[indextr].id)"
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-edit"
                title="Edit"
                style="margin-right: 5px"
              />
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <vs-td :data="data[indextr].flag">
            {{ data[indextr].flag == 1 ? "Product Focus" : "New Product" }}
          </vs-td>
          <vs-td :data="data[indextr].valid_from">
            {{ data[indextr].valid_from | formatDateValidFromTo }}-{{
              data[indextr].valid_to | formatDateValidFromTo
            }}
          </vs-td>
          <vs-td :data="data[indextr].name_territory">
            {{
              data[indextr].name_territory
                ? data[indextr].name_territory
                : "ALL"
            }}
          </vs-td>
          <vs-td :data="data[indextr].type_salesman">
            {{
              data[indextr].type_salesman ? data[indextr].type_salesman : "ALL"
            }}
          </vs-td>
          <vs-td :data="data[indextr].customer_category">
            {{
              data[indextr].customer_category
                ? data[indextr].customer_category
                : "ALL"
            }}
          </vs-td>
          <vs-td v-if="data[indextr].flag == 1" :data="data[indextr].group1">
            <tr class="d-flex">
              <div>
                Group 1:
                {{ data[indextr].group1 ? data[indextr].group1 : "ALL" }}
              </div>
              <div>
                Group 2:
                {{ data[indextr].group2 ? data[indextr].group2 : "ALL" }}
              </div>
              <div>
                Group 3:
                {{ data[indextr].group3 ? data[indextr].group3 : "ALL" }}
              </div>
            </tr>
          </vs-td>
          <vs-td v-if="data[indextr].flag == 2" :data="data[indextr].group4">
            {{ data[indextr].group4 ? data[indextr].group4 : "ALL" }}
          </vs-td>
          <vs-td :data="data[indextr].name_work_positions">
            {{
              data[indextr].name_work_positions
                ? data[indextr].name_work_positions
                : "ALL"
            }}
          </vs-td>

          <vs-td :data="data[indextr].created_at">
            {{ data[indextr].created_at | formatDate }}
          </vs-td>
          <vs-td :data="data[indextr].created_by">
            {{ data[indextr].created_by }}
          </vs-td>
          <vs-td :data="data[indextr].updated_at">
            {{ data[indextr].updated_at | formatDate }}
          </vs-td>
          <vs-td :data="data[indextr].updated_by">
            {{ data[indextr].updated_by }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import internal from "stream";
import moment from "moment";

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
    flag: {
      type: Number,
    },
    territoryID: {
      type: Array,
    },
    status: {
      type: String,
    },
    validitySt: {
      type: Date,
    },
    validityEd: {
      type: Date,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      itemDel: 0,
      exportID: [],
      showingData: true,
      selectAll: false,
      IDItem: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleCreate() {
      this.customerId = null;
      this.detail = true;
    },
    getData() {
      if (this.validitySt) {
        this.validitySt = moment(this.validitySt).format("YYYY-MM-DD");
      }
      if (this.validityEd) {
        this.validityEd = moment(this.validityEd).format("YYYY-MM-DD");
      }
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/getAll", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            flag: this.flag,
            territory_id: this.territoryID,
            status: this.status,
            validitySt: this.validitySt,
            validityEd: this.validityEd,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.IDItem = [];
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            for (let index = 0; index < resp.data.records.length; index++) {
              this.IDItem.push(resp.data.records[index].code);
            }
            console.log(resp.data.records);
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(i) {
      this.itemDel = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      console.log(this.table.data[this.itemDel].territory_id);
      this.$http
        .delete(this.baseUrl + "/delete", {
          params: {
            territory_id: this.table.data[this.itemDel].territory_id,
            work_position_id: this.table.data[this.itemDel].work_position_id,
            flag: this.table.data[this.itemDel].flag,
            company_id: this.$store.state.user.currentUser.company_id,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      console.log("aaaaaaaaa", id);
      this.$router.push({
        name: "product-flag-edit",
        params: { id: id },
      });
    },
    handleSelectAll(id) {
      console.log("yyy", id);
      console.log("yyy", this.exportID);
      if (id == false) {
        this.exportID = [];
      } else {
        this.exportID = this.IDItem;
      }
      this.$emit("selected-items", this.IDItem);
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        // return moment(String(value)).format("DD MMM YYYY,hh:mm:ss");
        return moment(value)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
    formatDateValidFromTo(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  watch: {
    isFilter(val) {
      if (val) {
        this.getData();
        this.$emit("isFilters", false);
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
