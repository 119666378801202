<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <vs-button
        color="success"
        icon-pack="feather"
        icon="icon-filter"
        @click="getData()"
      >Get Data</vs-button>
      <vs-button
        class="ml-5"
        color="success"
        icon-pack="feather"
        icon="icon-download"
        @click="doExport('ori')"
      >Export</vs-button>
      <vs-button
        class="ml-5"
        color="success"
        icon-pack="feather"
        icon="icon-download"
        @click="doExport('sap')"
      >Export for SAP</vs-button>
    </div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      :buttonSearch="true"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th sort-key="branch_code">Branch Code</vs-th>
        <vs-th sort-key="salesman_code">Salesman Code</vs-th>
        <vs-th sort-key="distribution_channel">Distribution Channel</vs-th>
        <vs-th sort-key="account_group">Account Group</vs-th>
        <vs-th sort-key="noo_id">NOO ID</vs-th>
        <vs-th sort-key="code_external">Code External</vs-th>
        <vs-th sort-key="sold_to">Sold To</vs-th>
        <vs-th sort-key="ship_to">Ship To</vs-th>
        <vs-th sort-key="outlet_name">Outlet Name</vs-th>
        <vs-th sort-key="legal_name">Legal Name</vs-th>
        <vs-th sort-key="contact_person">Contact Person</vs-th>
        <vs-th sort-key="rt_rw">RTRW</vs-th>
        <vs-th sort-key="street">Street</vs-th>
        <vs-th sort-key="street_no">Street No</vs-th>
        <vs-th sort-key="province_code">Province Code</vs-th>
        <vs-th sort-key="kotamadya">Kotamadya</vs-th>
        <vs-th sort-key="kecamatan">Kecamatan</vs-th>
        <vs-th sort-key="kelurahan">Kelurahan</vs-th>
        <vs-th sort-key="postal_code">Postal Code</vs-th>
        <vs-th sort-key="city">City</vs-th>
        <vs-th sort-key="longitude">Longitude</vs-th>
        <vs-th sort-key="latitude">Latitude</vs-th>
        <vs-th sort-key="telp">Telp</vs-th>
        <vs-th sort-key="ext">Ext</vs-th>
        <vs-th sort-key="hp">HP</vs-th>
        <vs-th sort-key="email">Email</vs-th>
        <vs-th sort-key="npwp">NPWP</vs-th>
        <vs-th sort-key="npwp_new">NPWP 16 Digits</vs-th>
        <vs-th sort-key="customer_group">Customer Group</vs-th>
        <vs-th sort-key="customer_group1">Customer Group1</vs-th>
        <vs-th sort-key="customer_group2">Customer Group2</vs-th>
        <vs-th sort-key="customer_group3">Customer Group3</vs-th>
        <vs-th sort-key="customer_group4">Customer Group4</vs-th>
        <vs-th sort-key="payment_type">Payment Type</vs-th>
        <vs-th sort-key="payment_term">Payment Term</vs-th>
        <vs-th sort-key="credit_limit_amount">Credit Limit Amount</vs-th>
        <vs-th sort-key="incoterm">Incoterm</vs-th>
        <vs-th sort-key="risk_category">Risk Category</vs-th>
        <vs-th sort-key="ktp">KTP</vs-th>
        <vs-th sort-key="pkp_non_pkp">PKP NON PKP</vs-th>
        <vs-th sort-key="faktur_pajak_address">Faktur Pajak Address</vs-th>
        <vs-th sort-key="final_status">Final Status</vs-th>
        <vs-th sort-key="customer_number">Customer Number</vs-th>
        <vs-th sort-key="spv">SPV</vs-th>
        <vs-th sort-key="approved_date_spv">Approved Date</vs-th>
        <vs-th sort-key="remark_spv">Remark</vs-th>
        <vs-th sort-key="bm">BM</vs-th>
        <vs-th sort-key="approved_date_bm">Approved Date</vs-th>
        <vs-th sort-key="remark_bm">Remark</vs-th>
        <vs-th sort-key="bc">BC</vs-th>
        <vs-th sort-key="approved_date_bc">Approved Date</vs-th>
        <vs-th sort-key="remark_bc">Remark</vs-th>
        <vs-th sort-key="cr">CR</vs-th>
        <vs-th sort-key="approved_date_cr">Approved Date</vs-th>
        <vs-th sort-key="remark_cr">Remark</vs-th>
        <vs-th sort-key="created_date">Created Date</vs-th>
        <vs-th sort-key="status_download">Status Download</vs-th>
        <vs-th sort-key="status_data">NOO Status</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.branch_code">{{ tr.branch_code }}</vs-td>
          <vs-td :data="tr.salesman_code">{{ tr.salesman_code }}</vs-td>
          <vs-td :data="tr.distribution_channel">{{ tr.distribution_channel }}</vs-td>
          <vs-td :data="tr.account_group">{{ tr.account_group }}</vs-td>
          <vs-td :data="tr.noo_id">{{ tr.noo_id }}</vs-td>
          <vs-td :data="tr.code_external">{{ tr.code_external }}</vs-td>
          <vs-td :data="tr.sold_to">{{ tr.sold_to }}</vs-td>
          <vs-td :data="tr.ship_to">{{ tr.ship_to }}</vs-td>
          <vs-td :data="tr.outlet_name">{{ tr.outlet_name }}</vs-td>
          <vs-td :data="tr.legal_name">{{ tr.legal_name }}</vs-td>
          <vs-td :data="tr.contact_person">{{ tr.contact_person }}</vs-td>
          <vs-td :data="tr.rt_rw">{{ tr.rt_rw }}</vs-td>
          <vs-td :data="tr.street">{{ tr.street }}</vs-td>
          <vs-td :data="tr.street_no">{{ tr.street_no }}</vs-td>
          <vs-td :data="tr.province_code">{{ tr.province_code }}</vs-td>
          <vs-td :data="tr.city">{{ tr.city }}</vs-td>
          <vs-td :data="tr.kecamatan">{{ tr.kecamatan }}</vs-td>
          <vs-td :data="tr.kelurahan">{{ tr.kelurahan }}</vs-td>
          <vs-td :data="tr.postal_code">{{ tr.postal_code }}</vs-td>
          <vs-td :data="tr.city">{{ tr.city }}</vs-td>
          <vs-td :data="tr.longitude">{{ tr.longitude }}</vs-td>
          <vs-td :data="tr.latitude">{{ tr.latitude }}</vs-td>
          <vs-td :data="tr.telp">{{ tr.telp }}</vs-td>
          <vs-td :data="tr.ext">{{ tr.ext }}</vs-td>
          <vs-td :data="tr.hp">{{ tr.hp }}</vs-td>
          <vs-td :data="tr.email">{{ tr.email }}</vs-td>
          <vs-td :data="tr.npwp">{{ tr.npwp }}</vs-td>
          <vs-td :data="tr.npwp_new">{{ tr.npwp_new }}</vs-td>
          <vs-td :data="tr.customer_group">{{ tr.customer_group1 }}</vs-td>
          <vs-td :data="tr.customer_group1">{{ tr.customer_group2 }}</vs-td>
          <vs-td :data="tr.customer_group2">{{ tr.customer_group3 }}</vs-td>
          <vs-td :data="tr.customer_group3">DNA</vs-td>
          <vs-td :data="tr.customer_group4">{{ tr.customer_group4 }}</vs-td>
          <vs-td :data="tr.payment_type">{{ tr.payment_type }}</vs-td>
          <vs-td :data="tr.payment_term">{{ tr.payment_term }}</vs-td>
          <vs-td :data="tr.credit_limit_amount">{{ tr.credit_limit_amount }}</vs-td>
          <vs-td :data="tr.incoterm">{{ tr.incoterm }}</vs-td>
          <vs-td :data="tr.risk_category">{{ tr.risk_category }}</vs-td>
          <vs-td :data="tr.ktp">{{ tr.ktp }}</vs-td>
          <vs-td :data="tr.pkp_non_pkp">{{ tr.pkp_non_pkp }}</vs-td>
          <vs-td :data="tr.faktur_pajak_address">{{ tr.faktur_pajak_address }}</vs-td>
          <vs-td :data="tr.final_status">{{ tr.final_status }}</vs-td>
          <vs-td :data="tr.customer_number">{{ tr.customer_number }}</vs-td>
          <vs-td :data="tr.spv">{{ tr.spv }}</vs-td>
          <vs-td :data="tr.approved_date_spv">{{ tr.approved_date_spv }}</vs-td>
          <vs-td :data="tr.remark_spv">{{ tr.remark_spv }}</vs-td>
          <vs-td :data="tr.bm">{{ tr.bm }}</vs-td>
          <vs-td :data="tr.approved_date_bm">{{ tr.approved_date_bm }}</vs-td>
          <vs-td :data="tr.remark_bm">{{ tr.remark_bm }}</vs-td>
          <vs-td :data="tr.bc">{{ tr.bc }}</vs-td>
          <vs-td :data="tr.approved_date_bc">{{ tr.approved_date_bc }}</vs-td>
          <vs-td :data="tr.remark_bc">{{ tr.remark_bc }}</vs-td>
          <vs-td :data="tr.cr">{{ tr.cr }}</vs-td>
          <vs-td :data="tr.approved_date_cr">{{ tr.approved_date_cr }}</vs-td>
          <vs-td :data="tr.remark_cr">{{ tr.remark_cr }}</vs-td>
          <vs-td :data="tr.created_date">{{ tr.created_date }}</vs-td>
          <vs-td v-if="tr.count_download == 0" :data="tr.count_download">False</vs-td>
          <vs-td v-else :data="tr.count_download">True</vs-td>
          <vs-td :data="tr.status_data">{{ tr.status_data }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    customerCategoryId: {
      type: Number,
    },
    customerCategoryName: {
      type: String,
    },
    territoryId: {
      type: Array,
    },
    territoryCode: {
      type: Array,
    },
    dateFrom: {
      type: Date,
    },
    dateTo: {
      type: Date,
    },
    statusApproval: {
      type: String,
    },
    statusApprovalName: {
      type: String,
    },
    statusDownload: {
      type: String,
    },
    statusDownloadName: {
      type: String,
    }
  },
  data() {
    return {
      baseUrl: "/api/oms/v1/sfa-master",
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/report-noo", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            customer_category_id: this.customerCategoryId,
            territory_id: this.territoryId.toString(),
            date_from: this.dateFrom == null ? "0001-01-01" : moment(this.dateFrom).format("YYYY-MM-DD"),
            date_to: this.dateTo == null ? "0001-01-01" : moment(this.dateTo).format("YYYY-MM-DD"),
            status_approval: this.statusApproval.toString(),
            status_download: this.statusDownload.toString(),
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    doExport(status) {
      if ((this.table.data).length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Validation",
          text: "Get Data First",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        var statusSubmit = true
        let endpoint_url = this.baseUrl + "/export-noo"
        if (status == "sap") {
          endpoint_url = this.baseUrl + "/export-noo-sap"
          if (this.statusApproval != "2" || this.statusDownload != "false"){
            this.$vs.notify({
              color: "danger",
              title: "Form Validation",
              text: "Set status to approved and status download to false",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            statusSubmit = false
          }
        }
        if (statusSubmit) {
          this.$vs.loading();
          this.$http
          .get(endpoint_url, {
            params: {
              title: "Report-NOO-" + this.territoryCode.join("_"),
              customer_category_id: this.customerCategoryId,
              territory_id: this.territoryId.toString(),
              date_from: this.dateFrom == null ? "0001-01-01" : moment(this.dateFrom).format("YYYY-MM-DD"),
              date_to: this.dateTo == null ? "0001-01-01" : moment(this.dateTo).format("YYYY-MM-DD"),
              status_approval: this.statusApproval.toString(),
              status_download: this.statusDownload.toString(),
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
          });
        }
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  watch: {
    customerCategoryId() {
      this.table = this.tableDefaultState()
    },
    territorryId() {
      this.table = this.tableDefaultState()
    },
    dateFrom() {
      this.table = this.tableDefaultState()
    },
    dateTo() {
      this.table = this.tableDefaultState()
    },
    statusApproval() {
      this.table = this.tableDefaultState()
    },
    statusDownload() {
      this.table = this.tableDefaultState()
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>