<template>
  <iframe
    :src="blobUrl"
    style="width: 100%; height: 100dvh"
    ref="iframeRef"
    @load="printIframe"
  />
</template>

<script>
import jsPDF from "jspdf";
import invoiceHelper, {
  printInvoiceList,
} from "../../../helpers/invoice-helper";
import { bulkUpdateInvoice } from "../../../services/api/invoice";
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const masterRepo = RepositoryFactory.get("master");

export default {
  data() {
    return {
      blobUrl: null,
      invoiceIDs: [],
      startCode: null,
      endCode: null,
      invoice_codes: [],
      paramsType: "",
      inv_code:null,
      inv_ID:null,
      AllOptionTerritory: [],
      terr_ID: null,
      terr_code: null,
    };
  },
  async mounted() {
    this.inv_code = this.$route.params.inv_code;
    this.paramsType = sessionStorage.getItem("param_type");
    this.paramsType = sessionStorage.getItem("param_type");
    console.log("this.paramsType", this.paramsType)
    await this.getDataInv();
    await this.getTerritory();
    
    this.startCode = sessionStorage.getItem("start_code");
    this.endCode = sessionStorage.getItem("end_code");
    this.invoiceIDs = [JSON.parse(this.inv_ID)]
    
    console.log("printing pdf");
    console.log("this.inv_code", this.inv_code);
    console.log("terrID, terrCode", this.terr_ID, this.terr_code);
    this.printPDF();

    console.log("this.startCode", this.startCode)
    console.log("this.endCode", this.endCode)
    console.log("this.invoiceIDs", this.invoiceIDs)

    // this.$refs.iframeRef.contentWindow.print();
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.blobUrl);
    sessionStorage.removeItem("param_type");
    if (this.paramsType === "id") {
      sessionStorage.removeItem("invoice_id");
    } else if (this.paramsType === "code,id") {
      sessionStorage.removeItem("start_code");
      sessionStorage.removeItem("end_code");
      sessionStorage.removeItem("invoice_id");
    } else {
      sessionStorage.removeItem("start_code");
      sessionStorage.removeItem("end_code");
    }
    sessionStorage.removeItem("isBillingList");
  },
  methods: {
    async getDataInv() {
      let url = "api/oms/v3/sfa-sales-order/invoice/by-code/" + this.inv_code;
      this.$vs.loading();
      let resp = await this.$http.get(url);
  
      console.log(resp);
      if (resp.code == 200 && resp.data.invoice.ID != 0) {
          console.log(resp.data);
          this.inv_ID = resp.data.invoice.ID
          this.terr_ID = resp.data.invoice.TerritoryID
          console.log("this.inv_ID", this.inv_ID)
      } else {
          console.log(resp.data);
      }
      console.log(resp.data);
  
      this.$vs.loading.close();
    },
    async getTerritory() {

      // this.$vs.loading();
      let url = "/api/sfa/v1/route-plan/territory";
      let resp = await this.$http.get(url, {
        params: {
          length: 0,
          order: "name",
          sort: "asc",
        },
      });
      if (resp.code == 200) {
        if (resp.data.records) {
          this.AllOptionTerritory = resp.data.records;
          
          let terr_code = this.AllOptionTerritory.filter(v => {
            return v.id == this.terr_ID;
          });

          if (terr_code.length == 1) {
            this.terr_code = terr_code[0].code;
          }

          console.log("this.terr_code", this.terr_code)
        } else {
          this.optionTerritory = [];
          this.selectedTerritory = {};
        }
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Failed to get Territory option",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.$vs.loading.close();
      }
    },
    async printPDF() {
      try {
        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        // let cursorY = 0;

        pdf.setFontSize(13);
        pdf.setFont("courier", "bold");
        // const invoiceIDs = this.$store.getters["arInvoice/getselectedInvoicesIds"];

        console.log("invoice ids", this.invoiceIDs);

        const url = await invoiceHelper.printPDF(
          pdf,
          null,
          true,
          pageWidth,
          pageHeight,
          padding,
          this.startCode,
          this.endCode,
          this.terr_code,
          this.invoiceIDs,
        );

        await this.updatePrintBulk(this.invoiceIDs, this.startCode, this.endCode);
        this.blobUrl = url;
      } catch (e) {
        console.log(e);
      }
    },
    async updatePrintBulk(ids, starCode, endCode) {
      try {
        this.$vs.loading();
        const resp = await bulkUpdateInvoice(ids, starCode, endCode);
        if (resp.code > 299) {
          throw new Error("Failed to update print count");
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        throw e;
      }
    },
    printIframe() {
      if (!this.blobUrl) {
        return;
      }
      console.log("loaded");
      this.$refs.iframeRef.contentWindow.print();
    },
  },
};
</script>
