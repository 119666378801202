// import jsPDF from "jspdf";
import Vue from "vue";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import moment from "moment";
import api from "../axios";
import { bulkReadInvoice } from "../services/api/invoice";

let invoiceDivider = {};

// const showLoading = () => {
//   Vue.prototype.$vs.loading();
// };

const setPos = (pageWidth, pos, offset) => {
  if (pos >= pageWidth) {
    return pageWidth;
  } else {
    if (pos <= 0) {
      return offset;
    } else {
      return (pos += offset);
    }
  }
};

const getUserTerritory = async (territory_id) => {
  return api.get(`api/v1/master/territory/${territory_id}`);
};

const dashedLine = (pdf, x1, y1, x2, y2, pageWidth, pageHeight, padding) => {
  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.setLineDashPattern([0.2, 0.2], 0);
  pdf.line(
    setPos(pageWidth, x1, padding),
    setPos(pageHeight, y1, padding),
    setPos(pageWidth, x2, padding),
    setPos(pageHeight, y2, padding)
  );
};

const calculatePaymentTerm = (date, due_date) => {
  const dueDate = moment(due_date);
  const invoiceDate = moment(date);
  const diff = dueDate.diff(invoiceDate, "days");
  return diff;
};

const generateNumberSaying = (value) => {
  return angkaTerbilang(value).toUpperCase();
};

const generateInvoiceInformation = (
  pdf,
  invoice,
  salesOrder,
  salesMan,
  customer,
  terr_code,
  cursorY,
  pageWidth,
) => {
  const header = [
    { header: "", dataKey: "title" },
    { header: "", dataKey: "delimiter" },
    { header: "", dataKey: "value" },
  ];
  const body = [
    // { title: "Print", delimiter: ":", value: invoice.PrintCount + 1 },
    { title: "Billing No.", delimiter: ":", value: invoice.Code },
    {
      title: "Billing Date",
      delimiter: ":",
      value: moment(invoice.Date).format("DD.MM.YYYY"),
    },
    {
      title: "Order No.",
      delimiter: ":",
      value: salesOrder && salesOrder.Code ? salesOrder.Code : "-",
    },
    {
      title: "Order Date",
      delimiter: ":",
      value:
        salesOrder && salesOrder.Date
          ? moment(salesOrder.Date).format("DD.MM.YYYY")
          : "-",
    },
    { title: "Customer No.", delimiter: ":", value: invoice.ClientCode },
    {
      title: "Sales Office",
      delimiter: ":",
      value: terr_code,
    },
    {
      title: "Condition Delivery",
      delimiter: ":",
      value:
        salesOrder && salesOrder.DeliveryTypeName
          ? salesOrder.DeliveryTypeName
          : "-",
    },
    {
      title: "Term of Payment",
      delimiter: ":",
      value: `Due within ${calculatePaymentTerm(
        invoice.Date,
        invoice.DueDate
      )} days`,
    },
    {
      title: "Due Date",
      delimiter: ":",
      value: moment(invoice.DueDate).format("DD.MM.YYYY"),
    },
    {
      title: "Tax Status/Currency",
      delimiter: ":",
      value: `${customer.is_pkp === "0" ? "Non PKP" : "PKP"} / IDR`,
    },
    {
      title: "Sales Code/Salesman",
      delimiter: ":",
      value: `${
        salesMan && salesMan.CodeExternal ? salesMan.CodeExternal : "-"
      } / ${salesMan && salesMan.Name ? salesMan.Name : "-"}`,
    },
  ];
  autoTable(pdf, {
    startY: cursorY,
    columns: header,
    body: body,
    showHead: "never",
    styles: {
      lineWidth: 0,
      fontSize: 13,
      font: "courier",
      valign: "top",
    },
    columnStyles: {
      title: { cellWidth: 6, cellPadding: 0 },
      delimiter: { cellWidth: 0.5, cellPadding: 0 },
      value: { cellWidth: 5, cellPadding: 0 },
    },
    theme: "plain",
    margin: {
      left: setPos(pageWidth, pageWidth / 2 + 1, 1),
      right: 1,
    },
    didParseCell(data) {
      const columnIndex = data.column.index;
      if (columnIndex === 2) {
        data.cell.styles.halign = "left";
      }
    },
  });

  return pdf.lastAutoTable.finalY;
};

const generateHeader = (pdf, resp, cursorY, pageWidth, pageHeight, padding, terr_code) => {
  const invoiceData = resp.invoice;
  const customer = resp.customer;
  const salesOrder = resp.salesOrder[0];
  const salesMan = resp.salesman[0];
  const branch = resp.branch;
  const realCursor = cursorY;

  pdf.setFontSize(13);

  pdf.text(
    "PT. SINARMAS DISTRIBUSI NUSANTARA",
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding)
  );

  cursorY += 0.45;

  const address = `${branch.Address}, ${branch.SubDistrict}, ${branch.District}, ${branch.City}`;
  const addrLine = pdf.splitTextToSize(
    address,
    setPos(pageWidth, pageWidth / 2 - 1, padding)
  );
  const addrHeight = pdf.getTextDimensions(address).h;

  pdf.text(
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding),
    addrLine
  );

  cursorY += 1 + addrHeight * addrLine.length;

  pdf.text(
    "021-87727150",
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding)
  );

  cursorY += 0.8;

  pdf.text(
    "NPWP SDN: 01.604.506.4-007.000",
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding)
  );

  cursorY += 0.8;

  const supplier =
    invoiceData.ShipmentToAddressID && invoiceData.ShipmentToAddressID > 0
      ? customer.name
      : invoiceData.ClientName;
  // const supplierAddr =
  //   invoiceData.ShipmentToAddressID && invoiceData.ShipmentToAddressID > 0
  //     ? `${customer.address.toUpperCase()}, ${customer.sub_district.toUpperCase()}, ${customer.district.toUpperCase()}, ${customer.city.toUpperCase()}`
  //     : invoiceData.ClientAddress;

  const supplierAddr = `${customer.address.toUpperCase()}, ${customer.sub_district.toUpperCase()}, ${customer.district.toUpperCase()}, ${customer.city.toUpperCase()}`;
  const supplierAddrLine = pdf.splitTextToSize(
    supplierAddr,
    setPos(pageWidth, pageWidth / 2 - 1, padding)
  );

  pdf.text(
    "Payer :",
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding)
  );

  cursorY += 0.45;

  pdf.text(
    supplier,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding)
  );

  cursorY += 0.45;

  pdf.text(
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding),
    supplierAddrLine
  );

  const custAddrHeight = pdf.getTextDimensions(supplierAddr).h;
  cursorY += 0.5 + custAddrHeight * supplierAddrLine.length;

  pdf.text(
    `NPWP Customer : ${getNPWP(customer, invoiceData)}`,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursorY, padding)
  );

  cursorY = realCursor;

  const faktur = "FAKTUR";
  const billing = "(BILLING)";

  const billingWidth = pdf.getTextDimensions(billing).w;
  const fakturWidth = pdf.getTextDimensions(faktur).w;
  const xPos =
    pageWidth - pageWidth / 4 - 0.5 + (billingWidth - fakturWidth) / 2;
  pdf.setFontSize(14);
  pdf.setFont("courier", "bold");

  pdf.text(
    faktur,
    setPos(pageWidth, xPos, padding),
    setPos(pageHeight, cursorY, padding)
  );

  cursorY += 0.4;

  pdf.text(
    billing,
    setPos(pageWidth, pageWidth - pageWidth / 4 - 0.5, padding),
    setPos(pageHeight, cursorY, padding)
  );
  cursorY += 0.3;

  dashedLine(
    pdf,
    pageWidth / 2 + 1,
    cursorY,
    pageWidth + 0.5,
    cursorY,
    pageWidth + 0.5,
    pageHeight,
    padding
  );

  cursorY += 1.3;

  cursorY = generateInvoiceInformation(
    pdf,
    invoiceData,
    salesOrder,
    salesMan,
    customer,
    terr_code,
    cursorY,
    pageWidth
  );

  return cursorY;
};

const getNPWP = (customer, invoice) => {
  if (!invoice.ShipmentToAddressID || invoice.ShipmentToAddressID === 0) {
    return invoice.ClientNpwp;
  }

  if (customer.npwp === "") {
    return "-";
  } else {
    return customer.npwp;
  }
};

const generateOtherInformation = (
  pdf,
  resp,
  cursorY,
  pageWidth,
  pageHeight,
  padding
) => {
  const invoice = resp.invoice;
  const payment = resp.payment[0];
  let cursor = cursorY;
  const salesOrder = resp.salesOrder[0];
  const saying = generateNumberSaying(invoice.Total) + " RUPIAH";
  const sayingLines = pdf.splitTextToSize(saying, pageWidth - 2);

  dashedLine(
    pdf,
    0.5,
    cursor,
    pageWidth + 0.5,
    cursor,
    pageWidth + 0.5,
    pageHeight,
    padding
  );

  cursor += 0.5;
  pdf.text(
    "SAY :",
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursor, padding)
  );
  pdf.text(
    setPos(pageWidth, 2.0, padding),
    setPos(pageHeight, cursor, padding),
    sayingLines
  );

  const sayingHeight = pdf.getTextDimensions(saying).h * sayingLines.length;

  cursor += sayingHeight;

  dashedLine(
    pdf,
    0.5,
    cursor,
    pageWidth + 0.5,
    cursor,
    pageWidth + 0.5,
    pageHeight,
    padding
  );

  cursor += 0.5;

  pdf.text(
    `Sales Order : ${salesOrder && salesOrder.Code ? salesOrder.Code : "-"}`,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursor, padding)
  );

  cursor += 0.5;

  pdf.text(
    `Customer PO. No. : ${invoice.InvoiceReferenceCode}`,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursor, padding)
  );

  cursor += 0.5;

  pdf.text(
    `Message : ${invoice.Notes}`,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursor, padding)
  );

  cursor += 0.5;

  dashedLine(
    pdf,
    0.5,
    cursor,
    pageWidth + 0.5,
    cursor,
    pageWidth + 0.5,
    pageHeight,
    padding
  );

  cursor += 1;

  if (cursor > pageHeight - 1.5) {
    pdf.addPage();
    cursor = 1;
  }

  pdf.text(
    `Notes:`,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursor, padding)
  );

  cursor += 0.5;

  pdf.setFont("courier", "normal");

  pdf.text(
    `This is a computer generated document and no signature is required`,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursor, padding)
  );

  cursor += 1;

  pdf.setFont("courier", "bold");
  pdf.text(
    `Bank Account : ${
      payment && payment.BankAccountNumber ? payment.BankAccountNumber : ""
    }`,
    setPos(pageWidth, 0.5, padding),
    setPos(pageHeight, cursor, padding)
  );

  return cursor;
};

const generateInvoiceLine = (
  pdf,
  data,
  cursorY,
  pageWidth,
  pageHeight,
  padding
) => {
  const invoice = data.invoice;
  const invoiceLines = data.invoiceLine[0].Line;
  let prevPage = 1;
  let isDrawLine = false;
  let isDraw = false;
  const header = [
    { header: "Product", dataKey: "product" },
    { header: "Quantity", dataKey: "qty" },
    { header: "UoM", dataKey: "uom" },
    { header: "Price Value", dataKey: "price_value" },
    { header: "Gross Value", dataKey: "gross_value" },
  ];

  let body = invoiceLines.map((line) => {
    return {
      product: line.ItemName,
      qty: line.Qty,
      uom: line.Unit,
      price_value: `${priceFormat(line.Price)} / 1${
        line.Unit ? line.Unit : ""
      }`,
      gross_value: priceFormat(
        line && line.Information && line.Information.subtotal_before_discount
          ? line.Information.subtotal_before_discount
          : line.Qty * line.Price
      ),
    };
  });

  let startY = cursorY;
  let colHeight = 0;
  let isShowHeader = false;

  for (let i = 0; i < body.length; i++) {
    const innerBody = [body[i]];

    if (startY + colHeight >= pageHeight) {
      pdf.addPage();
      pdf.setPage(pdf.internal.getNumberOfPages());
      startY = cursorY;
      isShowHeader = true;
    }

    autoTable(pdf, {
      startY: startY,
      columns: header,
      body: innerBody,
      styles: {
        lineWidth: 0,
        fontSize: 13,
        font: "courier",
        valign: "middle",
      },
      showHead: i === 0 || isShowHeader ? "firstPage" : "never",
      columnStyles: {
        product: { cellWidth: 10, cellPadding: 0 },
        qty: { cellWidth: 3, cellPadding: 0 },
        uom: { cellWidth: 2, cellPadding: 0 },
        price_value: { cellWidth: 5, cellPadding: 0 },
        gross_value: { cellPadding: 0 },
      },
      // margin: {
      //   top: setPos(pageHeight, cursorY, padding),
      //   bottom: setPos(pageHeight, 1.5, padding),
      // },
      theme: "plain",
      // margin: { left: setPos(pageWidth, pageWidth - 10, 1), right: 1 },
      didParseCell(data) {
        const columnIndex = data.column.index;
        if (columnIndex === 1 || columnIndex === 4) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellPadding = 0.1;
        }
      },
      didDrawPage: (data) => {
        if (prevPage !== data.pageCount) {
          isDrawLine = true;
          prevPage = data.pageCount;
        }
      },
      didDrawCell: (data) => {
        const columnIndex = data.column.index;
        const cellX = data.cell.x;
        const cellY = data.cell.y;
        if (columnIndex === 0) {
          // const cellIncm = data.cell.y * toCentiMeter;
          startY += data.cell.height;
          colHeight = data.cell.height;
        }

        if (i === 0 || isShowHeader || data.section === "head") {
          if (columnIndex === 4 && isShowHeader && data.section === "body") {
            isShowHeader = false;
          }
          pdf.setLineWidth(0.01);
          pdf.setDrawColor(0, 0, 0);
          pdf.setLineDashPattern([0.2, 0.2], 0);
          pdf.line(cellX, cellY, cellX + data.cell.width, cellY);
        }

        if (i === body.length - 1 || body.length === 1) {
          pdf.setLineWidth(0.01);
          pdf.setDrawColor(0, 0, 0);
          pdf.setLineDashPattern([0.2, 0.2], 0);
          pdf.line(
            cellX,
            cellY + data.cell.height,
            cellX + data.cell.width,
            cellY + data.cell.height
          );
        }
      },
    });
    // startY += pdf.lastAutoTable.finalY;
  }

  return pdf.lastAutoTable.finalY;
};

export const printInvoiceList = async (
  pdf,
  cursorY,
  pageWidth,
  pageHeight,
  padding,
  startCode = null,
  endCode = null,
  ids = null
) => {
  try {
    let resp = {};
    // showLoading();

    const currentUser = Vue.prototype.$store.state.user.currentUser;
    let territory = null;

    if (currentUser.territory_id > 0) {
      resp = await getUserTerritory(currentUser.territory_id);
      if (resp.code > 299) {
        throw new Error("Failed to get territory data");
      }
      territory = resp.data;
    }

    if (startCode && endCode) {
      console.log("startCode && endCode", startCode, endCode)
      console.log("ids", ids)
      const invoiceIDs = ids;
      resp = await bulkReadInvoice(invoiceIDs, null, startCode, endCode);
      if (resp.code > 299) {
        throw new Error("Failed to get invoices data");
      }

      const invoices = resp.data.map((data) => data.invoice);
      Vue.prototype.$store.commit("arInvoice/setSelectedInvoices", invoices);
    } else {
      const invoiceIDs = ids;
      resp = await bulkReadInvoice(invoiceIDs);
      if (resp.code > 299) {
        throw new Error("Failed to get invoices data");
      }

      // const invoicesState = [
      //   ...Vue.prototype.$store.state.arInvoice.selectedInvoices,
      // ];
      resp.data.sort((a, b) => {
        const indexA = invoiceIDs.indexOf(a.invoice.ID);
        const indexB = invoiceIDs.indexOf(b.invoice.ID);

        // If an ID is not found, place it at the end
        if (indexA === -1 && indexB === -1) return 0; // Both not found, equal
        if (indexA === -1) return 1; // a is not found, place it after b
        if (indexB === -1) return -1; // b is not found, place it after a

        return indexA - indexB; // Sort based on the index
      });
    }

    const data = resp.data;

    pdf.setFontSize(22);
    pdf.setFont("times", "bold");

    const billingListHeader = "List Billing Ke Inkasso";
    const headerWidth = pdf.getTextDimensions(billingListHeader).w;
    const xPos = pageWidth / 2 - headerWidth / 2;
    pdf.text(
      "List Billing Ke Inkasso",
      setPos(pageWidth, xPos, padding),
      setPos(pageHeight, cursorY, padding)
    );

    cursorY += 1.8;

    const header = [
      { header: "Billing Date", dataKey: "bill_date" },
      { header: "Billing No.", dataKey: "billing_number" },
      { header: "Description", dataKey: "description" },
      { header: "Value", dataKey: "value" },
    ];

    let startY = cursorY;
    let colHeight = 0;
    let isShowHeader = false;

    for (let i = 0; i < data.length; i++) {
      const body = [
        {
          bill_date: moment(data[i].invoice.Date).format("DD.MM.YYYY"),
          billing_number: data[i].invoice.Code,
          description: `${data[i].invoice.ClientCode} - ${data[
            i
          ].invoice.ClientName.trim()}`,
          value: priceFormat(data[i].invoice.Total),
        },
      ];
      if (startY + colHeight >= pageHeight + 2 * padding - 4) {
        pdf.addPage();
        pdf.setPage(pdf.internal.getNumberOfPages());
        startY = cursorY;
        isShowHeader = true;
      }

      autoTable(pdf, {
        startY: startY,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0,
          fontSize: 13,
          font: "courier",
          valign: "middle",
          halign: "left",
        },
        showHead: i === 0 || isShowHeader ? "firstPage" : "never",
        columnStyles: {
          bill_date: { cellWidth: 4, cellPadding: 0 },
          bill_number: { cellWidth: 5, cellPadding: 0 },
          description: { cellWidth: 10, cellPadding: 0 },
          value: { cellWidth: 5, cellPadding: 0 },
        },
        // margin: {
        //   top: setPos(pageHeight, cursorY, padding),
        //   bottom: setPos(pageHeight, 1.5, padding),
        // },
        theme: "plain",
        // margin: { left: setPos(pageWidth, pageWidth - 10, 1), right: 1 },
        didParseCell(data) {
          const columnIndex = data.column.index;

          if (data.section === "head") {
            if (columnIndex === 2) {
              data.cell.styles.halign = "center";
            } else if (columnIndex < 2) {
              data.cell.styles.halign = "left";
            }
          }

          if (columnIndex === 3) {
            data.cell.styles.halign = "right";
            // data.cell.styles.cellPadding = 0.1;
          }
        },
        didDrawCell: (data) => {
          const columnIndex = data.column.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (columnIndex === 0) {
            startY += data.cell.height;
            colHeight = data.cell.height;
          }

          if (data.section === "head") {
            pdf.setLineWidth(0.01);
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineDashPattern([0.2, 0.2], 0);
            pdf.line(cellX, cellY, cellX + data.cell.width, cellY);
          }

          if ((i === 0 || isShowHeader) && data.section === "body") {
            if (columnIndex === 3 && isShowHeader) {
              isShowHeader = false;
            }
            pdf.setLineWidth(0.01);
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineDashPattern([0.2, 0.2], 0);
            pdf.line(cellX, cellY, cellX + data.cell.width, cellY);
          }
        },
      });
    }

    generateSignature(pdf, territory, pageWidth, pageHeight, padding);

    const url = pdf.output("blob");
    const urlObject = URL.createObjectURL(url);
    // hideLoading();
    return urlObject;
  } catch (e) {
    // hideLoading();
    console.error(e);
    throw e;
  }
};

const generateSignature = (
  pdf,
  userTerritory,
  pageWidth,
  pageHeight,
  padding
) => {
  pdf.setFontSize(12);
  pdf.setFont("times", "normal");

  const cityName = userTerritory ? userTerritory.name : "Jakarta";

  const startPos = pageHeight + 2 * padding - 4;
  let cursorY = startPos;
  const timeAndPlace = `${cityName}, ${moment().format("DD.MM.YYYY")}`;
  const givenBy = "Diserahkan Oleh,";
  const salesAdmin = "Admin Sales";
  const givenByWidth = pdf.getTextDimensions(givenBy).w;
  const timeAndPlaceWidth = pdf.getTextDimensions(timeAndPlace).w;
  const salesAdminWidth = pdf.getTextDimensions(salesAdmin).w;
  let contentWidth =
    givenBy.length > timeAndPlace.length
      ? givenByWidth + 1
      : timeAndPlaceWidth + 1;
  const givenByposX = Math.abs(contentWidth / 2 - givenByWidth / 2);
  const timeAndPlacePosX = contentWidth / 2 - timeAndPlaceWidth / 2;
  const salesAdminPosx = contentWidth / 2 - salesAdminWidth / 2;

  pdf.text(timeAndPlace, setPos(pageWidth, timeAndPlacePosX, padding), cursorY);

  cursorY += 0.4;

  pdf.text(givenBy, setPos(pageWidth, givenByposX, padding), cursorY);

  cursorY += 1.6;

  pdf.text(salesAdmin, setPos(pageWidth, salesAdminPosx, padding), cursorY);

  cursorY = startPos + 0.4;

  const receivedBy = "Diterima Oleh,";
  const arAdmin = "Inkasso";
  const receivedByWidth = pdf.getTextDimensions(receivedBy).w;
  const arAdminWidth = pdf.getTextDimensions(arAdmin).w;
  contentWidth = receivedByWidth + 1;
  const realPageWidth = pageWidth;
  const receivedByAlignPos = contentWidth / 2 - receivedByWidth / 2;
  const arAdminAlignPos = contentWidth / 2 - arAdminWidth / 2;
  const receivedByPosX = realPageWidth - (receivedByAlignPos + receivedByWidth);
  const arAdminPosX = realPageWidth - (arAdminAlignPos + arAdminWidth);

  pdf.text(receivedBy, setPos(pageWidth, receivedByPosX, padding), cursorY);
  cursorY += 1.6;
  pdf.text(arAdmin, setPos(pageWidth, arAdminPosX, padding), cursorY);
};

const generateInvoiceCalculation = (
  pdf,
  data,
  cursorY,
  pageWidth,
  pageHeight,
  padding
) => {
  const invoice = data.invoice;
  const invoiceLines = data.invoiceLine[0].Line;

  const header = [
    { header: "", dataKey: "blank" },
    { header: "", dataKey: "name" },
    { header: "", dataKey: "value" },
  ];

  let grossUp = 0;
  let rounding = 0;

  invoiceLines.forEach((line) => {
    grossUp +=
      line &&
      line.Information &&
      line.Information.gross_up &&
      invoice.Sources.toLowerCase() === "generate"
        ? line.Information.gross_up
        : 0;

    rounding +=
      line && line.Information && line.Information.rounding
        ? line.Information.rounding
        : 0;
  });

  // const subtotal = invoice.Subtotal - invoice.Discount;
  const dp = 0;
  const total = [
    {
      blank: "",
      name: "GROSS TOTAL",
      value: priceFormat(
        invoice.Sources.toLowerCase() === "generate"
          ? invoice.Subtotal + invoice.Discount - rounding
          : invoice.Subtotal
      ),
    },
    {
      blank: "",
      name: "DISCOUNT",
      value: `${priceFormat(invoice.Discount - rounding)}-`,
    },
    {
      blank: "",
      name: "SUBTOTAL",
      value: priceFormat(
        invoice.Sources.toLowerCase() === "generate"
          ? invoice.Subtotal
          : invoice.Subtotal - invoice.Discount
      ),
    },
    {
      blank: "",
      name: "GROSS UP",
      value: priceFormat(grossUp),
    },
    {
      blank: "",
      name: "DP",
      value: priceFormat(dp),
    },
    {
      blank: "",
      name: "DPP",
      value: priceFormat(
        invoice.Sources.toLowerCase() === "generate"
          ? invoice.Subtotal - dp + grossUp
          : invoice.Subtotal - invoice.Discount - dp + grossUp
      ),
    },
    {
      blank: "",
      name: `PPN ${invoice.TaxRate}%`,
      value: priceFormat(invoice.TaxAmount),
    },
    {
      blank: "",
      name: "TOTAL",
      value: priceFormat(invoice.Total),
    },
  ];

  autoTable(pdf, {
    startY: cursorY,
    columns: header,
    body: total,
    styles: {
      lineWidth: 0,
      fontSize: 13,
      font: "courier",
      valign: "middle",
      fontStyle: "bold",
    },
    showHead: "never",
    columnStyles: {
      blank: { cellWidth: 13, cellPadding: 0 },
      name: { cellPadding: 0 },
      qty: { cellPadding: 0 },
    },
    // margin: {
    //   top: setPos(pageHeight, cursorY, padding),
    //   bottom: setPos(pageHeight, 1.5, padding),
    // },
    theme: "plain",
    // margin: { left: setPos(pageWidth, pageWidth - 10, 1), right: 1 },
    didParseCell(data) {
      const columnIndex = data.column.index;
      if (columnIndex === header.length - 1) {
        data.cell.styles.halign = "right";
      }
    },
    didDrawCell: (data) => {
      const cellX = data.cell.x;
      const cellY = data.cell.y;

      if (data.row.index === 0 && data.section === "body") {
        pdf.setLineWidth(0.01);
        pdf.setDrawColor(0, 0, 0);
        pdf.setLineDashPattern([0.2, 0.2], 0);
        pdf.line(cellX, cellY, cellX + data.cell.width, cellY);
      }
    },
  });

  return pdf.lastAutoTable.finalY;
};

const priceFormat = (amount) => {
  const newAmount = amount
    .toString()
    .replace(/[^.\d]/g, "")
    .toString();
  console.log(amount, newAmount);
  amount = parseFloat(newAmount).toFixed(0);

  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const printPDF = async (
  pdf,
  invoice_codes = null,
  isPrint = false,
  pageWidth,
  pageHeight,
  padding,
  startCode = null,
  endCode = null,
  terr_code = null,
  ids,
) => {
  try {
    // showLoading();

    let resp = {};

    if (
      (!startCode || !endCode) &&
      (!invoice_codes || invoice_codes.length === 0)
    ) {
      console.log("ids", ids);
      const invoiceIDs = ids;

      console.log("invoiceIDs", invoiceIDs);

      resp = await bulkReadInvoice(invoiceIDs);
      if (resp.code > 299) {
        throw new Error("Failed to get invoices data");
      }

      resp.data.sort((a, b) => {
        const indexA = invoiceIDs.indexOf(a.invoice.ID);
        const indexB = invoiceIDs.indexOf(b.invoice.ID);

        // If an ID is not found, place it at the end
        if (indexA === -1 && indexB === -1) return 0; // Both not found, equal
        if (indexA === -1) return 1; // a is not found, place it after b
        if (indexB === -1) return -1; // b is not found, place it after a

        return indexA - indexB; // Sort based on the index
      });
    } else if (invoice_codes && invoice_codes.length > 0) {
      resp = await bulkReadInvoice(null, invoice_codes, startCode, endCode);
      if (resp.code > 299) {
        throw new Error("Failed to get invoices data");
      }

      resp.data.sort((a, b) => {
        const indexA = invoice_codes.indexOf(a.invoice.Code);
        const indexB = invoice_codes.indexOf(b.invoice.Code);

        // If an ID is not found, place it at the end
        if (indexA === -1 && indexB === -1) return 0; // Both not found, equal
        if (indexA === -1) return 1; // a is not found, place it after b
        if (indexB === -1) return -1; // b is not found, place it after a

        return indexA - indexB; // Sort based on the index
      });
    } else if (startCode && endCode) {
      const invoiceIDs = ids;

      resp = await bulkReadInvoice(invoiceIDs, null, startCode, endCode);
      if (resp.code > 299) {
        throw new Error("Failed to get invoices data");
      }

      const invoices = resp.data.map((data) => data.invoice);
      // Vue.prototype.$store.commit("arInvoice/setSelectedInvoices", invoices);
    }

    pdf.setFontSize(13);
    pdf.setFont("courier", "bold");

    const startPoint = 12;
    let cursorY = startPoint;
    let key = 1;
    let currentPage = 1;
    invoiceDivider[key] = resp.data[0];
    for (let i = 0; i < resp.data.length; i++) {
      cursorY = generateInvoiceLine(
        pdf,
        resp.data[i],
        cursorY,
        pageWidth,
        pageHeight,
        padding
      );

      let contentHeight = 7.3;
      if (cursorY + contentHeight >= pageHeight + 2 - 1) {
        pdf.addPage();
        cursorY = startPoint;
      }

      cursorY = generateInvoiceCalculation(
        pdf,
        resp.data[i],
        cursorY,
        pageWidth,
        pageHeight,
        padding
      );

      cursorY -= 1;
      contentHeight = 6.5;
      if (cursorY + contentHeight >= pageHeight + 2 - 1) {
        pdf.addPage();
        cursorY = startPoint;
      }

      cursorY = generateOtherInformation(
        pdf,
        resp.data[i],
        cursorY,
        pageWidth,
        pageHeight,
        padding
      );

      if (resp.data.length > 1 && i < resp.data.length - 1) {
        pdf.addPage();
        pdf.setPage(pdf.internal.getNumberOfPages());
        cursorY = startPoint;
      }

      currentPage = pdf.internal.getNumberOfPages();
      if (resp.data.length > 1 && i < resp.data.length - 1) {
        invoiceDivider[currentPage] = resp.data[i + 1];
      }

      // let invoiceData = resp.data[i].invoice;
    }

    console.log("invoiceDivider", invoiceDivider);

    const arrayKey = Object.keys(invoiceDivider).map((key) => parseInt(key));
    const pageCount = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.text(
        `Page ${i} of ${pageCount}`,
        setPos(pageWidth, pageWidth - 3, padding),
        setPos(pageHeight, pageHeight - 0.5, padding)
      );

      if (i > 1 && arrayKey.includes(i)) {
        key = i;
      }

      const invoiceData = invoiceDivider[key].invoice;

      pdf.text(
        `Print : ${invoiceData.PrintCount + 1}`,
        setPos(pageWidth, pageWidth - (pageWidth - 3), padding),
        setPos(pageHeight, pageHeight - 0.5, padding)
      );

      generateHeader(
        pdf,
        invoiceDivider[key],
        2.8,
        pageWidth,
        pageHeight,
        padding,
        terr_code
      );
    }

    invoiceDivider = {};

    if (isPrint) {
      const url = pdf.output("blob");
      const urlObject = URL.createObjectURL(url);
      // hideLoading();
      return urlObject;
    } else {
      const url = pdf.output("dataurlstring") + "#toolbar=0";
      return url;
    }
  } catch (error) {
    // hideLoading();
    console.error(error);
    throw new Error("Failed to print PDF");
    // Vue.prototype.$store.commit("arInvoice/resetInvoice");
  }
};

export default {
  printPDF,
};
