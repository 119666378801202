<template>
  <vx-card title="Product Flag">
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/6 w-full">
        <span>Flag Option</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedFlag"
          :options="optionalFlag"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Select one"
          :searchable="true"
          :custom-label="customLableFlag"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/6 w-full">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Select one"
          :searchable="true"
          :custom-label="customLableTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/6 w-full">
        <span>Status</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedStatus"
          :options="optionStatus"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Select one"
          :searchable="true"
          label="name"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/6 w-full">
        <span>Validity</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <date-range-picker
          style="min-height: 40px"
          class="w-full"
          ref="picker"
          opens="right"
          :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="false"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="true"
          v-model="validity"
          :linkedCalendars="true"
        >
          <template v-slot:input="picker" style="min-width: 550px">
            {{ dateFormat(picker.startDate) }} -
            {{ dateFormat(picker.endDate) }}
          </template>
        </date-range-picker>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/6 w-full">
        <span></span>
      </div>
      <div class="vx-row sm:w-2/3 w-full justify-end">
        <vs-button
          class="mt-2 mr-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-filter"
          @click="handleFilter"
          >Filter</vs-button
        >
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-trash-2"
          @click="handleClose"
          >Clear</vs-button
        >
      </div>
    </div>
    <div class="vx-input-group flex">
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-upload"
        @click="handleImport()"
        >Import</vs-button
      >
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-download"
        @click="ExportForm()"
        >Export</vs-button
      >
    </div>

    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <data-table
            :territoryID="this.selectedTerritory.map((item) => item.id)"
            :baseUrl="this.baseUrl"
            :detail="this.detail"
            :flag="this.selectedFlag.flagID"
            :status="this.selectedStatus.name"
            :validitySt="this.validity.startDate"
            :validityEd="this.validity.endDate"
            :isFilter="this.isFilter"
            @isFilters="setFilter"
            @selected-items="handleSelectedItems"
          ></data-table>
        </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan> -->
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DataTable,
    DateRangePicker,
  },
  data() {
    return {
      uploadReady: true,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/product-flag",
      optionTerritory: [],
      selectedTerritory: [],
      selectedFlag: { name: "Product Focus", flagID: 1 },
      optionalFlag: [
        { name: "Product Focus", flagID: 1 },
        { name: "New Product", flagID: 2 },
      ],
      selectedStatus: {},
      optionStatus: [
        { name: "Active", id: 1 },
        { name: "Inactive", id: 0 },
      ],
      validity: {
        startDate: null,
        endDate: null,
      },
      isFilter: false,
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "product-flag-create",
        // params: { id: id },
      });
    },
    handleImport() {
      this.$router.push({
        name: "product-flag-import",
        // params: { id: id },
      });
    },
    handleSelectedItems(selectedItems) {
      console.log(selectedItems);
      this.selectedExport = selectedItems;
    },
    ExportForm() {
      const id = this.$route.params.id;
      var fileTitle = "PRODUCT_FLAG_EXPORT_";
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-flag/export", {
          id: parseInt(this.$route.params.id),
          responseType: "arraybuffer",
          params: {
            flag_id: this.selectedFlag.flagID,
            selectedExport: this.selectedExport.join(", "),
          },
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
          // this.$vs.loading.close();
        });
    },
    handleOpen() {
      this.detail = true;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/territory/personal-territories", {
          params: {
            company_id: this.$store.state.user.currentUser.company_id,
            length: 999,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data) {
              this.optionTerritory = resp.data;
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableFlag({ name }) {
      return `${name}`;
    },
    customLableTerritory({ name }) {
      return `${name}`;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    handleFilter() {
      this.isFilter = true;
    },
    handleClose() {
      this.selectedStatus = {};
      this.selectedTerritory = [];
      this.validity = {
        startDate: null,
        endDate: null,
      };
      this.selectedFlag = { name: "Product Focus", flagID: 1 };
      this.isFilter = true;
    },
    setFilter(val) {
      this.isFilter = val;
    },
  },
  mounted() {
    this.getTerritory();
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
