<template>
  <vx-card id="head_card" :title="title">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="my-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Back</vs-button
        >
      </div>
    </div>
    <!-- Pembagian dan pengelompokan dropdown -->
    <!-- 1st row : Customer Data & Segmentation Data -->
    <div class="vx-row mt-3">
      <!-- Customer Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Customer Data</span><hr>
        <!-- One time cust -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3">
            One Time
          </div>
          <div class="vx-col sm:w-2/6">
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="isOneTime"
              :disabled="this.checkSkuSelected()"
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ship to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedShippTo"
              :options="optionShippTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="false"
              :loading="isLoadingShipto"
              :max-height="200"
              :limit="5"
              :disabled="false"
              v-validate="'required'"
              name="shipto"
              @input="onSelectedShipto"
              placeholder="Type to search"
              @search-change="handlerSearchShipto"
              :custom-label="customLableShippTo"
            />
            <span
            class="text-danger text-sm"
            v-show="true"
            >{{ msgErrorShippTo }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" :value="oneTimeCust.contact_person!=null ? selectedShippTo.code+` - `+oneTimeCust.contact_person : selectedShippTo.code" disabled/>
          </div>
          <div class="vx-col sm:w-1/3 w-full"></div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="selectedShippTo.address" disabled/>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Bill to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedBillTo"
              :options="optionBillTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              name="billto"
              @input="onSelectedBillto"
              :custom-label="customLableBillto"
            />
            <span
            class="text-danger text-sm"
            v-show="isErrorBillTo"
            >{{ msgErrorBillto }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :loading="isLoadingCus"
              :internal-search="false"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              @search-change="handlerSearchCustomer"
              :custom-label="customLableCustomer"
              />
              <!-- @input="onSelectedCus" -->
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="selectedCustomer.code" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Name</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="selectedCustomer.name" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Created By</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="createdby.name" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span> Creation Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              format="yyyy-MM-dd"
              :inline="false"
              v-model="createdby.date"
              placeholder="Select Date"
              :disabled="true"
            ></datepicker>
          </div>
        </div>
        <!-- Cust Deposit Fields, for CBD type -->
        <!-- showCustDeposit -->
        <div v-if="showCustDeposit" class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Deposit</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustDeposit"
              :options="optionCustDeposit"
              :multiple="true"
              :allow-empty="false"
              track-by="ID"
              :max-height="200"
              placeholder="Type to search"
              @input="onSelectedCustDeposit"
              :custom-label="customLableCustDeposit"
            />
          </div>
        </div>
        <!-- checkpoint_one_time -->        
      </div>
      <!-- </div> -->
      <!-- Segmentation Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Segmentation Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              name="territory"
              :limit="5"
              :disabled="false"
              @search-change="handlerSearchTerritory"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Zone</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="zone.selected"
              :options="zone.option"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
              @search-change="handlerSearchZone"
              @input="onselectedZone"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesChannel.selected"
              :options="salesChannel.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="code"
              label="name"
              :disabled="this.checkSkuSelected()"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="customerCategory.selected"
              :options="customerCategory.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="name"
              :disabled="!this.isOneTime"
              :custom-label="customLableDistChannel"
            ></multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales ID</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesPersonal.selected"
              :options="salesPersonal.option"
              :multiple="false"
              :allow-empty="true"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :loading="isLoadingSales"
              track-by="ID"
              label="Name"
              :internal-search="false"
              @search-change="handlerSearchSales"
              @open ="handlerSearchSales"
              @input="onselectedSalesman"
              :disabled="this.checkSkuSelected()"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.CodeExternal + " - "+ props.option.Name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.CodeExternal + " - "+ props.option.Name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Pricing Group</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="pricingGroup.selected"
              :options="pricingGroup.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
              :disabled="true"
              @input="onselectedPricingGroup"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <!-- middle row : one time customer data-->
    <div v-if="this.isOneTime" class="vx-row mb-2 mt-4">
      <div class="vx-col w-full text-center">
        <span class="py-2 h5 font-semibold">One Time Customer Data</span>
        <hr>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row my-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Customer Name <span class="text-danger">*</span></label>
            <vs-input class="w-full" v-model="oneTimeCust.name" placeholder="Input name" name="Customer Name"/>
          </div>
        </div>
        <address-component
          :data="oneTimeCust.address"
          @data="setDataAddress"
        ></address-component>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Bill Address<span class="text-danger">*</span></label>
            <vs-input class="w-full" v-model="oneTimeCust.address_manual" placeholder="Input bill address"/>
          </div>
        </div>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row my-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Contact Person <span class="text-danger">*</span></label>
            <vs-input class="w-full" v-model="oneTimeCust.contact_person" placeholder="Input contact person"/>
          </div>
        </div>

        <!-- NIK -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">NIK <span class="text-danger">*</span></label>
            <vs-input type="number" class="w-full" v-model="oneTimeCust.nik"  placeholder="Input NIK"/>
          </div>
        </div>

        <!-- Phone Number -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Phone Number <span class="text-danger">*</span></label>
            <vs-input type="number" class="w-full" v-model="oneTimeCust.phone" placeholder="Input phone number"/>
          </div>
        </div>
        
        <!-- Domisili -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Domisili*</label>
            <vs-input type="text" class="w-full" v-model="oneTimeCust.domisili" placeholder="Input domisili"/>
          </div>
        </div>
        
        <!-- NPWP Type -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">NPWP Type*</label>
            <vs-input type="text" class="w-full" v-model="oneTimeCust.npwp_type" placeholder="Input npwp type"/>
          </div>
        </div>

        <!-- NPWP -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">NPWP*</label>
            <vs-input type="number" class="w-full" v-model="oneTimeCust.npwp" placeholder="Input npwp"/>
          </div>
        </div>

        <!-- NPWP Name -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">NPWP Name*</label>
            <vs-input type="text" class="w-full" v-model="oneTimeCust.npwp_name" placeholder="Input npwp name"/>
          </div>
        </div>

        <!-- SPPKP -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">SPPKP*</label>
            <vs-input type="text" class="w-full" v-model="oneTimeCust.sppkp" placeholder="Input sppkp"/>
          </div>
        </div>

        <!-- Tax Address -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Address*</label>
            <vs-input type="text" class="w-full" v-model="oneTimeCust.tax_address" placeholder="Input tax address"/>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <!-- <vs-button size="medium" v-on:click="submitCustOnetime" color="primary"> -->
            <vs-button size="medium" v-on:click="handleCustOnetime" color="primary">
              {{ this.oneTimeCust.id != null ? "Update" : "Confirm" }}
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 2nd row : CO Data & Delivery Data -->
    <div class="vx-row">
      <!-- CO Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">SO Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>SO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="dataCustomerOrder.code" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ref. PO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="poRefCode"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>SO Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="soType"
              :options="optionSoType"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="4"
              placeholder="Type to search"
              track-by="Code"
              :custom-label="customLableCOType"
              :disabled="this.checkSkuSelected()"
            >
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
          <!-- disabledate -->
            <!-- {
                from: new Date(),
                to: new Date()
              } -->
            <datepicker
              name="date"
              format="yyyy-MM-dd"
              :inline="false"
              v-model="dateCo"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="DateDisabled"
              :disabled="this.checkSkuSelected()"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>PO Date</span>
          </div>
          <div class="vx-col sm:w-7/12 w-full">
            <datepicker
              name="date"
              format="yyyy-MM-dd"
              :inline="false"
              v-model="po_date"
              placeholder="Select Date"
              v-validate="'required'"
              :allow-empty="true"
              :disabledDates="{
                from: new Date()
              }"
            ></datepicker>
          </div>
          <div class="vx-col sm:w-1/12 w-full flex items-center">
            <!-- <vs-button type="border" color="success" v-on:click="handleClose">Set null</vs-button> -->
            <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x" @click="setNullPoDate()"/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Payment Term</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="paymentTerm.selected"
              :options="paymentTerm.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="false"
              @input="onselectedPaymentTerm_"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>PBF</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="1" v-model="isPBF">
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="0" v-model="isPBF">
              <label> No</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Delivery Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">Delivery Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Allow Partial</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="1" v-model="allowPartial" :disabled="calculated || isSampleCO()">
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="0" v-model="allowPartial" :disabled="calculated || isSampleCO()">
              <label> No</label>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Delivery Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="deliveryType.selected"
              :options="deliveryType.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="this.checkSkuSelected()"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="vx-row mb-6" v-show="showExpenseType">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Expense Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="expenseType.selected"
              :options="expenseType.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Req. Delivery Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
            format="yyyy-MM-dd"
              :inline="false"
              v-model="rDeliveryDate"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex">
            <span>Note</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-textarea
              v-model="note"
              :disabled="false"
              height="110px"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Flush Out</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="1" v-model="flushOut" disabled>
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="0" v-model="flushOut" disabled>
              <label> No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CO Line baru -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <!-- <table class="table table-stripe" style="width:100%"> -->
        <table class="table table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="vertical-align: middle; width:30%; text-align: center;" rowspan="2">SKU Code</th>
              <th style="vertical-align: middle; width:30%; text-align: center;" rowspan="2" colspan="2" v-if="isExistSkuCombo">Item Combo</th>
              <th style="vertical-align: middle; width:10%; text-align: center;" rowspan="2">HU Label</th>
              <th style="text-align:center; width:5%;" colspan="2">Qty</th>
              <th style="vertical-align: middle; text-align:center;" colspan="4">List Price</th>
              <!-- Hide discount -->
              <!-- v-if="soType != null && soType.Code == 'ZDSM'"   -->
              <th style="vertical-align: middle; text-align:center; width:5%;" rowspan="2">Total Discount</th>
              <th style="vertical-align: middle; text-align:center; width:5%; max-width:70px;" rowspan="2">Rounding</th>
              <th style="vertical-align: middle; text-align:center; width:5%;" rowspan="2">Tax</th>
              <th style="vertical-align: middle; text-align:center; width:5%;" rowspan="2">Grand Total</th>
              <!-- <th style="vertical-align: middle; text-align:center;" rowspan="2">Promotion</th> -->
              <th style="vertical-align: middle; text-align:center; width:5%;" rowspan="2">Action</th>
            </tr>
            <tr>
              <th :style="{'text-align':'center',width:10+'%'}">HU</th>
              <th style="text-align:center; width:8%;">UOM</th>
              <th style="text-align:center; width:5%;">Unit</th>
              <th style="text-align:center; width:5%;">Cogs</th>
              <th style="text-align:center; width:5%;">Price</th>
              <th style="text-align:center; width:5%;">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <!-- Struktur baru, array-of-object -->
            <template v-for="(dt, index) in table.data">
              <!-- <vs-tr :key="index" v-for="(dt, index) in 3"> -->
              <!-- for start sku not combo -->
              <tr :key="index" v-if="!isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true))">
                <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <!-- <div :class="''" :key="index2" v-for="(dt2, index2) in dt.formInputs"> -->
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]"
                      @onSelected="onselected(dt.formInputs[0].value, index)"
                      @search-change="handlerSearchSelect"
                    /><br>
                    <!-- <strong v-if="dt.formInputs[0].value.type != undefined && dt.formInputs[0].value.type_name != undefined" class="text-success">
                      {{ "SKU Type" }}
                    </strong> : -->
                    <span class="badge badge-pill badge-success mt-5" v-if="dt.hu_labels.length>0">{{ dt.hu_labels.length>0&&!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true)?"Reguler": "Combo Item"}}</span>
                    <span class="badge badge-pill badge-success mt-5 ml-2" v-if="getStatusAllowPartial(dt)">Allow Partial</span>
                    <!-- <span v-if="dt.formInputs[0].value.type != undefined && dt.formInputs[0].value.type_name != undefined">
                      {{ dt.formInputs[0].value.type + " || " + dt.formInputs[0].value.type_name }}
                    </span> -->
                  </div>
                </td>
                <td v-if="isExistSkuCombo" style="vertical-align: middle;text-align:center;" :rowspan="dt.list_price.length+dt.rowSpanPlus" colspan="2"> -
                </td>
                <td v-if="dt.hu_labels.length > 0" style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <span :key="i" v-for="(dt, i) in dt.hu_labels">
                    {{ dt["unit"] + "." }}
                  </span>
                </td>
                <td v-else style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  {{ " - " }}
                </td>
                <td style="vertical-align: middle; text-align: center; " :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <!-- LAYOUT GOOD -->
                  <!-- <div> -->
                    <vs-row vs-justify="center">
                      <vs-col v-for="(q, ix) in dt.items_qty_hu" :key="ix" vs-type="flex" :style="'width :' +(dt.width>0?dt.width-35:35)+'px'" vs-justify="center" vs-align="center">
                        <vs-input v-model="dt.items_qty_hu[ix]" type="text" @change="calc_itemsLineQtyHU(index, ix)" @keypress="isNumber($event)"></vs-input>
                      </vs-col>
                    </vs-row>
                  <!-- </div> -->
                </td>
                <td style="vertical-align: middle; text-align: center;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <vs-input-number v-model="dt.items_qty" class="w-full" placeholder="" @input="function(v){
                      calc_itemsLineQtyConv(index,v)
                    }" @keypress="isNumber($event)"/>
                  <!-- <vs-input v-model="dt.items_qty" type="text" class="w-full" @change="calc_itemsLineQtyConv(index)" @keypress="isNumber($event)"></vs-input> -->
                </td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <!-- Hide discount -->
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;">
                  <div v-if="index == table.data.length-1">
                    <vx-tooltip text="Add Item">
                      <vs-button
                        size="small"
                        color="green"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="handleAddItem()"
                      />
                    </vx-tooltip>
                    <vx-tooltip v-if="dt.formInputs[0].value.code !== undefined" text="Delete Item">
                      <vs-button
                        size="small"
                        color="red"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click="handleRemoveItem(index)"
                        class="mt-2"
                      />
                    </vx-tooltip>
                  </div>
                  <div v-else style="align-items: center; vertical-align: middle;">
                    <vx-tooltip text="Delete Item">
                      <vs-button
                        size="small"
                        color="red"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click="handleRemoveItem(index)"
                      />
                    </vx-tooltip>
                  </div>
                </td>
              </tr>
              <!-- List Price -->
              <!-- diubah jadi template -->
              <template v-for="(lp, i) in table.data[index].list_price">
                <tr :key="index+'-'+i" :class="dt.qty_hu[i]>0?'table-expend':''" v-if="(!isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true)))&&dt.qty_hu[i]>0" >
                  <!-- <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;" :rowspan="lp.is_expand?dt.list_price.length:null"> -->
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:center;" :rowspan="lp.is_expand?2:null">
                  {{ dt.qty_hu[i] + ' (' + lp.unit + ')' }}
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(lp.cogs) }} <br>
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ '@' + formatCurrency(lp.price) }} <br>
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.subtotal.price[i]) }} <br>
                  </td>
                  <!-- Hide discount -->
                  <!-- v-if="soType != null && soType.Code == 'ZDSM'"  -->
                  <td  class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                    <b>{{formatCurrency(dt.subtotal.disc[i])}}</b><a href="javascript:void(0);" v-on:click="detailDiscShow(index,i)"><i class="fa fa-eye"></i></a>
                  </td>
                  <td style="vertical-align: middle; text-align:center;">
                    <div>
                      <vs-row vs-justify="center">
                        <vs-col vs-justify="center" vs-align="center">
                          <vs-input v-model="lp.rounding" type="text" @input="onInputRounding(index, i)" style="max-width: 75px;"></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                    <!-- {{ "rounding here" }} -->
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.subtotal.tax[i]) }} <br>
                  </td>

                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.grand_total[i]) }} <br>
                  </td>
                  <td v-if="lp.is_head" style="vertical-align: middle; text-align:right;">
                    <div v-if="index == table.data.length-1 && lp.is_head">
                      <vx-tooltip text="Add Item">
                        <vs-button
                          size="small"
                          color="green"
                          icon-pack="feather"
                          icon="icon-plus"
                          @click="handleAddItem()"
                        />
                      </vx-tooltip>
                      <vx-tooltip v-if="dt.formInputs[0].value.code !== undefined" text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                          class="mt-2"
                        />
                      </vx-tooltip>
                    </div>
                    <div v-else-if="lp.is_head" style="align-items: center; vertical-align: middle;">
                      <vx-tooltip text="Delete Item">
                        <vs-button
                        size="small"
                        color="red"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click="handleRemoveItem(index)"
                        />
                      </vx-tooltip>
                    </div>
                    <div v-else style="align-items: center; vertical-align: middle;"></div>
                  </td>
                </tr>
                <tr v-else :key="index+'-'+i"></tr>
                <tr :key="'expand-head-'+index+'-'+i" v-if="lp.is_expand && (!isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true)))">
                  <td colspan="7">
                  <!-- expand-head -->
                    <tr>
                      <!-- Hide Width, height, weight, length (SIT 1 - 04/04/2024) -->
                      <!-- <th>Width<br>
                        @{{ lp.width }}
                      </th>
                      <th>
                        Height <br>
                        @{{ lp.height }}
                      </th>
                      <th>Length<br>
                        @{{ lp.length }}
                      </th> -->
                      <th>Weight<br>
                        @{{ lp.weight }}
                      </th>
                      <th>Volume<br>
                        @{{ lp.volume }}
                      </th>
                      <th>Net Weight<br>
                        @{{ lp.net_weight }}
                      </th>
                      <th>Gross Up</th>
                      <th>DPP</th>
                      <th>Price Rule Code</th>
                      <th>Price Rule Valid From</th>
                      <th>Price Rule Valid To</th>
                      <th>Division Code</th>
                      <th>Division Description</th>
                    </tr>
                    <!-- expand-body -->
                    <tr>
                      <!-- <td>{{ lp.total_width }}</td>
                        <td>{{ lp.total_height }}</td>
                        <td>{{ lp.total_length }}</td> -->
                        <td>{{ lp.total_weight }}</td>
                        <td>{{ lp.total_volume }}</td>
                      <td>{{ lp.total_net_weight }}</td>
                      <td>{{ formatCurrency(lp.gross_up) }}</td>
                      <td>{{ formatCurrency(lp.dpp) }}</td>
                      <td>{{ lp.price_rule_code }}</td>
                      <td>{{ lp.price_rule_valid_from }}</td>
                      <td>{{ lp.price_rule_valid_to }}</td>
                      <td>{{ lp.division_code }}</td>
                      <td>{{ lp.division_description }}</td>
                    </tr>
                  </td>
                </tr>
              </template>
              <!-- for end sku not combo -->

              <!-- for start sku combo -->
              <tr :key="index" v-if="isExistSkuCombo&&(dt.hu_labels.length>0&&dt.hu_labels[0].is_combo&&dt.data_combo)">
                <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;" :rowspan="
                dt.rowSpanPlus>=0?dt.rowSpanPlus+2:1
                  ">
                  <!-- <div :class="''" :key="index2" v-for="(dt2, index2) in dt.formInputs"> -->
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]"
                      @onSelected="onselected(dt.formInputs[0].value, index)"
                      @search-change="handlerSearchSelect"
                    />
                  </div>
                </td>
              </tr>

              <template v-for="(dtCombo,iCombo) in dt.data_combo">
                <tr :key="index+'-c-'+iCombo" :class="dt.qty_hu[iCombo]>0?'table-expend-combo':''" v-if="isExistSkuCombo&&(dt.hu_labels.length>0&&dt.data_combo&&dt.data_combo.length>0)">
                  <!-- dt.rowSpanPlus -->
                  <td class="combo" style="vertical-align: middle;" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null" colspan="2">
                    [{{ dtCombo.ItemCode }}] {{ dtCombo.ItemName }}<br>

                    <span class="badge badge-pill badge-success">{{ "Combo Item"}}</span>
                    <!-- <strong v-if="dtCombo.ItemType != undefined && dtCombo.ItemTypeName != undefined" class="text-success">
                      {{ "SKU Type" }}
                    </strong>
                    <span v-if="dtCombo.ItemType != undefined && dtCombo.ItemTypeName != undefined">
                      : {{ dtCombo.ItemType + " || " + dtCombo.ItemTypeName }}
                    </span> -->
                  </td>
                  <td v-if="dt.hu_labels.length > 0" style="vertical-align: middle;" class="combo" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    <span :key="i" v-for="(dt, i) in dt.hu_labels">
                      {{ dt["unit"] + "." }}{{ dtCombo.UnitName }}
                    </span>
                  </td>
                  <td v-else style="vertical-align: middle;" class="combo" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    {{ " - " }}
                  </td>
                  <td style="vertical-align: middle;" class="combo" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    <!-- LAYOUT GOOD -->
                    <div>
                      <vs-row vs-justify="center">
                        <vs-col v-for="(q, ix) in dt.items_qty_hu" :key="ix" vs-type="flex" :style="'width :' +(dtCombo.width>0?dtCombo.width:70)+'px'" vs-justify="center" vs-align="center">
                          <vs-input v-model="dt.items_qty_hu[ix]" type="text" disabled="true"></vs-input>
                          <vs-input v-model="dt.data_combo[iCombo].total_qty" type="text" disabled="true"></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                  </td>
                  <td style="vertical-align: middle; text-align: center;" class="combo" v-if="iCombo==0" :rowspan="(dt.rowSpanPlus>0?dt.rowSpanPlus+1:1)">
                    <vs-input-number v-model="dt.items_qty" class="w-full" placeholder="" @input="function(v){
                      calc_itemsLineQtyConv(index,v)
                    }"/>
                  </td>
                  <td style="vertical-align: middle; text-align:center;" class="combo-hover" v-on:click="expandDetail(index,iCombo)" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    {{ dt.list_price.length > 0?dt.data_combo[iCombo].total_qty + ' (' + dt.list_price[iCombo].unit + ')':'' }}
                  </td>
                  <td style="vertical-align: middle; text-align:right;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0 ? formatCurrency(dt.list_price[iCombo].cogs):0 }}
                  </td>
                  <td style="vertical-align: middle; text-align:right;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    @{{ dt.list_price.length > 0 ? formatCurrency(dt.list_price[iCombo].price):0 }}
                  </td>
                  <td style="vertical-align: middle; text-align:right;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.price[iCombo]):0 }}
                  </td>

                  <td style="vertical-align: middle; text-align:right;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.disc[iCombo]):0 }}
                    <a href="javascript:void(0);" v-on:click="detailDiscShow(index,iCombo)"><i class="fa fa-eye"></i></a>
                  </td>
                  <td style="vertical-align: middle;">
                    <div v-if="dt.list_price.length > 0">
                      <vs-row vs-justify="center">
                        <vs-col vs-justify="center" vs-align="center">
                          <vs-input v-model="dt.list_price[iCombo].rounding" type="text" @input="onInputRounding(index, iCombo)" style="max-width: 75px;"></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                    <!-- {{ "rounding here" }} -->
                  </td>
                  <td style="vertical-align: middle; text-align:right;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.tax[iCombo]):0 }}
                  </td>
                  <td style="vertical-align: middle; text-align:right;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.grand_total[iCombo]):0 }}
                  </td>
                  <td v-if="iCombo==0" style="vertical-align: middle;" >
                    <div v-if="index == table.data.length-1">
                      <vx-tooltip text="Add Item">
                        <vs-button
                          size="small"
                          color="green"
                          icon-pack="feather"
                          icon="icon-plus"
                          @click="handleAddItem()"
                        />
                      </vx-tooltip>
                      <vx-tooltip v-if="dt.formInputs[0].value.code !== undefined" text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                          class="mt-2"
                        />
                      </vx-tooltip>
                    </div>
                    <div v-else style="align-items: center; vertical-align: middle;">
                      <vx-tooltip text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                        />
                      </vx-tooltip>
                    </div>
                  </td>
                </tr>
                <!-- v-if="dt.list_price[iCombo].is_expand" -->
                <tr :key="index+'-c-expand-'+iCombo" v-if="dt.list_price[iCombo]?dt.list_price[iCombo].is_expand:false">
                  <td colspan="7">
                  <!-- expand-head -->
                    <tr>
                      <!-- <th>Width({{ dtCombo.UnitName }})<br>
                        @{{ dt.list_price[iCombo].width }}
                      </th>
                      <th>
                        Height <br>
                        @{{ dt.list_price[iCombo].height }}
                      </th>
                      <th>Length<br>
                        @{{ dt.list_price[iCombo].length }}
                      </th> -->
                      <th>Weight<br>
                        @{{ dt.list_price[iCombo].weight }}
                      </th>
                      <th>Volume<br>
                        @{{ dt.list_price[iCombo].volume }}
                      </th>
                      <th>Net Weight<br>
                        @{{ dt.list_price[iCombo].net_weight }}
                      </th>
                      <th>Price Rule Code</th>
                      <th>Price Rule Valid From</th>
                      <th>Price Rule Valid To</th>
                      <th>Division Code</th>
                      <th>Division Description</th>
                    </tr>
                    <!-- expand-body -->
                    <tr>
                      <!-- <td>{{ dt.list_price[iCombo].total_width }}</td>
                      <td>{{ dt.list_price[iCombo].total_height }}</td>
                      <td>{{ dt.list_price[iCombo].total_length }}</td> -->
                      <td>{{ dt.list_price[iCombo].total_weight }}</td>
                      <td>{{ dt.list_price[iCombo].total_volume }}</td>
                      <td>{{ dt.list_price[iCombo].total_net_weight }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_code }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_valid_from }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_valid_to }}</td>
                      <td>{{ dt.list_price[iCombo].division_code }}</td>
                      <td>{{ dt.list_price[iCombo].division_description }}</td>
                    </tr>
                  </td>
                </tr>
              </template>
              <!-- for end sku combo -->

            </template>

            <!-- row free good -->
            <template v-if="existing_fg">
              <tr>
                <td colspan="100%" class="p-0"> <vs-divider><b>Free Good</b></vs-divider></td>
              </tr>

              <tr>
                <th style="vertical-align: middle; width:30%; text-align: center;">SKU Code</th>
                <th v-if="!isExistSkuCombo"></th>
                <!-- v-if="isExistSkuCombo" -->
                <th style="vertical-align: middle; text-align: center;">Promotion Type</th>
                <th style="vertical-align: middle; text-align: center;">Promotion Code</th>
                <th v-if="isExistSkuCombo"></th>
                <th v-if="isExistSkuCombo" colspan="2"></th>
                <th style="text-align:center;">Unit</th>
                <th style="vertical-align: middle; text-align:center;">Cogs</th>
                <th style="vertical-align: middle; text-align:center;">Price</th>
                <th style="vertical-align: middle; text-align:center;">Subtotal</th>
                <th style="vertical-align: middle; text-align:center;">Discount</th>
                <th style="vertical-align: middle; text-align:center;"></th>
                <th style="vertical-align: middle; text-align:center;"></th>
                <th style="vertical-align: middle; text-align:center;"></th>
                <th v-if="!isExistSkuCombo"></th>
                <th v-if="isExistSkuCombo"></th>
              </tr>
              <template v-for="(dt, index) in table.free_goods">
                <!-- <vs-tr :key="index" v-for="(dt, index) in 3"> -->
                <!-- for start sku not combo -->
                <!-- <tr :key="index+'-fg'" v-if="!isExistSkuCombo" > -->
                <tr :key="index+'-fg'" v-if="existing_fg" >
                  <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;">
                        <!-- @onSelected="onselected2" -->
                    <FormInput
                        :components="table.free_goods[index].fg_component"
                        :i_data="index"
                        @onSelected="function(v){
                          onSelectedFG(index,v)
                        }"
                        @search-change="function(s){
                          return handlerSearchSelectFG(index,s)
                        }"
                      /><br>
                      <span v-if="dt.allow_partial == 1" class="badge badge-pill badge-success"> Allow Partial </span>
                  </td>
                  <td style="vertical-align: middle; text-align: center; " v-if="!isExistSkuCombo"> - </td>
                  <td style="vertical-align: middle; text-align: center; "> {{ table.free_goods[index].promotion_type }}</td>
                  <td style="vertical-align: middle; text-align: center; ">{{ table.free_goods[index].promotion_code }}</td>
                  <td style="vertical-align: middle; text-align: center; " v-if="isExistSkuCombo"> - </td>
                  <td style="vertical-align: middle; text-align: center; " v-if="isExistSkuCombo" colspan="2"> - </td>
                  <td style="vertical-align: middle; text-align: center; ">{{ table.free_goods[index].fg_component.value?table.free_goods[index].fg_component.value.qty:0 }} {{ table.free_goods[index].fg_component.value?table.free_goods[index].fg_component.value.item_unit:"" }}</td>
                  <td style="vertical-align: middle;">{{ table.free_goods[index].fg_component.value?table.free_goods[index].fg_component.value.cogs:0 }}</td>
                  <td style="vertical-align: middle;">{{ formatCurrency(table.free_goods[index].fg_component.value?table.free_goods[index].fg_component.value.price:0) + (table.free_goods[index].fg_component.value?(table.free_goods[index].fg_component.value.capped?" (capped)":""):"")}}</td>
                  <td style="vertical-align: middle;">{{ formatCurrency(table.free_goods[index].fg_component.value?table.free_goods[index].fg_component.value.discount:0) }}</td>
                  <td style="vertical-align: middle;">{{ formatCurrency(table.free_goods[index].fg_component.value?table.free_goods[index].fg_component.value.discount:0) }}</td>
                  <td style="vertical-align: middle;"></td>
                  <td style="vertical-align: middle;"></td>
                  <td v-if="!isExistSkuCombo" ></td>
                  <td v-if="isExistSkuCombo" ></td>
                  <!-- <td style="vertical-align: middle;"></td> -->
                </tr>
              </template>
            </template>
            <tr v-if="table.data[0].list_price.length != 0 && this.CalcRespData != null ">
              <td :colspan="isExistSkuCombo?9:7" style="vertical-align: middle; text-align:right;"> <b>Total</b> </td>
              <!-- Hide discount -->
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_order.toFixed(2)) }}</b> </td>
              <!-- v-if="soType != null && soType.Code == 'ZDSM'" -->
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_discount.toFixed(3)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_rounding.toFixed(2)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_tax.toFixed(2)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_billing.toFixed(2)) }}</b> </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <vs-button v-if="!calculated" class="float-left" type="border" color="primary" @click="handleCalculate()">
          Calculate
        </vs-button>
        <vs-button v-else class="float-left" color="success" type="border" @click="handleSubmit()">
          Submit
        </vs-button>
      </div>
      <div v-if="calculated" class="vx-col w-1/2">
        <table class="table" style="width:100%">
          <thead>
            <tr>
              <td style="text-align: right;"><b>Total Net Weight</b></td>
              <!-- <td style="text-align: right;">{{ formatCurrency(this.CalcRespData.total_net_value) }}</td> -->
              <td style="text-align: right;">{{ formatCurrency(this.CalcRespData.total_net_value.toFixed(2)) }}</td>
            </tr>
            <tr>
              <td style="text-align: right;"><b>Total Weight</b></td>
              <!-- <td style="text-align: right;">{{ formatCurrency(this.CalcRespData.total_weight) }}</td> -->
              <td style="text-align: right;">{{ formatCurrency(this.CalcRespData.total_weight.toFixed(2)) }}</td>
            </tr>
            <tr>
              <td style="text-align: right;"><b>Total Volume</b></td>
              <!-- <td style="text-align: right;">{{ formatCurrency(this.CalcRespData.total_volume) }}</td> -->
              <td style="text-align: right;">{{ formatCurrency(this.CalcRespData.total_volume.toFixed(2)) }}</td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <vs-popup fullscreen title="Discount Details" :active.sync="isDetailDisc">
      <div class="vx-row m-2 mt-6">
        <div class="vx-col w-full" v-if="isDetailDisc">
          <DetailDiscount :DetailDataDiscount="detailDataDiscount" :DetailPricing="detailPricing"></DetailDiscount>
        </div>
      </div>
  </vs-popup>
  </vx-card>
</template>

<script>
import moment from "moment";
import FormInput from "./form_input.vue"
import Datepicker from "vuejs-datepicker";
import DetailDiscount from "./detail_discount.vue"
import AddressComponent from "@/components/master/AddressOtc";
export default {
  components :{
    Datepicker,
    FormInput,
    AddressComponent,
    DetailDiscount,
  },

  data() {
    const id = this.$route.params.id;
    const username =this.$userLogin.user_name
    return {
      baseUrl: "/api/sfa/v1/customer-order/create-v5",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      createdby:{
        name:username,
        date:new Date()
      },
      trxTimeUnix:"",
      detailPricing: {},
      tempSelectFgChoice:[],
      existing_fg:false,
      detail: false,
      widthHu:150,
      isErrorBillTo:false,
      msgErrorBillto:"",
      isErrorShippTop:false,
      msgErrorShippTo:"",
      deleteId: null,
      AllOptionTerritory: [],
      optionTerritory: [],
      optionCustomer: [],
      optionBillTo: [],
      optionShippTo: [],
      isPBF:0,
      isLoadingCus:false,
      isLoadingSales:false,
      isLoadingShipto:false,
      countLoadingShipto:0,
      moq:{
        id:0,
        customer_address_id:0,
        customer_group_id1:0,
        amount:0,
      },
      selectedTerritory: {
        id:null
      },
      selectedCustomer: {
        code:"",
        name:""
      },
      // selectedBillTo: null,
      selectedBillTo: {
        ID:null,
        customer_id:"",
        contact_name:"",
        code:"",
        address:"",
        search:"",
      },
      // selectedShippTo: null,
      selectedShippTo: {
        ID:null,
        customer_id:"",
        contact_name:"",
        code:"",
        address:"",
        search:"",
      },
      searchCust:"",
      searchItem:"",
      searchSales:"",
      dataCustomerOrder:{
        code:null,
      },
      isDetailDisc:false,
      detailDataDiscount:[
        {
          qty :0,
          amount:0,
          promotion_id:0,
          promotion_code:"",
          promotion_type:0,
          promotion_sequence:0,
          promotion_item_req_id:0,
          promotion_step_id:0,
          base:0,
          base_amount:0,
          discount_type:0,
          type:0,
          main:0,
          main_rate:0,
          add:0,
          add_rate:0,
          cap:0,
          capped:false,
          from:0,
          to:0,
        },
      ],
      table: this.tableDefaultState(),
      dateCo:null,
      po_date: null,
      duedate:null,
      note:null,
      rDeliveryDate:null,
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      },
      title:"Form sales-order",
      id: id,
      allowPartial: 1,
      flushOut: 0,
      poRefCode: null,
      isOneTime: false,
      sameAddress: false,
      oneTimeCust: {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          countryID: 0,
          provinceName: "",
          provinceID: 0,
          cityName: "",
          cityID: 0,
          transportZone: "",
          districtName: "",
          districtID: 0,
          subdistrictName: "",
          subdistrictID: 0,
          showWarningAddress: false,
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      },
      pricingGroup: {
        selected: null,
        options: [],
        all_options: [],
      },
      update: false,
      calculated: false,
      optionSoType: [],
      allOptionSoType: [],
      soType: null,
      zone: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesChannel: {
        selected: null,
        option: [],
        all_options: [],
      },
      customerCategory: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesPersonal: {
        selected: null,
        default_cust: null,
        option: [],
        all_options: [],
      },
      paymentTerm: {
        selected: null,
        option: [],
        all_options: [],
      },
      deliveryType: {
        selected: null,
        option: [],
        all_options: [],
      },
      expenseType: {
        selected: {
            id :0,
            name :'',
          },
        option: [
          {
            id :1,
            name :'Principal',
          },
          {
            id :2,
            name :'Distributor',
          }
        ],
      },
      showOTCForm: false,
      CalcRespData: null,
      selectedCustDeposit: [],
      optionCustDeposit: [],
      showCustDeposit: false,
      showExpenseType : false,
      isExistSkuCombo:false,
      temp_free_good:{
          discount_type:0,
          discount_string:"",
          promotion_type:"",
          promotion_code:"",
          promotion_id: 0,
          promotion_item_req_id: 0,
          promotion_sequence: 0,
          promotion_step_id: 0,
          line:0,
          qty: 0,
          to: 0,
          from: 0,
          base:0,
          base_amount: 0,
          base_string: "",
          fg_component:{
            disabled: false,
            validate: "required",
            name: "fg_sku",
            placeholder: "Sku Code",
            head:"Sku Code",
            type: "multiselect",
            track_by:"item_unit_id",
            isLoading:false,
            multiple: false,
            allowempty: false,
            value: {},
            optionValue:[],
          },
        },
        pararelRunDateDisabled:{
          from: new Date()
        },
        DateDisabled:{
          from: new Date(),
          to: new Date()
        },
    };
  },
  methods: {
    getTempPromoFG(){
      const data = {
          discount_type:0,
          discount_string:"",
          promotion_type:"",
          promotion_code:"",
          promotion_id: 0,
          promotion_item_req_id: 0,
          promotion_sequence: 0,
          promotion_step_id: 0,
          line:0,
          qty: 0,
          to: 0,
          from: 0,
          base:0,
          base_amount: 0,
          base_string: "",
          fg_component:{
            disabled: false,
            validate: "required",
            name: "fg_sku",
            placeholder: "Sku Code",
            head:"Sku Code",
            type: "multiselect",
            track_by:"item_unit_id",
            isLoading:false,
            multiple: false,
            allowempty: false,
            value: {
              sku_code:"",
              item_id:0,
              item_name:"",
              item_unit:"",
              item_unit_id:0,
              text:"",
              qty:0,
              price:0,
              discount:0,
              cap:0,
              capped:false,
              cogs:0,
            },
            optionValue:[],
          }
        }
      return data
    },
    tableDefaultState() {
      return {
        data: [
          {
            id:1,
            formInputs:[
              {
                id:1,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                isLoading:false,
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
            ],
            items_hu: '0',
            items_qty: '0',
            qty_hu: [],
            hu_labels: [],
            list_price: [],
            reg_disc: [],
            promotion: [],
            tax: [],
            tax_rate: null,
            subtotal: {
              price: [],
              disc: 0,
              tax: [],
              all_price: 0,
              all_tax: 0,
            },
            colWidth: 0,
          }
        ],
        free_goods:[{
          discount_type:0,
          discount_string:"",
          promotion_type:"",
          promotion_code:"",
          promotion_id: 0,
          promotion_item_req_id: 0,
          promotion_sequence: 0,
          promotion_step_id: 0,
          line:0,
          qty: 0,
          to: 0,
          from: 0,
          base:0,
          base_amount: 0,
          base_string: "",
          fg_component:{
            disabled: false,
            validate: "required",
            name: "fg_sku",
            placeholder: "Sku Code",
            head:"Sku Code",
            type: "multiselect",
            track_by:"item_unit_id",
            isLoading:false,
            multiple: false,
            allowempty: false,
            value: {
              sku_code:"",
              item_id:0,
              item_name:"",
              item_unit:"",
              item_unit_id:0,
              text:"",
              qty:0,
              price:0,
              discount:0,
              cap:0,
              capped:false,
              cogs:0,
            },
            optionValue:[],
          },
        }],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handlerSearchCustomer(search){
      this.searchCust = search
      this.getDataCustomer();
    },
    handlerSearchTerritory(search){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }

      let userLogin = this.getUserLogin();
      if (userLogin.territory_id != 0){
        search = new RegExp(search, "gi")
        if (search == ""){
          this.optionTerritory = this.AllOptionTerritory.filter(v => {
            return v.id == userLogin.territory_id;
          });
        } else {
          this.optionTerritory = this.AllOptionTerritory.filter(v => {
            return v.name.match(search) != null;
          });
        }
      }
    },
    handlerSearchZone(search){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      search = new RegExp(search, "gi")
      this.zone.option = this.zone.all_options.filter(v => {
        return v.name.match(search) != null;
      });
    },
    handlerSearchSales(search){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      this.searchSales = search
      if(search != ""){
        this.getOptionSalesID(false);
      }
    },
    getUserLogin(){
      return this.$userLogin;
    },
    isSales(){
      return this.$userLogin.territory_id != 0;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              let userLogin = this.getUserLogin();

              this.AllOptionTerritory = resp.data.records;
              this.optionTerritory = resp.data.records;

              console.log("this.AllOptionTerritory", this.AllOptionTerritory)
              if (this.optionTerritory.length > 0) {

                if (this.isSales()) {
                  console.log("Masuk sini 1401")
                  this.optionTerritory = this.AllOptionTerritory.filter(function(ter) {
                    return ter.id == userLogin.territory_id;
                  });
                  this.AllOptionTerritory = this.optionTerritory;
                } else {
                  console.log("Masuk sini 1401, user is superadmin")
                }
                this.selectedTerritory = null;
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          }
        });
    },
    getOptionZones() {
      console.log("this.optionTerritory",this.optionTerritory)
      this.zone.option = [];
      this.zone.all_options = [];
      this.$vs.loading();
      this.$http.get("api/oms/v1/sfa-sales-order/zone").then((resp) => {
        if (resp.status == "success") {
          this.zone.option = resp.data.records;
          this.zone.all_options = resp.data.records;
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Zone option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      });
    },
    getOptionSalesChannel() {
      let url = "/api/oms/v1/sfa-sales-order/sales-channel/list";
      this.$http.get(url).then(
        resp => {
          if(resp.code == 200){
            this.salesChannel.option = [];
            this.salesChannel.all_options = [];
            for(var salesChannel = 0; salesChannel<resp.data.length; salesChannel++){
              if(resp.data[salesChannel].name == 'Sales Taking Order'){
                this.salesChannel.option.push(resp.data[salesChannel])
                this.salesChannel.all_options.push(resp.data[salesChannel])
              }
            }
            this.salesChannel.selected = this.salesChannel.option[0];
          }else{
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Sales Channel option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            // console.log(resp)
          }
        }
      );
    },
    getOptionCustomerCategory() {
      this.$http.get("api/oms/v1/sfa-sales-order/customer-category-all").then(
        resp => {
          if(resp.code == 200){
            this.customerCategory.option = []
            this.customerCategory.all_options = []
            for(var customerCategory = 0; customerCategory<resp.data.length;customerCategory++){
              this.customerCategory.option.push(resp.data[customerCategory])
              this.customerCategory.all_options.push(resp.data[customerCategory])
            }
            this.customerCategory.selected = null;
          }else{
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Distribution Channel option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        }
      );
    },
    getOptionSalesID(is_default) {
      let superadmin, territory_id, sales_channel_id, sales_personal_id;
      console.log("this.$userLogin", this.$userLogin)
      console.log("this.$userLogin.roles", this.$userLogin.roles)
      this.$userLogin.roles.filter(function(item) {
        if(item.name == "superadmin" || item.name == "admin" || item.name == "sales_admin"){ // role nya kalau diatas sales, maka boleh pilih semua opsi sales
          superadmin = true;
        }else{
          superadmin = false;
        }
      });
      console.log("superadmin", superadmin)
      this.isLoadingSales = true
      territory_id = (this.selectedTerritory != null && this.selectedTerritory.id) ? this.selectedTerritory.id : 0;
      sales_channel_id = this.salesChannel.selected != null ? this.salesChannel.selected.ID : 0;
      sales_personal_id = superadmin ? 0 : this.$userLogin.id ;
      this.$http.get("/api/oms/v1/sfa-sales-order/sales-order/personals",{
          params: {
            search: this.searchSales,
            territory_id: territory_id,
            sales_channel_id: sales_channel_id,
            sales_personal_id: sales_personal_id,
          }
        }).then(
        resp => {
          this.isLoadingSales = false
          if(resp.code == 200){
            this.salesPersonal.option = []
            this.salesPersonal.all_options = []
            let data = resp.data.salesman
            for(var idx = 0;idx<data.length;idx++){
              this.salesPersonal.option.push(data[idx])
              this.salesPersonal.all_options.push(data[idx])
            }
            if (!superadmin) {
              // set default Sales ID jika bukan superadmin
              let selectedSalesman = this.salesPersonal.option.filter((v) => {
                return v.ID == this.$userLogin.personal_id;
              });
              if(selectedSalesman.length > 0) {
                this.salesPersonal.selected = selectedSalesman[0]
              }
              if(this.salesPersonal.selected.length > 0){
                this.salesPersonal.option = this.salesPersonal.selected;
                this.salesPersonal.default_cust = this.salesPersonal.selected;
              } else {
                // kalau tidak ada salesman yang cocok saat looping, maka dikembalikan ke default cust
                if (this.salesPersonal.selected == null) {
                  this.salesPersonal.selected = this.salesPersonal.default_cust;
                  this.salesPersonal.option = this.salesPersonal.default_cust;
                }
              }
            } else {
              // set default by customer
              let selectedSalesman = this.salesPersonal.option.filter((v) => {
                return v.ID == this.selectedShippTo.sales_personal_id;
              });
              if(selectedSalesman.length > 0) {
                this.salesPersonal.selected = selectedSalesman[0]
              }
            }

            console.log(" is_default ", is_default)
            console.log("sales_personal_id", sales_personal_id)
            if(sales_personal_id != 0 && this.searchSales == ""){
              // set default Sales ID saat dipanggil dari selectedCust
              this.salesPersonal.selected = this.salesPersonal.option[0];
            } else {

            }
          } else {
            // console.log(resp)
          }
        }
      );
    },
    getAllDataPricingGroup() {
      this.$vs.loading();
      this.$http.get("api/oms/v1/sfa-sales-order/pricing-group", {
        params: {
          order: "name",
          sort: "asc",
        },
      }).then((resp) => {
        if (resp.status == "success") {
          this.pricingGroup.options = resp.data.records;
          this.pricingGroup.all_options = resp.data.records;
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Pricing Group option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      });
    },
    getOptionPaymentTerm() {
      this.$vs.loading();
      // this.$http.get(`api/sfa/v1/mobile-preparation/payment-method`, { params: {is_mobile: true}})
      // this.$http.get(`api/sfa/v1/payment/customer-order-payment`)
      this.$http.get("api/oms/v1/sfa-sales-order/payment-term", {
        params: {
          order: 'name',
          sort: 'asc',
        }
      })
      .then(resp => {
        if (resp.code == 200) {
          // this.paymentTerm.option = resp.data.records;
          this.paymentTerm.all_options = resp.data.records;
          this.paymentTerm.selected = null;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Payment Term option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    getOptionDeliveryType() {
      this.$vs.loading();
      this.$http.get("api/oms/v1/sfa-sales-order/delivery-type", {
        params: {
          order: 'id',
          sort: 'asc',
        }
      }).then(resp => {
        if (resp.code == 200) {
          this.deliveryType.option = resp.data.records;
          this.deliveryType.all_options = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Delivery Type option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    getSOTypes(){
      // this.$vs.loading();
      return new Promise((resolve, reject) => {
      // this.$http.get("api/v1/master/sales-order-type", {
      this.$http.get("api/oms/v1/sfa-sales-order/sales-order-type", {
          params: {
            order: 'name',
            sort: 'asc',
          }
        }).then(resp => {
            // console.log(resp)
            if (resp.code == 200) {
              if (resp.data.records && resp.data.records.length>0) {
                this.optionSoType = resp.data.records.filter((elm)=>{
                  return elm.Code !="ZDCV"
                })
              }
              this.allOptionSoType = this.optionSoType;
              resolve(true);
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Sales Order Type option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                reject(false);
            }
          // this.$vs.loading.close();
        });
      });
    },
    onselected2(v,comp,i){
      console.log(v);
      this.table.free_goods[i].fg_component.value = v
      console.log("this.comp",comp,i);
      console.log("this.table.free_goods[i]",this.table.free_goods[i]);
    },
    onselected(select, i){
      /*
        Dicek dulu, harus isi form dengan benar.
        Case 1
        1. harus pilih data customer dan salesman
        2. piliih data terkait SO (type, date, delivery, dll)
        jika point 1 & 2 sudah terisi, baru boleh pilih sku

        Case 2
        1. harus pilih data customer dan salesman
        2. piliih data terkait SO (type, date, delivery, dll)
        jika sku sudah terpilih, tidak boleh mengubah point 1 & 2.
        user harus ngosongin sku yg terpilih dulu baru bisa ubah point 1 & 2 (kembali ke case 1)
      */

      let check_res = this.checkFormComplete();

      console.log("this.table.data[i]",this.table.data[i]);

      if (!check_res.is_complete) {
        // if form not complete, value item dinolkan, dan etc" nya juga dinolkan
        this.table.data[i].formInputs[0].value = {};
        this.table.data[i].data_combo = [];
        this.table.data[i].items_qty_hu = [];
        this.table.data[i].qty_hu = [];
        this.table.data[i].items_hu = '0';
        this.table.data[i].items_qty = '0';
        this.table.data[i].hu_labels = [];
        this.table.data[i].list_price = [];
        this.table.data[i].reg_disc = [];
        this.table.data[i].promotion = [];
        this.table.data[i].tax = [];
        this.table.data[i].tax_rate = null;
        this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };

        for (let i = check_res.msg_incomplete.length-1; i > -1; i--) {
          this.$vs.notify({
            title: "Warning",
            text: check_res.msg_incomplete[i],
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }

        this.calculated = false;
        return;
      } else {
        let checkItem, is_duplicate = false;
        // check duplicate items
        if(this.table.data.length > 1){
          this.table.data.forEach((item, j) => {
            checkItem = item.formInputs[0].value;
            if(checkItem.code == select.code && i != j){
              is_duplicate = true;
            }
          });
        }
        // // console.log(currentItem, select)
        if(is_duplicate){
          // if duplicate, value item dinolkan, dan etc" nya juga dinolkan
          this.table.data[i].formInputs[0].value = {};
          this.table.data[i].items_qty_hu = [];
          this.table.data[i].qty_hu = [];
          this.table.data[i].data_combo = [];
          this.table.data[i].items_hu = '0';
          this.table.data[i].items_qty = '0';
          this.table.data[i].hu_labels = [];
          this.table.data[i].list_price = [];
          this.table.data[i].reg_disc = [];
          this.table.data[i].promotion = [];
          this.table.data[i].tax = [];
          this.table.data[i].tax_rate = null;
          this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
          this.$vs.notify({
            title: "Warning",
            text: "Duplicate item detected!",
            color: "warning",
            position: "top-right"
          });
          this.calculated = false
          return;
        }else{
          this.calculated = false
          this.$http.get("api/oms/v1/sfa-sales-order/item-hu", {
            params: {
              sku_code: select.code,
              co_type: this.soType == null ? "" : this.soType.Code,
              sales_personal_id: (this.salesPersonal.selected != null && this.salesPersonal.selected.length > 0) ? this.salesPersonal.selected[0].ID : 0,
              is_combo:select.is_combo?true:false,
            },
          }).then((resp) => {
            // console.log(resp)
            if (resp.status == "success") {
              // set column width property
              // console.log("colwidth before",this.table.data[i].colWidth);
              if (resp.data.hu.length>0) {
                this.table.data[i].colWidth = (6/resp.data.hu.length);
              }else{
                this.table.data[i].colWidth = 6;
              }
              // console.log("colwidth after",this.table.data[i].colWidth);
              //
              // ======= Add items dan HU =======
              //
              // untuk HU length 2, colspan 7, untuk HU length 3 colspan 8
              // SDNC21227
              console.log("i", i)
              console.log(this.table.data[i])
              console.log(this.table.data[i].hu_labels)
              console.log("resp.data.hu", resp.data.hu)
              this.table.data[i].hu_labels = resp.data.hu;
              this.table.data[i].tax_rate = resp.data.tax_rate;
              let sumHU = [];
              let data_Combo = []
              for (let k = 0; k < resp.data.hu.length; k++) {
                console.log("k", k)
                console.log("resp.data.hu[k]", resp.data.hu[k])
                sumHU.push(0);
                if (resp.data.hu[k].combo_items) {
                  resp.data.hu[k].combo_items.forEach((el)=>{
                    el.total_qty = 0
                    data_Combo.push(el)
                  })
                  this.isExistSkuCombo = true
                }
              }
              this.table.data[i].items_qty_hu = sumHU;
              this.table.data[i].qty_hu = sumHU;
              this.table.data[i].items_hu = sumHU.join('.');
              this.table.data[i].items_qty = 0;
              // reset line items price
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].promotion = [];
              this.table.data[i].tax = [];
              if (data_Combo&&data_Combo.length>0) {
                this.table.data[i].rowSpanPlus = data_Combo.length - 1
                if (this.table.data[i].rowSpanPlus < 0) {
                  this.table.data[i].rowSpanPlus = 0
                }
              }
              this.table.data[i].data_combo = data_Combo;
              // this.table.data[i].tax_rate = null;
              this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
            }else{
              this.$vs.notify({
                title: "error",
                text: resp.message,
                color: "danger",
                position: "top-right"
              });
            }
            console.log("this.table.data[i]",this.table.data[i])
          });
        }
      }

    },
    // onSelectedCus(select){

    // },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} - ${name}`;
    },
    customLableBillto({ contact_name,code, customer }) {
      if (contact_name!=undefined && code!=undefined && customer !=undefined && customer.name !=undefined){
        return `${code} - ${customer.name}`;
      } else {
        return `-`
      }
    },
    customLableShippTo({ contact_name, code, customer }) {
      if (contact_name!=undefined && code!=undefined && customer !=undefined && customer.name !=undefined){
        return `${code} - ${customer.name}`;
      } else {
        return `-`
      }
    },
    customLableCustDeposit({ Code, DepositValue }){
      return `${Code} - Value : ${this.formatCurrency(DepositValue)}`;
    },
    customLableCOType({ Code, Description }){
      return `${Code} - ${Description}`;
    },
    customLableDistChannel({ name, classification }){
      return `${name} - ${classification}`;
    },
    // onselectedTerritory(Select){

    // },
    // onselectedSalesChannel(){

    // },
    onselectedDistChannel(){
      if(!this.isOneTime){
        this.getDataCustomer();
        // console.log("saya terpanggil 1533")
        this.resetShipBillto();
      }
    },
    onselectedSalesman(){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      /*
        1. kalau canvas, maka item/sku nya hanya terbatas pada yang dibawa sales
        2. reset item yang kepilih dulu
        3. get data item nya dikasi param SOType
      */
      if (this.soType != null) {
        if (this.soType.Code == "ZDCV") {
          this.getItem();
          // re empty selected SKU
          this.table.data.forEach((dt,i) => {
            this.table.data[i].formInputs[0].value = {};
            this.table.data[i].items_qty_hu = [];
            this.table.data[i].items_hu = '0';
            this.table.data[i].items_qty = '0';
            this.table.data[i].hu_labels = [];
            this.table.data[i].list_price = [];
            this.table.data[i].reg_disc = [];
            this.table.data[i].promotion = [];
            this.table.data[i].tax = [];
            this.table.data[i].tax_rate = null;
            this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
          });
        }
      }
    },
    onselectedPricingGroup(){
      if(!this.isOneTime){
        this.getDataCustomer();
        // console.log("saya terpanggil 1569")
        this.resetShipBillto();
      }
    },
    onselectedZone(select){
      if(!this.isOneTime){
        this.getDataCustomer();
        this.optionTerritory = this.AllOptionTerritory.filter(v => {
          return v.code == select.territory_code;
        });
        this.selectedTerritory = this.optionTerritory[0];
        this.getOptionSalesID(false);
        // console.log("saya terpanggil 1580")
        this.resetShipBillto();
      }
    },
    onSelectedShipto(select){
    if (select.customer.status_block==1||select.customer.status_block==3) {
      this.$vs.notify({
        title: "Customer Order Block",
        text: "This customer cannot place an order",//This customer cannot place an order
        color: "danger",
        position: "top-right"
      });
      this.resetShipBillto()
      return
    }
      if(!this.isOneTime){
        console.log(select)

        if (this.selectedShippTo.customer_id != null ) {
          if (select.customer_id != this.selectedShippTo.customer_id){
            // Clear bill to address
            this.optionBillTo = [];
            this.selectedBillTo = {
              ID:null,
              customer_id:"",
              contact_name:"",
              code:"",
              address:"",
              search:"",
            };
            return;
          }
        }

        select.status = select.status.replace("/", "");
        var array_ = JSON.parse(select.status);
        // console.log("array_", array_)
        this.optionTerritory = this.AllOptionTerritory.filter(v => {
          return v.id == select.territory_id;
        });
        this.selectedTerritory = this.optionTerritory[0];

        if (array_.includes("Billing Address")) {
          // sudah dengan billing address, maka auto set billing address juga
          this.selectedBillTo = select;
        } else {
          this.searchBillto();
        }

        this.getDataCustomer();
        this.getOptionSalesID(true);
        this.getMoq()

        // console.log("this.selectedBillTo", this.selectedBillTo)
        // console.log("this.selectedShippTo", this.selectedShippTo)
      }
    },
    onSelectedBillto(){
    },
    onSelectedCustDeposit(){
      this.calculated = false;
    },
    onselectedPaymentTerm_(v){
      // due_invoice_period > 0 && is_cod = false && is_cbd = false;
      if (this.soType.Code=="ZDTO"&&!(v.due_invoice_period>0&&v.is_cod==false&&v.is_cbd==false)) {
          this.$vs.notify({
            title: "Info",
            text: "Can't select this payment_term",
            color: "danger",
            position: "top-right"
          });
          this.paymentTerm.selected = null
      }
      console.log(v)
      console.log(v.due_invoice_period>0&&v.is_cod==false&&v.is_cbd==false)
    },
    onselectedPaymentTerm(select){
      // set duedate after paymentterm selected (req deliv + due_invoice_period payment-term)
      let due_inv_period = select.due_invoice_period

      // due date di-hide, maka yang berperan jadi due-date adl rDD
      // let rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
      // let duedate = new Date(rDeliveryDate);
      // duedate.setDate( duedate.getDate() + due_inv_period)
      // this.duedate = duedate;

      let coDate = moment(this.dateCo).format("MM/DD/YYYY");
      let rdd = new Date(coDate);
      rdd.setDate( rdd.getDate() + due_inv_period)
      this.rDeliveryDate = rdd;
    },
    isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    },
    handlerSearchSelectFG(i,s){
      this.table.free_goods[i].fg_component.optionValue = this.table.free_goods[i].fg_component.optionOriginal.filter((el)=>{
        return el.text.toLowerCase().includes(s.toLowerCase());
      })
    },
    onSelectedFG(i,v){
      // this.table.free_goods[i].fg_component.optionValue = this.table.free_goods[i].fg_component.optionOriginal
      let tempTotalOrder = this.CalcRespData.total_order
      let tempTotalDisc = this.CalcRespData.total_discount
      let oldSelect = this.tempSelectFgChoice.filter((el)=>{
        return el.promotion_id == v.promotion_id
      })
      if (oldSelect.length>0) {
        oldSelect = oldSelect[0]
      }
      console.log("<<>>")
      console.log("before tempTotalOrder-",tempTotalOrder)
      console.log("before tempTotalDisc-",tempTotalDisc)
      if (oldSelect.subtotal>0) {
        tempTotalOrder -=oldSelect.subtotal
        tempTotalDisc -=oldSelect.subtotal
      }
      console.log("<<>>")
      console.log("after tempTotalOrder-",tempTotalOrder)
      console.log("after tempTotalDisc-",tempTotalDisc)
      this.tempSelectFgChoice = this.tempSelectFgChoice.filter((el)=>{
        return el.promotion_id != v.promotion_id
      })
      this.tempSelectFgChoice.push({
        promotion_id : v.promotion_id,
        item_unit_id : v.item_unit_id,
        subtotal     : v.subtotal,
      })
      console.log("<<>>")
      console.log("before tempTotalOrder+",tempTotalOrder)
      console.log("before tempTotalDisc+",tempTotalDisc)
      if (v.subtotal>0) {
        tempTotalOrder +=v.subtotal
        tempTotalDisc +=v.subtotal
      }
      console.log("")
      console.log("after tempTotalOrder+",tempTotalOrder)
      console.log("after tempTotalDisc-+",tempTotalDisc)
      this.CalcRespData.total_order = tempTotalOrder
      this.CalcRespData.total_discount = tempTotalDisc

      console.log("tempSelectFgChoice",this.tempSelectFgChoice)
      console.log("this.table.free_goods[i].fg_component.value",v)
    },
    handleCalculate(){
      console.log(this.table.data);
      let check_res = this.checkFormComplete();

      // console.log(check_res);

      let is_complete = check_res.is_complete;
      let msg_incomplete = check_res.msg_incomplete;


      // Jika CO Canvas, maka dicek juga untuk stock yang dibawa oleh sales, apakah jumlahnya cukup?
      if(this.soType != null && this.soType.Code == "ZDCV"){
        for (let i = 0; i < this.table.data.length; i++) {
          let _items = this.table.data[i].formInputs[0].value;
          let items_qty_hu = this.table.data[i].items_qty_hu;
          for (let j = 0; j < this.table.data[i].hu_labels.length; j++) {
            // if( parseInt(qty_per_HU[j]) > 0 ){
            let stokSkuSales = this.table.data[i].hu_labels[j].stock;
            if( parseInt(items_qty_hu[j]) > stokSkuSales ){
              is_complete = false;
              msg_incomplete.push("Insufficent stock for item " + _items.sku_name + "["+this.table.data[i].hu_labels[j].unit+"] Stock remaining : " + stokSkuSales)
            }
          }
        }
      }

      // for (let i = 0; i < this.table.data[i].hu_labels.length; j++) {
      //   // if( parseInt(qty_per_HU[j]) > 0 ){
      //   let stokSkuSales = this.table.data[i].hu_labels[j].stock;
      //   if( parseInt(items_qty_hu[j]) > stokSkuSales ){
      //     is_complete = false;
      //     msg_incomplete.push("Insufficent stock for item " + _items.sku_name + "["+this.table.data[i].hu_labels[j].unit+"] Stock remaining : " + stokSkuSales)
      //   }
      // }

      // Batas pengecekan Canvas

      // check if jumlah UOM tiap item > 0 | if 0 maka return tidak bisa calculate
      for (let i = 0; i < this.table.data.length; i++) {
        let itmCheck = this.table.data[i];

        if (parseInt(itmCheck.items_qty) == 0 ) {
          is_complete = false;
          console.log("itmCheck.items_qty, ",itmCheck.items_qty)
          msg_incomplete.push("Please add qty order for item [" + itmCheck.formInputs[0].value.code + "]" + itmCheck.formInputs[0].value.sku_name);
        }
      }

      if(!is_complete){
        for (let i = check_res.msg_incomplete.length-1; i > -1; i--) {
          this.$vs.notify({
            title: "Warning",
            text: check_res.msg_incomplete[i],
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }
        // msg_incomplete.forEach(msg => {
        //   this.$vs.notify({
        //     title: "Warning",
        //     text: msg,
        //     color: "danger",
        //     position: "top-right",
        //     iconPack: "feather",
        //     icon: "icon-alert-circle"
        //   });
        // });
        this.calculated = false;
        return;
      }

      let params = {
        sales_channel_code: this.salesChannel.selected.code,
        territory_code: this.selectedTerritory.code,
        company_code: this.$store.state.user.currentUser.company_code,
        company_id: this.$store.state.user.currentUser.company_id,
        customer_code: this.isOneTime ? this.oneTimeCust.customer.code : this.selectedCustomer.code,
        pricing_group_id: this.pricingGroup.selected == null ? 0 : this.pricingGroup.selected.ID,
        ship_to_id: this.selectedShippTo.ID,
        bill_to_id: this.selectedBillTo.ID,
        date: this.dateCo ? moment(String(this.dateCo)).format("YYYY-MM-DD") : null,
        promo_code: [],
        tax_rate: 0,
        subtotal_charge: 0,
        items:[],
        so_type: this.soType.Code
      };

      for (let i = 0; i < this.table.data.length; i++) {
        console.log(this.table.data[i])
        let _items = this.table.data[i].formInputs[0].value;
        let items_qty_hu = this.table.data[i].items_qty_hu;
        // let qty_per_HU = this.table.data[i].items_hu.split('.');
        for (let j = 0; j < this.table.data[i].hu_labels.length; j++) {
          // if( parseInt(qty_per_HU[j]) > 0 ){
            let is_combo = false
            is_combo = this.table.data[i].hu_labels[j].is_combo
            console.log("is_combo",is_combo)
          if (is_combo) {
            let _combo_items = []
            _combo_items = this.table.data[i].data_combo
            if (_combo_items==undefined||(_combo_items!=undefined&&_combo_items.length==0)) {
                this.$vs.notify({
                  title: "The child combo has not been set",
                  text: "please set the child combo first",
                  color: "danger",
                  position: "top-right"
                });
                return
              }
            _combo_items.forEach((el)=>{
              let temp_item = {
                item_code : el.ItemCode,
                combo_code : el.Code,
                item_name : el.ItemName,
                item_uom : el.UnitName,
                item_quantity_request : 0,
                item_quantity_adjusted : parseInt(el.total_qty),
                item_price : 0,
                is_combo: true,
                total_qty:parseInt(this.table.data[i].items_qty?this.table.data[i].items_qty:0),
                rounding : 0,
              }
              params.items.push(temp_item)
            })

          }else if( parseInt(items_qty_hu[j]) > 0 ){
            let temp_item = {
              item_code : _items.code,
              combo_code : null,
              item_name : _items.sku_name,
              item_uom : this.table.data[i].hu_labels[j].unit,
              item_quantity_request : 0,
              item_quantity_adjusted : parseInt(items_qty_hu[j]),
              total_qty:parseInt(this.table.data[i].items_qty?this.table.data[i].items_qty:0),
              item_price : 0,
              is_combo:false,
              rounding : this.table.data[i].list_price.length == 0 ? 0 : parseFloat(this.table.data[i].list_price[j].rounding),
            }
            params.items.push(temp_item)
          }
        }
      }

      // console.log('NEW params',params)

      this.$vs.loading();
      this.$http
      .post("/api/oms/v3/sfa-sales-order/sales-order/calculate", params)
      .then(resp => {

        let tempTotalOrder = resp.data.total_order
        let tempTotalDisc = resp.data.total_discount

        const disc_type_choice = 9
        const disc_type_lower_price = 5
        // // console.log(resp);
        let allow_partial = true // tiket MIX2-0912 default allow partial yes
        this.table.free_goods = []
        let total_net_value = 0, total_weight = 0, total_volume = 0;
        if (resp.code == 200) {

          var calc_resp = resp.data;
          var is_available_price = true;
          for (var i = 0; i < calc_resp.pricing.length; i++) {

            if(calc_resp.pricing[i].sub_total <= 0 && calc_resp.pricing[i].sub_total_engine <= 0) {
              this.$vs.loading.close();
            }

            if(calc_resp.pricing[i].error_message != "") {
              this.$vs.notify({
                title: "Error",
                text: calc_resp.pricing[i].error_message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle"
              });
              is_available_price = false;
            }
          }

          // if (is_available_price) {
            // Loops price/pricing per SO Line, per HU
            for (let i = 0; i < this.table.data.length; i++) {
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].tax = [];
              this.table.data[i].items_qty_hu = [];
              this.table.data[i].qty_hu = [];
              this.table.data[i].subtotal.price = [];
              this.table.data[i].subtotal.tax = [];
              this.table.data[i].subtotal.tax_rate = [];
              this.table.data[i].subtotal.disc = [];
              this.table.data[i].subtotal.gross_up = [];
              this.table.data[i].grand_total = [];
              this.table.data[i].rowSpanPlus = 1;

              let sku = this.table.data[i];
              let warn_item = this.table.data[i].formInputs[0].value
              let sku_item_unit_ids = []
              let calc_item_unit_ids = []
              let proc_item_unit_ids = []

              this.table.data[i].hu_labels.forEach(v => {
                if (!v.is_combo) {
                  console.log("sku_item_unit_ids is combo",v.is_combo);
                  sku_item_unit_ids.push( parseInt(v.item_unit_id));
                }
              });

              calc_resp.pricing.forEach(calc => {
                if (!calc.is_combo) {
                  console.log("calc_item_unit_ids is combo",calc.is_combo);
                  calc_item_unit_ids.push( parseInt(calc.item_unit_id));
                }
              });

              // console.log("sku_item_unit_ids", sku_item_unit_ids)
              // console.log("calc_item_unit_ids", calc_item_unit_ids)

              let all_tax = 0, grand_total = 0, st_price = 0, st_disc = 0, st_gross_up = 0, all_gross_up = 0, temp_listprice = null;
              let is_head = false, ex_head = false, is_head2 = false, ex_head2 = false
              this.table.data[i].hu_labels.forEach((val,j) => {
                if (val.is_combo) {
                  this.table.data[i].items_qty_hu.push( this.table.data[i].items_qty )
                }
                calc_resp.pricing.forEach((calc,i_calc)=>{
                  console.log(calc.allow_partial_fulfill)
                  // Cek allow partial di level promo
                  // prioritas allow_partial = no
                  // if (!calc.allow_partial_fulfill) {
                  //     allow_partial = false
                  // }

                  let combo_items = []
                  combo_items = this.table.data[i].data_combo
                  if (calc.is_combo) {
                    if (combo_items&&combo_items.length>0) {
                      this.table.data[i].rowSpanPlus = combo_items.length - 1
                    }
                    combo_items.forEach((elm)=>{
                      if (elm.ItemCode==calc.code && calc.unit == elm.UnitName && elm.ID == calc.combo_id) {
                        console.log(elm.ItemCode,"==",calc.code);
                        if(calc.unit == elm.UnitName){
                          console.log(calc.unit,"==",elm.UnitName);
                            // calc_item_unit_ids.push( parseInt(calc.item_unit_id));
                            if (!ex_head){
                              ex_head = true
                              is_head = true
                            }
                            temp_listprice = {
                              is_head :is_head,
                              price: calc.price ? calc.price.toFixed(2) : 0 ,
                              item_name: calc.name,
                              item_code: calc.code,
                              unit: calc.unit,
                              rounding: calc.rounding,
                              gross_up: calc.gross_up,
                              cogs:calc.cogs,
                              total_qty:calc.total_qty?calc.total_qty:0,
                              height:calc.height,
                              width:calc.width,
                              length:calc.length,
                              volume:calc.volume,
                              weight:calc.weight,
                              price_rule_code:calc.price_rule_code,
                              price_rule_id:calc.price_rule_id,
                              price_rule_valid_to:moment(calc.price_rule_valid_to).format("YYYY-MM-DD"),
                              price_rule_valid_from:moment(calc.price_rule_valid_from).format("YYYY-MM-DD"),
                              division_code:calc.division_code,
                              division_description:calc.division_description,
                              net_weight:calc.net_weight,
                              total_height:calc.total_height,
                              total_weight:calc.total_weight,
                              total_width:calc.total_width,
                              total_volume:calc.total_volume,
                              total_length:calc.total_length,
                              is_expand:false,
                              total_net_weight:calc.total_net_weight,
                              promo_calculation:calc.promo_calculation,
                              sub_total:calc.sub_total,
                              discount:calc.discount,
                              dpp:calc.dpp,
                              tax:calc.tax,
                              grand_total:calc.grand_total,
                            }
                            is_head = false

                            total_net_value += calc.total_net_weight;
                            total_weight += calc.total_weight;
                            total_volume += calc.total_volume;

                            console.log("temp_listprice=>",temp_listprice);

                            this.table.data[i].list_price.push( temp_listprice );
                            this.table.data[i].reg_disc.push( calc.discountable.toFixed(3) );
                            this.table.data[i].qty_hu.push( calc.qty );

                            st_price += calc.sub_total;
                            st_gross_up += calc.gross_up;

                            all_tax += calc.tax;
                            all_gross_up += calc.gross_up;
                            this.table.data[i].subtotal.tax.push( calc.tax.toFixed(2) );
                            this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );

                            this.table.data[i].subtotal.price.push( calc.sub_total.toFixed(2) );

                          // Sudah ga ada sub_total_discountable
                            st_disc = calc.discount?calc.discount:0;
                            this.table.data[i].subtotal.disc.push( st_disc.toFixed(3) ); // ini subtotal discount (disc * qty)
                            grand_total = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total - st_disc : calc.grand_total;
                            this.table.data[i].grand_total.push( (grand_total?grand_total:0).toFixed(2) );
                            // this.table.data[i].grand_total.push( (grand_total).toFixed(2) );

                            // if (calc.tax > 0) {
                            //   resp.data.total_tax += calc.tax;
                            //   resp.data.total_discount += calc.tax;
                            // }

                            //fg for combo
                            let tempDataFgOption = []
                            let tempKeyFgChoices=[]
                            let tempKeyFgChoice = {
                                promotion_item_req_id : 0,
                                promotion_id : 0
                              }
                            var temp_data_fg = this.getTempPromoFG()
                            let dataFgSisa = []
                            calc.promo_free_good.forEach((promo_fg)=>{
                              if (promo_fg.allow_partial_fulfill==false) {
                                allow_partial = false
                              }
                              // terbalik, jika promo allow partial false, maka SO allow partial juga no
                              // if (this.allowPartial == 1 && promo_fg.allow_partial_fulfill == false){
                              //   this.allowPartial = 0;
                              // }
                              let temp_fg_select = {...temp_data_fg.fg_component.value}
                              temp_fg_select = {}

                              if (parseInt(promo_fg.discount_type)==disc_type_choice||parseInt(promo_fg.discount_type)==disc_type_lower_price) {
                                let fg_filter =[]
                                fg_filter = this.table.free_goods.filter((el)=>{
                                  console.log("fg_filter=>",
                                    promo_fg.promotion_id ,"==", el.promotion_id,"&&",
                                    promo_fg.promotion_item_req_id ,"==", el.promotion_item_req_id,"&&",
                                    el.fg_component.i_parent ,"==", i_calc,"=>",
                                    promo_fg.promotion_id == el.promotion_id &&
                                    promo_fg.promotion_item_req_id == el.promotion_item_req_id &&
                                    el.fg_component.i_parent == i_calc)
                                  return promo_fg.promotion_id == el.promotion_id &&promo_fg.promotion_item_req_id == el.promotion_item_req_id && el.fg_component.i_parent == i_calc
                                })
                                if (fg_filter.length>0) {
                                  this.table.free_goods.forEach((elm,i_fg) => {
                                    console.log(i_fg+".","(",promo_fg.fg_item_code,")",promo_fg.promotion_item_req_id,"==", elm.promotion_item_req_id ,"=>" ,promo_fg.promotion_item_req_id == elm.promotion_item_req_id);
                                    console.log("fg_filter2=>",
                                    promo_fg.promotion_id ,"==", elm.promotion_id,"&&",
                                    promo_fg.promotion_item_req_id ,"==", elm.promotion_item_req_id,"&&",
                                    elm.fg_component.i_parent ,"==", i_calc,"=>",
                                    promo_fg.promotion_id == elm.promotion_id &&
                                    promo_fg.promotion_item_req_id == elm.promotion_item_req_id &&
                                    elm.fg_component.i_parent == i_calc)
                                    if (promo_fg.promotion_id == elm.promotion_id && promo_fg.promotion_item_req_id == elm.promotion_item_req_id&&elm.fg_component.i_parent==i_calc) {
                                      this.table.free_goods[i_fg].fg_component.optionValue.push({
                                        sku_code:promo_fg.fg_item_code,
                                        item_id:promo_fg.fg_item_id,
                                        item_name:promo_fg.fg_item_name,
                                        item_unit:promo_fg.fg_item_unit,
                                        item_unit_id:promo_fg.fg_item_unit_id,
                                        promotion_id :promo_fg.promotion_id,
                                        promotion_item_req_id :promo_fg.promotion_item_req_id,
                                        text:"["+promo_fg.sku_code+"] " + promo_fg.item_name + "("+promo_fg.fg_item_unit+")",
                                        qty:promo_fg.fg_qty,
                                        price:promo_fg.price,
                                        discount:promo_fg.qty*promo_fg.price,
                                        cap:promo_fg.cap,
                                        capped:promo_fg.capped,
                                        cogs:promo_fg.cogs,
                                      })
                                      this.table.free_goods[i_fg].fg_component.value=null
                                    }
                                  });

                                }else{
                                  if (tempKeyFgChoice.promotion_id != promo_fg.promotion_id || tempKeyFgChoice.promotion_item_req_id != promo_fg.promotion_item_req_id) {
                                    tempKeyFgChoice.promotion_id = promo_fg.promotion_id
                                    tempKeyFgChoice.promotion_item_req_id = promo_fg.promotion_item_req_id
                                    tempKeyFgChoices.push({
                                      allow_partial : promo_fg.allow_partial_fulfill ? 1 : 0,
                                      line : i,
                                      promotion_type : promo_fg.promotion_type,
                                      promotion_code : promo_fg.promotion_code,
                                      discount_type : promo_fg.discount_type,
                                      discount_string : promo_fg.discount_string,
                                      promotion_sequence : promo_fg.promotion_sequence,
                                      promotion_step_id : promo_fg.promotion_step_id,
                                      qty : promo_fg.qty,
                                      to : promo_fg.to,
                                      from : promo_fg.from,
                                      base : promo_fg.base,
                                      base_amount : promo_fg.base_amount,
                                      base_string : promo_fg.base_string,
                                      promotion_item_req_id : promo_fg.promotion_item_req_id,
                                      promotion_id : promo_fg.promotion_id
                                    })
                                  }
                                  console.log("["+promo_fg.fg_item_code+"] " + promo_fg.fg_item_name + "("+promo_fg.fg_item_unit+")=>",promo_fg.promotion_id,",",promo_fg.promotion_item_req_id,"==>",tempKeyFgChoice.promotion_id != promo_fg.promotion_id || tempKeyFgChoice.promotion_item_req_id != promo_fg.promotion_item_req_id);
                                  temp_fg_select.sku_code=promo_fg.fg_item_code
                                  temp_fg_select.item_id=promo_fg.fg_item_id
                                  temp_fg_select.item_name=promo_fg.fg_item_name
                                  temp_fg_select.item_unit=promo_fg.fg_item_unit
                                  temp_fg_select.item_unit_id=promo_fg.fg_item_unit_id
                                  temp_fg_select.text="["+temp_fg_select.sku_code+"] " + temp_fg_select.item_name + "("+promo_fg.fg_item_unit+")"
                                  temp_fg_select.qty=promo_fg.fg_qty
                                  temp_fg_select.price=promo_fg.price
                                  temp_fg_select.discount=temp_fg_select.qty*temp_fg_select.price
                                  temp_fg_select.cap=promo_fg.cap
                                  temp_fg_select.capped=promo_fg.capped
                                  temp_fg_select.cogs=promo_fg.cogs
                                  temp_fg_select.promotion_id = promo_fg.promotion_id
                                  temp_fg_select.promotion_item_req_id = promo_fg.promotion_item_req_id

                                  temp_data_fg.allow_partial = promo_fg.allow_partial_fulfill ? 1 : 0
                                  temp_data_fg.discount_type = promo_fg.discount_type
                                  temp_data_fg.discount_string = promo_fg.discount_string
                                  temp_data_fg.promotion_type = promo_fg.promotion_type
                                  temp_data_fg.promotion_code = promo_fg.promotion_code
                                  temp_data_fg.promotion_id = promo_fg.promotion_id
                                  temp_data_fg.promotion_item_req_id = promo_fg.promotion_item_req_id
                                  temp_data_fg.promotion_sequence = promo_fg.promotion_sequence
                                  temp_data_fg.promotion_step_id = promo_fg.promotion_step_id
                                  temp_data_fg.line = i
                                  temp_data_fg.qty = promo_fg.qty
                                  temp_data_fg.to = promo_fg.to
                                  temp_data_fg.from = promo_fg.from
                                  temp_data_fg.base = promo_fg.base
                                  temp_data_fg.base_amount = promo_fg.base_amount
                                  temp_data_fg.base_string = promo_fg.base_string
                                  let cloneDtSelect ={...temp_fg_select}
                                  tempDataFgOption.push(cloneDtSelect)
                                  temp_data_fg.fg_component.value = null
                                  temp_data_fg.fg_component.disabled = false
                                  temp_data_fg.fg_component.i_parent = i_calc
                                }
                              }else{
                                let dataFgFinal ={}
                                dataFgFinal.discount_type = promo_fg.discount_type
                                dataFgFinal.discount_string = promo_fg.discount_string
                                dataFgFinal.promotion_type = promo_fg.promotion_type
                                dataFgFinal.promotion_code = promo_fg.promotion_code
                                dataFgFinal.promotion_id = promo_fg.promotion_id
                                dataFgFinal.promotion_item_req_id = promo_fg.promotion_item_req_id
                                dataFgFinal.promotion_sequence = promo_fg.promotion_sequence
                                dataFgFinal.promotion_step_id = promo_fg.promotion_step_id
                                dataFgFinal.line = i
                                dataFgFinal.qty = promo_fg.qty
                                dataFgFinal.to = promo_fg.to
                                dataFgFinal.from = promo_fg.from
                                dataFgFinal.base = promo_fg.base
                                dataFgFinal.base_amount = promo_fg.base_amount
                                dataFgFinal.base_string = promo_fg.base_string
                                // let cloneDtSelect ={...temp_fg_select}
                                // tempDataFgOption.push(cloneDtSelect)
                                dataFgFinal.fg_component = {
                                    value : {
                                      sku_code :promo_fg.fg_item_code,
                                      item_id :promo_fg.fg_item_id,
                                      item_name :promo_fg.fg_item_name,
                                      item_unit :promo_fg.fg_item_unit,
                                      item_unit_id :promo_fg.fg_item_unit_id,
                                      text :"["+promo_fg.fg_item_code+"] "+ promo_fg.fg_item_name,
                                      qty :promo_fg.fg_qty,
                                      price :promo_fg.price,
                                      discount :promo_fg.fg_qty*promo_fg.price,
                                      cap :promo_fg.cap,
                                      capped :promo_fg.capped,
                                      cogs :promo_fg.cogs,
                                      promotion_id :promo_fg.promotion_id,
                                      promotion_item_req_id :promo_fg.promotion_item_req_id
                                    },
                                    disabled : true,
                                    i_parent : i_calc
                                  }
                                // const temp2DataFg ={...temp_data_fg}
                                dataFgSisa.push(dataFgFinal)
                              }
                              console.log("temp_data_fg.line=>",temp_data_fg.line,", i=>",i)
                            })
                            // <<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>
                            console.log("calc.promo_free_good&&calc.promo_free_good.length>0=>",calc.promo_free_good&&calc.promo_free_good.length>0)
                            if (calc.promo_free_good&&calc.promo_free_good.length>0) { //&&is_push
                              console.log("tempKeyFgChoices.length=>",tempKeyFgChoices.length)
                              if (tempKeyFgChoices.length>0) {
                                tempKeyFgChoices.forEach((el)=>{
                                  temp_data_fg.fg_component.optionValue = tempDataFgOption.filter((fg)=>{
                                    return fg.promotion_id==el.promotion_id && fg.promotion_item_req_id == el.promotion_item_req_id
                                  })
                                  var tempSelectFgChoice = this.tempSelectFgChoice.filter((fgC)=>{
                                    console.log("fgC.promotion_id == el.promotion_id=>",fgC.promotion_id == el.promotion_id)
                                    console.log(fgC.promotion_id ,"==", el.promotion_id)
                                    return fgC.promotion_id == el.promotion_id
                                  })
                                  var defaultSelectFgChoice ={}
                                  console.log("tempSelectFgChoice.length",tempSelectFgChoice.length)
                                  if (tempSelectFgChoice.length>0) {
                                    tempSelectFgChoice = tempSelectFgChoice[0]
                                    if (tempSelectFgChoice.subtotal>0) {
                                    tempTotalOrder +=tempSelectFgChoice.subtotal
                                    tempTotalDisc +=tempSelectFgChoice.subtotal
                                  }
                                  }else{
                                    tempSelectFgChoice = {}
                                  }

                                  console.log("el.promotion_id",el.promotion_id)
                                  console.log("tempSelectFgChoice",tempSelectFgChoice)
                                  console.log("temp_data_fg.fg_component.optionValue",temp_data_fg.fg_component.optionValue)
                                  var tempOptionValueFgChoice =temp_data_fg.fg_component.optionValue.filter((fg)=>{
                                    console.log("get default select fg choice=>",fg.promotion_id==tempSelectFgChoice.promotion_id && fg.item_unit_id == tempSelectFgChoice.item_unit_id)
                                    return fg.promotion_id==tempSelectFgChoice.promotion_id && fg.item_unit_id == tempSelectFgChoice.item_unit_id
                                  })
                                  if (tempOptionValueFgChoice.length>0){
                                    defaultSelectFgChoice = tempOptionValueFgChoice[0]
                                  }
                                  this.table.free_goods.push({
                                    allow_partial         :el.allow_partial,
                                    discount_type         :el.discount_type,
                                    discount_string       :el.discount_string,
                                    promotion_type        :el.promotion_type,
                                    promotion_code        :el.promotion_code,
                                    promotion_id          :el.promotion_id,
                                    promotion_item_req_id :el.promotion_item_req_id,
                                    promotion_sequence    :el.promotion_sequence,
                                    promotion_step_id     :el.promotion_step_id,
                                    line                  :el.line,
                                    qty                   :el.qty,
                                    to                    :el.to,
                                    from                  :el.from,
                                    base                  :el.base,
                                    base_amount           :el.base_amount,
                                    base_string           :el.base_string,
                                    fg_component          :{
                                      disabled        :temp_data_fg.fg_component.disabled,
                                      validate        :temp_data_fg.fg_component.validate,
                                      name            :temp_data_fg.fg_component.name,
                                      placeholder     :temp_data_fg.fg_component.placeholder,
                                      head            :"free_item",
                                      type            :temp_data_fg.fg_component.type,
                                      track_by        :temp_data_fg.fg_component.track_by,
                                      isLoading       :temp_data_fg.fg_component.isLoading,
                                      multiple        :temp_data_fg.fg_component.multiple,
                                      allowempty      :temp_data_fg.fg_component.allowempty,
                                      value           :defaultSelectFgChoice,
                                      optionValue     :temp_data_fg.fg_component.optionValue,
                                      optionOriginal     :temp_data_fg.fg_component.optionValue,
                                      internal_search :false,
                                    },
                                  })
                                  console.log("checkpoint 3",this.table.free_goods)
                                })
                              }else{
                              console.log("dataFgSisa=>",dataFgSisa)
                                dataFgSisa.forEach(el =>{
                                  this.table.free_goods.push({
                                      discount_type         :el.discount_type,
                                      discount_string       :el.discount_string,
                                      promotion_type        :el.promotion_type,
                                      promotion_code        :el.promotion_code,
                                      promotion_id          :el.promotion_id,
                                      promotion_item_req_id :el.promotion_item_req_id,
                                      promotion_sequence    :el.promotion_sequence,
                                      promotion_step_id     :el.promotion_step_id,
                                      line                  :el.line,
                                      qty                   :el.qty,
                                      to                    :el.to,
                                      from                  :el.from,
                                      base                  :el.base,
                                      base_amount           :el.base_amount,
                                      base_string           :el.base_string,
                                      fg_component          :{
                                        disabled        :el.fg_component.disabled,
                                        validate        : "required",
                                        name            : "fg_sku",
                                        placeholder     : "Sku Code",
                                        head            :"free_item",
                                        type            : "multiselect",
                                        track_by        :"item_unit_id",
                                        isLoading       :false,
                                        multiple        : false,
                                        allowempty      : false,
                                        value           :el.fg_component.value,
                                        optionValue     :[],
                                        optionOriginal  :[],
                                        internal_search :false,
                                      },
                                    })
                                })
                              }
                            }
                            // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>
                          }
                      }
                    })
                  }
                  if (!val.is_combo) {
                    console.log("val.sku_code <==>",val.sku_code);
                    // calc_resp.pricing.forEach((calc)=>{
                      if(val.sku_code == calc.code&&!calc.is_combo){
                        if(calc.unit == this.table.data[i].hu_labels[j].unit && calc_item_unit_ids.includes( parseInt(this.table.data[i].hu_labels[j].item_unit_id))){
                          // calc_item_unit_ids.push( parseInt(calc.item_unit_id));
                          if (calc.promo_calculation) {
                            calc.promo_calculation.forEach((promo)=>{
                              //prioritas allow_partial = no
                              if (promo.allow_partial_fulfill == false) {
                                allow_partial = false
                              }
                              // if (this.allowPartial == 1 && promo.allow_partial_fulfill == false){
                              //   this.allowPartial = 0;
                              // }
                            })
                          }
                          if (!ex_head2) {
                            ex_head2 = true
                            is_head2 = true
                          }

                            if (this.soType != null && this.soType.Code == "ZDSM") {
                              calc.sub_total = ((calc.price>0&&calc.qty>0) ? calc.price * calc.qty: 0)
                            }
                          temp_listprice = {
                            is_head:is_head2,
                            price: calc.price ? calc.price.toFixed(2) : 0 ,
                            item_name: calc.name,
                            item_code: calc.code,
                            unit: this.table.data[i].hu_labels[j].unit,
                            rounding: calc.rounding,
                            gross_up: calc.gross_up,
                            cogs:calc.cogs,
                            total_qty:calc.total_qty?calc.total_qty:0,
                            height:calc.height,
                            width:calc.width,
                            length:calc.length,
                            volume:calc.volume,
                            weight:calc.weight,
                            price_rule_code:calc.price_rule_code,
                            price_rule_id:calc.price_rule_id,
                            price_rule_valid_to:moment(calc.price_rule_valid_to).format("YYYY-MM-DD"),
                            price_rule_valid_from:moment(calc.price_rule_valid_from).format("YYYY-MM-DD"),
                            division_code:calc.division_code,
                            division_description:calc.division_description,
                            net_weight:calc.net_weight,
                            total_height:calc.total_height,
                            total_weight:calc.total_weight,
                            total_width:calc.total_width,
                            total_volume:calc.total_volume,
                            total_length:calc.total_length,
                            is_expand:false,
                            total_net_weight:calc.total_net_weight,
                            promo_calculation:calc.promo_calculation,
                            sub_total:calc.sub_total,
                            discount:calc.discount,
                            dpp:calc.dpp,
                            tax:calc.tax,
                            grand_total:calc.grand_total,
                          }
                          is_head2 = false
                          total_net_value += calc.total_net_weight;
                          total_weight += calc.total_weight;
                          total_volume += calc.total_volume;
                          // const disc_type_choice = 9
                          // const disc_type_lower_price = 5
                          var temp_data_fg = this.getTempPromoFG()
                          console.log("temp_data_fg==>>",temp_data_fg)
                          let tempDataFgOption = []
                          let dataFgSisa = []
                          let is_push = false
                          let tempKeyFgChoices=[]
                          let tempKeyFgChoice = {
                              promotion_item_req_id : 0,
                              promotion_id : 0
                            }
                          calc.promo_free_good.forEach((promo_fg)=>{
                            if (promo_fg.allow_partial_fulfill==false) {
                              allow_partial = false
                            }
                            // terbalik, jika promo allow partial false, maka SO allow partial juga no
                            // if (this.allowPartial == 1 && promo_fg.allow_partial_fulfill == false){
                            //   this.allowPartial = 0;
                            // }
                            let temp_fg_select = {...temp_data_fg.fg_component.value}
                            console.log("temp_fg_select==>>",temp_fg_select)
                            temp_fg_select = {}

                            if (parseInt(promo_fg.discount_type)==disc_type_choice||parseInt(promo_fg.discount_type)==disc_type_lower_price) {
                              let fg_filter =[]
                              fg_filter = this.table.free_goods.filter((el)=>{
                                console.log("fg_filter=>",
                                  promo_fg.promotion_id ,"==", el.promotion_id,"&&",
                                  promo_fg.promotion_item_req_id ,"==", el.promotion_item_req_id,"&&",
                                  el.fg_component.i_parent ,"==", i_calc,"=>",
                                  promo_fg.promotion_id == el.promotion_id &&
                                  promo_fg.promotion_item_req_id == el.promotion_item_req_id &&
                                  el.fg_component.i_parent == i_calc)
                                return promo_fg.promotion_id == el.promotion_id &&promo_fg.promotion_item_req_id == el.promotion_item_req_id && el.fg_component.i_parent == i_calc
                              })
                              if (fg_filter.length>0) {
                                this.table.free_goods.forEach((elm,i_fg) => {
                                  console.log(i_fg+".","(",promo_fg.fg_item_code,")",promo_fg.promotion_item_req_id,"==", elm.promotion_item_req_id ,"=>" ,promo_fg.promotion_item_req_id == elm.promotion_item_req_id);
                                  console.log("fg_filter2=>",
                                  promo_fg.promotion_id ,"==", elm.promotion_id,"&&",
                                  promo_fg.promotion_item_req_id ,"==", elm.promotion_item_req_id,"&&",
                                  elm.fg_component.i_parent ,"==", i_calc,"=>",
                                  promo_fg.promotion_id == elm.promotion_id &&
                                  promo_fg.promotion_item_req_id == elm.promotion_item_req_id &&
                                  elm.fg_component.i_parent == i_calc)
                                  if (promo_fg.promotion_id == elm.promotion_id && promo_fg.promotion_item_req_id == elm.promotion_item_req_id&&elm.fg_component.i_parent==i_calc) {
                                    this.table.free_goods[i_fg].fg_component.optionValue.push({
                                      sku_code:promo_fg.fg_item_code,
                                      item_id:promo_fg.fg_item_id,
                                      item_name:promo_fg.fg_item_name,
                                      item_unit:promo_fg.fg_item_unit,
                                      item_unit_id:promo_fg.fg_item_unit_id,
                                      promotion_id :promo_fg.promotion_id,
                                      promotion_item_req_id :promo_fg.promotion_item_req_id,
                                      text:"["+promo_fg.sku_code+"] " + promo_fg.item_name + "("+promo_fg.fg_item_unit+")",
                                      qty:promo_fg.fg_qty,
                                      price:promo_fg.price,
                                      discount:promo_fg.qty*promo_fg.price,
                                      cap:promo_fg.cap,
                                      capped:promo_fg.capped,
                                      cogs:promo_fg.cogs,
                                    })
                                    this.table.free_goods[i_fg].fg_component.value=null
                                  }
                                });
                                is_push = false

                              }else{
                                if (tempKeyFgChoice.promotion_id != promo_fg.promotion_id || tempKeyFgChoice.promotion_item_req_id != promo_fg.promotion_item_req_id) {
                                  tempKeyFgChoice.promotion_id = promo_fg.promotion_id
                                  tempKeyFgChoice.promotion_item_req_id = promo_fg.promotion_item_req_id
                                  tempKeyFgChoices.push({
                                    allow_partial : promo_fg.allow_partial_fulfill ? 1 : 0,
                                    line : i,
                                    promotion_type : promo_fg.promotion_type,
                                    promotion_code : promo_fg.promotion_code,
                                    discount_type : promo_fg.discount_type,
                                    discount_string : promo_fg.discount_string,
                                    promotion_sequence : promo_fg.promotion_sequence,
                                    promotion_step_id : promo_fg.promotion_step_id,
                                    qty : promo_fg.qty,
                                    to : promo_fg.to,
                                    from : promo_fg.from,
                                    base : promo_fg.base,
                                    base_amount : promo_fg.base_amount,
                                    base_string : promo_fg.base_string,
                                    promotion_item_req_id : promo_fg.promotion_item_req_id,
                                    promotion_id : promo_fg.promotion_id
                                  })
                                }
                                console.log("["+promo_fg.fg_item_code+"] " + promo_fg.fg_item_name + "("+promo_fg.fg_item_unit+")=>",promo_fg.promotion_id,",",promo_fg.promotion_item_req_id,"==>",tempKeyFgChoice.promotion_id != promo_fg.promotion_id || tempKeyFgChoice.promotion_item_req_id != promo_fg.promotion_item_req_id);
                                temp_fg_select.sku_code=promo_fg.fg_item_code
                                temp_fg_select.item_id=promo_fg.fg_item_id
                                temp_fg_select.item_name=promo_fg.fg_item_name
                                temp_fg_select.item_unit=promo_fg.fg_item_unit
                                temp_fg_select.item_unit_id=promo_fg.fg_item_unit_id
                                temp_fg_select.text="["+temp_fg_select.sku_code+"] " + temp_fg_select.item_name + "("+promo_fg.fg_item_unit+")"
                                temp_fg_select.qty=promo_fg.fg_qty
                                temp_fg_select.price=promo_fg.price
                                temp_fg_select.subtotal =promo_fg.fg_qty*promo_fg.price,
                                temp_fg_select.discount=temp_fg_select.qty*temp_fg_select.price
                                temp_fg_select.cap=promo_fg.cap
                                temp_fg_select.capped=promo_fg.capped
                                temp_fg_select.cogs=promo_fg.cogs
                                temp_fg_select.promotion_id = promo_fg.promotion_id
                                temp_fg_select.promotion_item_req_id = promo_fg.promotion_item_req_id

                                temp_data_fg.allow_partial = promo_fg.allow_partial_fulfill ? 1 : 0
                                temp_data_fg.discount_type = promo_fg.discount_type
                                temp_data_fg.discount_string = promo_fg.discount_string
                                temp_data_fg.promotion_type = promo_fg.promotion_type
                                temp_data_fg.promotion_code = promo_fg.promotion_code
                                temp_data_fg.promotion_id = promo_fg.promotion_id
                                temp_data_fg.promotion_item_req_id = promo_fg.promotion_item_req_id
                                temp_data_fg.promotion_sequence = promo_fg.promotion_sequence
                                temp_data_fg.promotion_step_id = promo_fg.promotion_step_id
                                temp_data_fg.line = i
                                temp_data_fg.qty = promo_fg.qty
                                temp_data_fg.to = promo_fg.to
                                temp_data_fg.from = promo_fg.from
                                temp_data_fg.base = promo_fg.base
                                temp_data_fg.base_amount = promo_fg.base_amount
                                temp_data_fg.base_string = promo_fg.base_string
                                let cloneDtSelect ={...temp_fg_select}
                                tempDataFgOption.push(cloneDtSelect)
                                temp_data_fg.fg_component.value = null
                                temp_data_fg.fg_component.disabled = false
                                temp_data_fg.fg_component.i_parent = i_calc
                                is_push = true
                              }
                            }else{
                              // temp_fg_select.sku_code=promo_fg.fg_item_code
                              // temp_fg_select.item_id=promo_fg.fg_item_id
                              // temp_fg_select.item_name=promo_fg.fg_item_name
                              // temp_fg_select.item_unit=promo_fg.fg_item_unit
                              // temp_fg_select.item_unit_id=promo_fg.fg_item_unit_id
                              // temp_fg_select.text="["+temp_fg_select.sku_code+"] "+ temp_fg_select.item_name
                              // temp_fg_select.qty=promo_fg.fg_qty
                              // temp_fg_select.price=promo_fg.price
                              // temp_fg_select.discount=temp_fg_select.qty*temp_fg_select.price
                              // temp_fg_select.cap=promo_fg.cap
                              // temp_fg_select.capped=promo_fg.capped
                              // temp_fg_select.cogs=promo_fg.cogs
                              // temp_fg_select.promotion_id = promo_fg.promotion_id
                              // temp_fg_select.promotion_item_req_id = promo_fg.promotion_item_req_id
                              let dataFgFinal ={}
                              dataFgFinal.discount_type = promo_fg.discount_type
                              dataFgFinal.discount_string = promo_fg.discount_string
                              dataFgFinal.promotion_type = promo_fg.promotion_type
                              dataFgFinal.promotion_code = promo_fg.promotion_code
                              dataFgFinal.promotion_id = promo_fg.promotion_id
                              dataFgFinal.promotion_item_req_id = promo_fg.promotion_item_req_id
                              dataFgFinal.promotion_sequence = promo_fg.promotion_sequence
                              dataFgFinal.promotion_step_id = promo_fg.promotion_step_id
                              dataFgFinal.line = i
                              dataFgFinal.qty = promo_fg.qty
                              dataFgFinal.to = promo_fg.to
                              dataFgFinal.from = promo_fg.from
                              dataFgFinal.base = promo_fg.base
                              dataFgFinal.base_amount = promo_fg.base_amount
                              dataFgFinal.base_string = promo_fg.base_string
                              // let cloneDtSelect ={...temp_fg_select}
                              // tempDataFgOption.push(cloneDtSelect)
                              dataFgFinal.fg_component = {
                                  value : {
                                    sku_code :promo_fg.fg_item_code,
                                    item_id :promo_fg.fg_item_id,
                                    item_name :promo_fg.fg_item_name,
                                    item_unit :promo_fg.fg_item_unit,
                                    item_unit_id :promo_fg.fg_item_unit_id,
                                    text :"["+promo_fg.fg_item_code+"] "+ promo_fg.fg_item_name,
                                    qty :promo_fg.fg_qty,
                                    price :promo_fg.price,
                                    subtotal :promo_fg.fg_qty*promo_fg.price,
                                    discount :promo_fg.fg_qty*promo_fg.price,
                                    cap :promo_fg.cap,
                                    capped :promo_fg.capped,
                                    cogs :promo_fg.cogs,
                                    promotion_id :promo_fg.promotion_id,
                                    promotion_item_req_id :promo_fg.promotion_item_req_id
                                  },
                                  disabled : true,
                                  i_parent : i_calc
                                }
                              // const temp2DataFg ={...temp_data_fg}
                              dataFgSisa.push(dataFgFinal)
                              is_push = true
                            }
                            console.log("temp_data_fg.line=>",temp_data_fg.line,", i=>",i)
                          })
                          console.log("calc.promo_free_good&&calc.promo_free_good.length>0=>",calc.promo_free_good&&calc.promo_free_good.length>0)
                          if (calc.promo_free_good&&calc.promo_free_good.length>0) { //&&is_push
                            console.log("tempKeyFgChoices.length=>",tempKeyFgChoices.length)
                            if (tempKeyFgChoices.length>0) {
                              tempKeyFgChoices.forEach((el)=>{
                                temp_data_fg.fg_component.optionValue = tempDataFgOption.filter((fg)=>{
                                  return fg.promotion_id==el.promotion_id && fg.promotion_item_req_id == el.promotion_item_req_id
                                })
                                var tempSelectFgChoice = this.tempSelectFgChoice.filter((fgC)=>{
                                  console.log("fgC.promotion_id == el.promotion_id=>",fgC.promotion_id == el.promotion_id)
                                  console.log(fgC.promotion_id ,"==", el.promotion_id)
                                  return fgC.promotion_id == el.promotion_id
                                })
                                var defaultSelectFgChoice ={}
                                console.log("tempSelectFgChoice.length",tempSelectFgChoice.length)
                                if (tempSelectFgChoice.length>0) {
                                  tempSelectFgChoice = tempSelectFgChoice[0]
                                  if (tempSelectFgChoice.subtotal>0) {
                                    tempTotalOrder +=tempSelectFgChoice.subtotal
                                    tempTotalDisc +=tempSelectFgChoice.subtotal
                                  }
                                }else{
                                  tempSelectFgChoice = {}
                                }

                                console.log("el.promotion_id",el.promotion_id)
                                console.log("tempSelectFgChoice",tempSelectFgChoice)
                                console.log("temp_data_fg.fg_component.optionValue",temp_data_fg.fg_component.optionValue)
                                var tempOptionValueFgChoice =temp_data_fg.fg_component.optionValue.filter((fg)=>{
                                  console.log("get default select fg choice=>",fg.promotion_id==tempSelectFgChoice.promotion_id && fg.item_unit_id == tempSelectFgChoice.item_unit_id)
                                  return fg.promotion_id==tempSelectFgChoice.promotion_id && fg.item_unit_id == tempSelectFgChoice.item_unit_id
                                })
                                if (tempOptionValueFgChoice.length>0){
                                  defaultSelectFgChoice = tempOptionValueFgChoice[0]
                                }
                                this.table.free_goods.push({
                                  allow_partial         :el.allow_partial,
                                  discount_type         :el.discount_type,
                                  discount_string       :el.discount_string,
                                  promotion_type        :el.promotion_type,
                                  promotion_code        :el.promotion_code,
                                  promotion_id          :el.promotion_id,
                                  promotion_item_req_id :el.promotion_item_req_id,
                                  promotion_sequence    :el.promotion_sequence,
                                  promotion_step_id     :el.promotion_step_id,
                                  line                  :el.line,
                                  qty                   :el.qty,
                                  to                    :el.to,
                                  from                  :el.from,
                                  base                  :el.base,
                                  base_amount           :el.base_amount,
                                  base_string           :el.base_string,
                                  fg_component          :{
                                    disabled        :temp_data_fg.fg_component.disabled,
                                    validate        :temp_data_fg.fg_component.validate,
                                    name            :temp_data_fg.fg_component.name,
                                    placeholder     :temp_data_fg.fg_component.placeholder,
                                    head            :"free_item",
                                    type            :temp_data_fg.fg_component.type,
                                    track_by        :temp_data_fg.fg_component.track_by,
                                    isLoading       :temp_data_fg.fg_component.isLoading,
                                    multiple        :temp_data_fg.fg_component.multiple,
                                    allowempty      :temp_data_fg.fg_component.allowempty,
                                    value           :defaultSelectFgChoice,
                                    optionValue     :temp_data_fg.fg_component.optionValue,
                                    optionOriginal     :temp_data_fg.fg_component.optionValue,
                                    internal_search :false,
                                  },
                                })
                                console.log("checkpoint 3",this.table.free_goods)
                              })
                            }else{
                            console.log("dataFgSisa=>",dataFgSisa)
                              dataFgSisa.forEach(el =>{
                                this.table.free_goods.push({
                                    discount_type         :el.discount_type,
                                    discount_string       :el.discount_string,
                                    promotion_type        :el.promotion_type,
                                    promotion_code        :el.promotion_code,
                                    promotion_id          :el.promotion_id,
                                    promotion_item_req_id :el.promotion_item_req_id,
                                    promotion_sequence    :el.promotion_sequence,
                                    promotion_step_id     :el.promotion_step_id,
                                    line                  :el.line,
                                    qty                   :el.qty,
                                    to                    :el.to,
                                    from                  :el.from,
                                    base                  :el.base,
                                    base_amount           :el.base_amount,
                                    base_string           :el.base_string,
                                    fg_component          :{
                                      disabled        :el.fg_component.disabled,
                                      validate        : "required",
                                      name            : "fg_sku",
                                      placeholder     : "Sku Code",
                                      head            :"free_item",
                                      type            : "multiselect",
                                      track_by        :"item_unit_id",
                                      isLoading       :false,
                                      multiple        : false,
                                      allowempty      : false,
                                      value           :el.fg_component.value,
                                      optionValue     :[],
                                      optionOriginal  :[],
                                      internal_search :false,
                                    },
                                  })
                                  if (el.fg_component.value.subtotal>0) {
                                    tempTotalOrder +=el.fg_component.value.subtotal
                                    tempTotalDisc +=el.fg_component.value.subtotal
                                  }
                              })
                            }
                             resp.data.total_order = tempTotalOrder
                            resp.data.total_discount = tempTotalDisc
                          }
                          this.table.data[i].list_price.push( temp_listprice );
                          this.table.data[i].reg_disc.push( calc.discountable.toFixed(2) );
                          this.table.data[i].qty_hu.push( calc.qty );
                          this.table.data[i].items_qty_hu.push( calc.qty );

                          st_price += calc.sub_total;
                          st_gross_up += calc.gross_up;

                          all_tax += calc.tax;
                          all_gross_up += calc.gross_up;
                          this.table.data[i].subtotal.tax.push( calc.tax.toFixed(2) );
                          this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );

                          this.table.data[i].subtotal.price.push( calc.sub_total.toFixed(2) );

                          // Sudah ga ada sub_total_discountable
                          st_disc = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total : (calc.discount?calc.discount:0);
                          this.table.data[i].subtotal.disc.push( st_disc.toFixed(3) ); // ini subtotal discount (disc * qty)
                          grand_total = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total - st_disc : calc.grand_total;
                          this.table.data[i].grand_total.push( (grand_total?grand_total:0).toFixed(2) );
                          // this.table.data[i].grand_total.push( (grand_total).toFixed(2) );

                          // if (calc.tax > 0) {
                          //   resp.data.total_tax += calc.tax;
                          //   resp.data.total_discount += calc.tax;
                          // }
                          proc_item_unit_ids.push(calc.item_unit_id);

                        }
                        if ( !calc_item_unit_ids.includes( parseInt(this.table.data[i].hu_labels[j].item_unit_id)) && !proc_item_unit_ids.includes( parseInt(this.table.data[i].hu_labels[j].item_unit_id)) ) {
                            temp_listprice = {
                              is_head:false,
                              price: (0).toFixed(2),
                              item_name: calc.name,
                              unit: this.table.data[i].hu_labels[j].unit,
                              rounding: 0,
                              gross_up: 0,
                            }

                            this.table.data[i].list_price.push( temp_listprice );
                            this.table.data[i].reg_disc.push( (0).toFixed(2) );
                            this.table.data[i].qty_hu.push( 0 );
                            this.table.data[i].items_qty_hu.push( 0 );

                            st_price += 0;
                            st_gross_up += 0;

                            all_tax += 0;
                            all_gross_up += 0;
                            this.table.data[i].subtotal.tax.push( (0).toFixed(2) );
                            this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );

                            this.table.data[i].subtotal.price.push( (0).toFixed(2) );

                            // push grandtotal
                            st_disc = 0;
                            this.table.data[i].subtotal.disc.push( st_disc.toFixed(3) ); // ini subtotal discount (disc * qty)
                            grand_total = 0;
                            this.table.data[i].grand_total.push( (grand_total?grand_total:0).toFixed(2) );
                            // this.table.data[i].grand_total.push( (grand_total).toFixed(2) );
                            proc_item_unit_ids.push( parseInt(this.table.data[i].hu_labels[j].item_unit_id));
                        }
                        console.log("this.table.free_goods",this.table.free_goods);
                      }
                    // })
                  }
                })
              });

              this.table.data[i].subtotal.gross_up.push(st_gross_up.toFixed(2))
              // console.log(st_price)

              if(st_price <= 0){
                  this.calculated = false;
                  // this.$vs.notify({
                  //   title: "Error",
                  //   text: "Please add qty or set price for item : " + warn_item.sku_name,
                  //   color: "danger",
                  //   position: "top-right",
                  //   iconPack: "feather",
                  //   icon: "icon-alert-circle"
                  // });
              }

              this.table.data[i].subtotal.all_price = st_price.toFixed(2);
              this.table.data[i].subtotal.all_tax = all_tax.toFixed(2);
              this.table.data[i].subtotal.all_gross_up = all_gross_up.toFixed(2);
              console.log("this.table.data[i]",this.table.data[i]);
            }

            if (is_available_price) {
              this.calculated = true;
            }

          this.$vs.loading.close();
          if (!allow_partial) {
              this.allowPartial = 0
          }
          // if(calc_resp.total_billing <= 0) {
            // alert("ada yang 0")
            // this.calculated = false;
            // this.$vs.loading.close();
          // }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to calculate Sales Order\n" + resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.calculated = false;
          this.$vs.loading.close();
        }
        if(this.calculated==true) {
          console.log(this.table.data)
          this.CalcRespData = resp.data;
          this.CalcRespData.total_net_value = total_net_value;
          this.CalcRespData.total_weight    = total_weight;
          this.CalcRespData.total_volume    = total_volume;

          console.log("this.CalcRespData", this.CalcRespData);
          if (this.CalcRespData.total_billing<this.moq.amount) {
            this.$vs.notify({
                title: "Warning",
                text: "total order is below the minimum order",
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle"
              });
          }else{
            if (resp.data.pricing&&resp.data.pricing.length==0) {
              this.calculated = false;
            }
            this.$vs.notify({
              title: "Success",
              text: resp.message,
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          }

        }
        console.log("this.table, ",this.table)
        if (this.table.free_goods.length>0) {
          this.setPromoDiscountTypeLowestPrice()
          this.existing_fg = true
        } else {
          this.existing_fg = false
        }
        this.$vs.loading.close();
        // this.checkMoq()
      });

    },
    setPromoDiscountTypeLowestPrice(){
      const disc_type_lower_price= 5
      let tempTotalOrder = this.CalcRespData.total_order
      let tempTotalDisc = this.CalcRespData.total_discount
      this.table.free_goods.forEach((elm,i_fg) => {
        if (parseInt(this.table.free_goods[i_fg].discount_type)==disc_type_lower_price) {
        let temp_selectFGLowestPrice ={}
        let temp_price_lower = 0
        this.table.free_goods[i_fg].fg_component.optionValue.forEach((el_FGLowestPrice)=>{
          console.log("lowest price=>",i_fg+".",el_FGLowestPrice.price,"<",temp_price_lower,"=",el_FGLowestPrice.price<temp_price_lower)
          if (temp_price_lower<=0) {
            temp_price_lower = el_FGLowestPrice.price
            temp_selectFGLowestPrice = el_FGLowestPrice
          }else if(el_FGLowestPrice.price<temp_price_lower){
            temp_price_lower = el_FGLowestPrice.price
            temp_selectFGLowestPrice = el_FGLowestPrice
          }
        })
        if (temp_selectFGLowestPrice.subtotal) {
          tempTotalOrder +=temp_selectFGLowestPrice.subtotal
          tempTotalDisc +=temp_selectFGLowestPrice.subtotal
        }
        this.table.free_goods[i_fg].fg_component.value = temp_selectFGLowestPrice
        this.table.free_goods[i_fg].fg_component.disabled = true
      }
      })
      this.CalcRespData.total_order = tempTotalOrder
      this.CalcRespData.total_discount = tempTotalDisc

      console.log("this.table.free_goods,",this.table.free_goods)
    },
    handleSubmit() {
      // bst_condition -> billto - shippto condiditon, harus sudah diisi / terpilih
      // ot_address_condition -> address Onetime customer harus sudah diisi / terpilih

      if (this.is_one_time) {
        if (this.oneTimeCust.npwp&&(this.oneTimeCust.npwp.length != 15 && this.oneTimeCust.npwp.length != 16)) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "NPWP must be 15 or 16 characters!",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
        if (!this.oneTimeCust.nik||(this.oneTimeCust.nik&&this.oneTimeCust.nik.length != 16)) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "required 16 characters for NIK!",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
      }
      console.log("this.salesPersonal", this.salesPersonal)
      let deposit_condition = {
        status: true,
        exceed: 0,
      };
      if (this.soType.Code == "ZDCBD" && this.selectedCustDeposit.length > 0) {
        deposit_condition = this.checkDeposit();
      }
      if (this.soType.Code == "ZDSM"&&this.expenseType.selected.id ==0) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Please select expense type!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return
      }
      // Customer Deposit saat CBD tidak mandatory | 2024/02/01 Fitur : SO Creation | PIC QA : Ilham, Latif
      // else if (this.soType.Code == "ZDCBD" && this.selectedCustDeposit.length == 0)
      // {
      //   this.$vs.notify({
      //     color: "warning",
      //     title: "Warning",
      //     text: "Please select deposit first!",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return;
      // }
      let bst_condition = (this.selectedBillTo !=undefined && this.selectedShippTo!=undefined);
      let ot_address_condition = (this.isOneTime && this.oneTimeCust.address_manual != null);

      if (this.deliveryType.selected == null) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Please select delivery type first!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      if (bst_condition || ot_address_condition) {
        bst_condition = (this.selectedBillTo.ID && this.selectedShippTo.ID);
        if (bst_condition || ot_address_condition) {
          if (deposit_condition.status) {
            this.$vs.dialog({
              type: "confirm",
              color: "danger",
              title: `Confirm`,
              text: "Please confirm to submit",
              accept: this.promiseSubmit,
            });
          } else {
            this.$vs.notify({
              color: "warning",
              title: "Warning",
              text: "Insufficent deposit ! Billing exceed : " + this.formatCurrency(deposit_condition.exceed.toFixed(2)),
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            return;
          }
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Bill to & Ship to is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
      }
      console.log(this.selectedBillTo)
      if (this.selectedBillTo || ot_address_condition) {
        if (this.selectedBillTo.ID == null || !ot_address_condition) {
          if (this.selectedBillTo.ID==null||this.selectedBillTo.ID==0) {
            this.isErrorBillTo =true
            this.msgErrorBillto ="bill to is required "+this.selectedBillTo.ID
          }
        }else{
          this.isErrorBillTo=false
          this.msgErrorBillto =""
        }
      }else{
          this.isErrorBillTo =true
          this.msgErrorBillto ="bill to is required "
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Bill to is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
      }

      if (this.selectedShippTo || ot_address_condition) {
        if (!this.selectedShippTo.ID || ot_address_condition) {
          this.isErrorShippTop =true
          this.msgErrorShippTo ="shipp to is required "+this.selectedShippTo.ID
        }else{
          this.isErrorShippTop=false
          this.msgErrorShippTo =""
        }
      }else{
        this.isErrorShippTop =true
        this.msgErrorShippTo ="shipp to is required "
        this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Shipp to is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
      }
    },
    promiseSubmit() {
      this.$vs.loading();
      if (this.isOneTime) {
        Promise.all([
          this.submitCustOnetime()
        ]).then((r) => {
          // console.log(r);
          if(r[0].resp.code == 200) {
            this.acceptSubmit();
          } else {
            this.$vs.loading.close();
          }
        });
      } else {
        this.acceptSubmit();
      }
    },
    acceptSubmit() {
      // this.$vs.loading();
      this.duedate = this.rDeliveryDate;
      let cout, sum_units, qty_per_HU, item_units, item = [], deposits = [], total_deposit = 0,combo_id,combo_name,combo_code,is_combo = false;

      // console.log(this.table.data)

      this.table.data.forEach((dt, dt_i)=>{
        let total_rounding = 0, total_gross_up = 0, total_disc = 0;
        sum_units = dt.hu_labels.length;
        qty_per_HU = dt.items_hu.split('.');
        item_units = [];
        combo_id = 0;
        combo_name = "";
        combo_code = ""

        // Loops by lsit_price, price > 0
        dt.list_price.forEach((lp, idx) => {
          cout = 0;
          for (let i = 0; i < sum_units; i++) {
            // if(lp.unit == dt.hu_labels[i].unit){
            if(lp.item_code==dt.hu_labels[i].sku_code&&lp.unit == dt.hu_labels[i].unit && qty_per_HU[cout] > 0&&!dt.hu_labels[i].is_combo){ // if qty <= 0 maka tidak dikirimkan ke BE
              is_combo = false
              item_units.push({
                // "discount" : (this.soType != null && this.soType.Code == "ZDSM") ? parseFloat(dt.subtotal.disc[idx]) : 0, // kalo SO type Sample / ZDSM maka discount = total belanjaan, agar nol
                "discount" : parseFloat(dt.subtotal.disc[idx]),
                "item_unit_id" : parseInt(dt.hu_labels[i].item_unit_id),
                "item_unit" : dt.hu_labels[i].unit,
                "price" : parseFloat(dt.subtotal.price[idx]),
                "tax" : parseFloat(dt.subtotal.tax[idx]),
                "tax_rate" : parseFloat(dt.subtotal.tax_rate[idx]),
                "quantity" : parseFloat(qty_per_HU[cout]),
                "total_qty" : parseFloat(lp.total_qty),
                "rounding" : parseFloat(lp.rounding),
                "gross_up" : parseFloat(lp.gross_up),
                "cogs" : lp.cogs?parseFloat(lp.cogs):0,
                "height":lp.height,
                "width":lp.width,
                "length":lp.length,
                "volume":lp.volume,
                "weight":lp.weight,
                "price_rule_code":lp.price_rule_code,
                "price_rule_id":lp.price_rule_id,
                "price_rule_valid_to":new Date(lp.price_rule_valid_to),
                "price_rule_valid_from":new Date(lp.price_rule_valid_from),
                "division_code":lp.division_code,
                "division_description":lp.division_description,
                "net_weight":lp.net_weight,
                "total_height":lp.total_height,
                "total_weight":lp.total_weight,
                "total_width":lp.total_width,
                "total_volume":lp.total_volume,
                "total_length":lp.total_length,
                "total_net_weight":lp.total_net_weight,
                "dpp": lp.dpp,
                "promo_calculation":lp.promo_calculation,
              });
              total_rounding += parseFloat(lp.rounding);
              total_gross_up += parseFloat(lp.gross_up);
            }else if (dt.hu_labels[i].is_combo) {
              is_combo = true
              if (dt.hu_labels[i].combo_items==undefined||(dt.hu_labels[i].combo_items!=undefined&&dt.hu_labels[i].combo_items.length==0)) {
                this.$vs.notify({
                  title: "The child combo has not been set",
                  text: "please set the child combo first",
                  color: "danger",
                  position: "top-right"
                });
                return
              }
              dt.hu_labels[i].combo_items.forEach((el_ci)=>{
                console.log("qty_per_HU combo=>",qty_per_HU);
                console.log("lp.unit combo=>",lp.unit);
                combo_id = el_ci.ID
                combo_name = el_ci.Name
                combo_code = el_ci.Code
                if (lp.item_code==el_ci.ItemCode&&lp.unit == el_ci.UnitName && qty_per_HU[cout] > 0) {//qty_per_HU=> qty per set combo
                  item_units.push({
                    // "discount" : (this.soType != null && this.soType.Code == "ZDSM") ? parseFloat(dt.subtotal.disc[idx]) : 0, // kalo SO type Sample / ZDSM maka discount = total belanjaan, agar nol
                    "discount" : parseFloat(dt.subtotal.disc[idx]),
                    "item_unit_id" : parseInt(el_ci.ItemUnitID),
                    "item_unit" : el_ci.UnitName,
                    "price" : parseFloat(dt.subtotal.price[idx]),
                    "tax" : parseFloat(dt.subtotal.tax[idx]),
                    "tax_rate" : parseFloat(dt.subtotal.tax_rate[idx]),
                    "quantity" : parseFloat(el_ci.total_qty),
                    "total_qty" : parseFloat(lp.total_qty),
                    "rounding" : parseFloat(lp.rounding),
                    "gross_up" : parseFloat(lp.gross_up),
                    "cogs" : lp.cogs?parseFloat(lp.cogs):0,
                    "height":lp.height,
                    "width":lp.width,
                    "length":lp.length,
                    "volume":lp.volume,
                    "weight":lp.weight,
                    "price_rule_code":lp.price_rule_code,
                    "price_rule_id":lp.price_rule_id,
                    "price_rule_valid_to":new Date(lp.price_rule_valid_to),
                    "price_rule_valid_from":new Date(lp.price_rule_valid_from),
                    "division_code":lp.division_code,
                    "division_description":lp.division_description,
                    "net_weight":lp.net_weight,
                    "total_height":lp.total_height,
                    "total_weight":lp.total_weight,
                    "total_width":lp.total_width,
                    "total_volume":lp.total_volume,
                    "total_length":lp.total_length,
                    "total_net_weight":lp.total_net_weight,
                    "dpp": lp.dpp,
                    "promo_calculation":lp.promo_calculation,
                  });
                  total_rounding += parseFloat(lp.rounding);
                  total_gross_up += parseFloat(lp.gross_up);
                }

              })
            }
            total_disc += parseFloat(dt.subtotal.disc[idx]);
            cout++;
          }
        });

        item.push({
          "charge" : 0,
          //"discount" : parseFloat(dt.subtotal.disc), //belum ada diskon, sementara nol aja dulu
          // "discount" : 0, //belum ada diskon, sementara nol aja dulu || kalo SO type Sample / ZDSM maka discount = total belanjaan, agar nol
          "discount" : total_disc, //belum ada diskon, sementara nol aja dulu || kalo SO type Sample / ZDSM maka discount = total belanjaan, agar nol
          "item_unit_id" : parseInt(dt.hu_labels[dt.hu_labels.length-1].item_unit_id),
          // "item_unit_id" : parseInt(dt.hu_labels[sum_units-1].item_unit_id),
          "cogs" : dt.list_price[sum_units-1]?parseFloat(dt.list_price[sum_units-1].cogs):0,
          "sku_code" : dt.formInputs[0].value.code,
          "sku_name" : dt.formInputs[0].value.sku_name,
          "item_units" : item_units,
          "price" : parseFloat(dt.subtotal.all_price), //need total price setelah kalkulasi all HU
          "quantity" : is_combo?0:parseInt(dt.items_qty),
          "tax" : parseFloat(dt.subtotal.all_tax),
          "total_rounding" : parseFloat(total_rounding),
          "total_gross_up" : parseFloat(total_gross_up),
          "combo_id":combo_id?parseInt(combo_id):0,
          "combo_code":combo_code,
          "combo_name":combo_name,
          "line":dt_i,
          "is_free_good":false,
          "quantity_combo":is_combo?parseInt(dt.items_qty):0,
          "allow_partial": this.getStatusAllowPartial(dt) ? 1 : 0,
        })
        let fg_filter = []
        fg_filter = this.table.free_goods.filter(el=>{
          return el.line == dt_i
        })
        if (fg_filter.length>0) {
          // check if item doesnt have price
          let priceless = []
          let isValidFreeGood = true
          for(let dt_promo of fg_filter) {
            if (dt_promo.fg_component.value==undefined||dt_promo.fg_component.value==null) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: "Please select item free_good",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              isValidFreeGood = false
              continue
            }
            if(parseFloat(dt_promo.fg_component.value.price) == 0) {
              isValidFreeGood = false
              priceless.push("DBP for SKU " + dt_promo.fg_component.value.sku_code + " not found")
              continue
            }
            item.push({
              "charge" : 0,
              "discount" : parseFloat(dt_promo.fg_component.value.discount),
              "item_unit_id" : parseFloat(dt_promo.fg_component.value.item_unit_id),
              "cogs" : dt_promo.fg_component.value.cogs,
              "sku_code" : dt_promo.fg_component.value.sku_code,
              "sku_name" : dt_promo.fg_component.value.item_name,
              "item_units" : [
                {
                  "promo_calculation" : [
                    {
                      "qty":dt_promo.qty,
                      "promotion_id":dt_promo.promotion_id,
                      "promotion_code":dt_promo.promotion_code,
                      "promotion_type":dt_promo.promotion_type,
                      "promotion_sequence":dt_promo.promotion_sequence,
                      "promotion_item_req_id":dt_promo.promotion_item_req_id,
                      "promotion_step_id":dt_promo.promotion_step_id,
                      "base":dt_promo.base,
                      "base_amount":dt_promo.base_amount,
                      "discount_type":dt_promo.discount_type,
                      "cap":dt_promo.fg_component.value.cap,
                      "capped":dt_promo.fg_component.value.capped,
                      "from":dt_promo.from,
                      "order_line":dt_promo.order_line,
                      "order_line_after_discount":dt_promo.order_line_after_discount,
                      "to":dt_promo.to,
                    }
                  ]
                }
              ],
              "price" :parseFloat(dt_promo.fg_component.value.price*dt_promo.fg_component.value.qty),
              "quantity" : parseFloat(dt_promo.fg_component.value.qty),
              "tax" : 0,
              "total_rounding" : 0,
              "total_gross_up" : 0,
              "combo_id":0,
              "combo_code":"",
              "combo_name":"",
              "line":dt_i,
              "is_free_good":true,
              "quantity_combo":0,
              "allow_partial": dt_promo.allow_partial,
            })
          }

          // notif alert jika ada FG yang tidak punya price
          if (priceless.length > 0) {
            let uniquePriceless = priceless.filter(function(item, pos) {
                return priceless.indexOf(item) == pos;
            })
            uniquePriceless.forEach((msg) => {
              this.$vs.notify({
                color: "warning",
                title: "Warning",
                text: msg,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
            })
          }

          if(!isValidFreeGood) {
            this.$vs.loading.close();
            return;
          }
        }
      })


      if (this.soType.Code == "ZDCBD" && this.selectedCustDeposit.length > 0) {
        this.selectedCustDeposit.forEach(cd => {
          deposits.push({
            deposit_id: cd.ID,
            deposit_code: cd.Code,
            value: cd.DepositValue,
          });
          total_deposit += cd.DepositValue;
        });

        if (parseInt(this.CalcRespData.total_billing) > parseInt(total_deposit)) {
          let exceed = parseInt(this.CalcRespData.total_billing) - parseInt(total_deposit);
          this.$vs.notify({
            color: "warning",
            title: "Warning",
            text: "Insufficent deposit ! Billing exceed : " + this.formatCurrency(exceed.toFixed(2)),
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.$vs.loading.close();
          return;
        }

      }
      // Customer Deposit saat CBD tidak mandatory | 2024/02/01 Fitur : SO Creation | PIC QA : Ilham, Latif
      // else if (this.soType.Code == "ZDCBD" && this.selectedCustDeposit.length == 0) {
      //   this.$vs.notify({
      //     color: "warning",
      //     title: "Warning",
      //     text: "Please select deposit first!",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   this.$vs.loading.close();
      //   return;
      // }

      // console.log("deposits", deposits)
      // console.log("total_deposit", deposits)
      let dataOneTimeCust = {};
      if (this.isOneTime) {
        dataOneTimeCust ={
          "domisili": this.oneTimeCust.domisili,
          "npwp_type": this.oneTimeCust.npwp_type,
          "npwp": this.oneTimeCust.npwp,
          "npwp_name": this.oneTimeCust.npwp_name,
          "sppkp": this.oneTimeCust.sppkp,
          "tax_address": this.oneTimeCust.tax_address,
          "onetime_customer_id": (this.oneTimeCust.id != null && this.isOneTime) ? this.oneTimeCust.id : 0,
          "customer_id": this.oneTimeCust.customer.ID,
        }
      }
      const params = {
        "customer_orders":[
            {
              // "type": this.soType.Code,
              "category": this.soType.Code,
              "note": this.note,
              "allow_partial": parseInt(this.allowPartial),
              "po_ref_code": this.poRefCode,
              "customer_category_id": this.customerCategory.selected.ID,
              "order_from": this.soType.Code != "ZDCV" ? "normal" : "canvas",
              "payment_term_id": this.paymentTerm.selected.id,
              "request_delivery_date" :this.rDeliveryDate?moment(String(this.rDeliveryDate)).format("YYYY-MM-DD"):null,
              // "sales_personal_id": this.salesPersonal.selected[0].ID,
              "sales_personal_id": this.salesPersonal.selected.ID,
              // "shipTo_id": this.isOneTime ? 0 : this.selectedShippTo.ID,
              "shipTo_id": this.selectedShippTo.ID, //onetime cust sudah diberi master address
              "source":"sfa-web",
              "status":0,
              "territory_id":this.selectedTerritory.id,
              // "billTo_id": this.isOneTime ? 0 : this.selectedBillTo.ID,
              "billTo_id": this.selectedBillTo.ID, //onetime cust sudah diberi master address
              "customer_id": this.isOneTime ? this.oneTimeCust.customer.ID : this.selectedCustomer.id,
              "date" :this.dateCo?moment(String(this.dateCo)).format("YYYY-MM-DD"):null,
              "due_date":this.duedate?moment(String(this.duedate)).format("YYYY-MM-DD"):null,
              "Items":item,
              "sales_channel_id": this.salesChannel.selected.ID,
              // "pricing_rule_id": 0, //From backend
              "delivery_type_id": this.deliveryType.selected.id,
              "pricing_group_id": this.pricingGroup.selected.ID,
              "zone_id": this.zone.selected.ID,
              "onetime_customer_id": (this.oneTimeCust.id != null && this.isOneTime) ? this.oneTimeCust.id : 0,
              "is_one_time": this.isOneTime,
              "onetime_customer_data":dataOneTimeCust,
              "all_rounding": this.CalcRespData?parseFloat(this.CalcRespData.total_rounding):0,
              "all_gross_up": this.CalcRespData?parseFloat(this.CalcRespData.total_gross_up):0,
              "deposits": deposits,
              "time":this.trxTimeUnix,
              "timestamp":new Date(),
              "is_pbf": this.isPBF==1?true:false,
              //Based on BRD, if PO date (ex_date) di UI kosong, maka didefault by CO Date - confirmed by Ilham
              // "ex_date": this.po_date ? moment(String(this.po_date)).format("YYYY-MM-DD") :  null,
              "ex_date": this.po_date ? moment(String(this.po_date)).format("YYYY-MM-DD") :  moment(String(this.dateCo)).format("YYYY-MM-DD"),
              "expense_type":this.showExpenseType?this.expenseType.selected.id:0,
              // "po_reference_date": this.po_date ? moment(String(this.po_date)).format("YYYY-MM-DD") :  null,
              "is_flush_out": (this.flushOut == 0 ? false : true),
            }
        ]
      };

      // // console.log(params);
      // return;
      // let _true = true
      // if (_true) {
      //   console.log("param submit =>",params);
      //   return
      // }
      // Canvas tetap dikirim di url normal, beda order_from saja
      let url = this.baseUrl;
      if (this.soType.Code == "ZDCV"){
         url = "/api/sfa/v1/customer-order/create-canvas";
      }

      console.log("this.soType apa? ", this.soType)
      console.log("url submit apa? ", url)

      this.$http
        .post(url, params)
        .then((resp) => {
          // // console.log(resp.data)
          if (resp.code == 200) {
            // kalau 200 ndak bener-bener success, dicek dulu.
            let msg ="",title = ""

            if(this.soType.Code != "ZDCV") {
              // // console.log(resp.data[0].status_create )
              if(resp.data[0].status_create){
                let msgArr =[]
                if (resp.data[0].status_create.status=="Error") {
                  if (resp.data[0].status_create.messages){
                    msgArr = resp.data[0].status_create.messages
                  }
                  title = resp.data[0].status_create.State
                  msgArr.forEach((dt,i)=>{
                    msg +=dt
                    if ((i+1)<msgArr.length){
                      msg +=", "
                    }
                  })
                  if (msg=="") {
                    msg = resp.data[0].status_create.message
                  }
                  this.$vs.notify({
                    color: "danger",
                    title: title,
                    text: msg,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }else if (resp.data[0].status_create.status=="Success"){
                  this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "The data was successfully submitted",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check-circle",
                  });
                  // this.$router.push({ name: "customer-order" }); //return to CO page
                  // persiapan next, redirect to view data page
                  let id = resp.data[0].id
                  this.$router.push({
                    name: "sales-order-view",
                    params: { id: id },
                  });
                }
                // msg = resp.data[0].StatusCreate.Message
              }
            } else {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "The data was successfully submitted",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              // this.$router.push({ name: "customer-order" }); //return to CO page
              this.$router.push({ name: "manage-canvas-sales" }); //return to Manage Canvas Sales page
              // persiapan next, redirect to view data page
              // let id = resp.data[0].id
              // this.$router.push({
              //   name: "customer-order-view",
              //   params: { id: id },
              // });
            }

          } else {
            if (resp.data) {
              if(resp.data.messages){
                resp.data.messages.forEach(dt => {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: dt,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                });
              }
            }else{
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          // console.log(error);
        });
    },
    checkDeposit(){
      let total_deposit = 0, cond = null;
      this.selectedCustDeposit.forEach(cd => {
        total_deposit += cd.DepositValue;
      });

      if (parseInt(this.CalcRespData.total_billing) > parseInt(total_deposit)) {
        let exceed = parseInt(this.CalcRespData.total_billing) - parseInt(total_deposit);
        cond = {
          status : false,
          exceed : exceed,
        }
      } else {
        cond = {
          status : true,
          exceed : 0,
        }
      }
      return cond;
    },
    handlerSearchSelect(searching,comp) {
      if (this.soType == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Please select SO type !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      this.getItem(searching,this.table.data[comp.parentId-1].formInputs)
    },
    handleAddItem() {
      let check_res = this.checkFormComplete();

      if(!check_res.is_complete){
        for (let i = check_res.msg_incomplete.length-1; i > -1; i--) {
          this.$vs.notify({
            title: "Warning",
            text: check_res.msg_incomplete[i],
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }

        this.calculated = false;
        return;
      }

      // // console.log(this.table)
      let i_new = this.table.data.length
      let empty_holder = {
        id:i_new+1,
        formInputs:[
          {
            id:1,
            parentId:i_new+1,
            disabled: false,
            validate: "required",
            name: "sku_code",
            placeholder: "Sku Code",
            head:"Sku Code",
            type: "multiselect",
            multiple: false,
            allowempty: false,
            value: {},
            optionValue:[],
          },
        ],
        items_qty_hu: [],
        qty_hu: [],
        data_combo: [],
        items_hu: '0',
        items_qty: '0',
        hu_labels: [],
        list_price: [],
        reg_disc: [],
        promotion: [],
        tax: [],
        tax_rate: null,
        subtotal: {
          price: [],
          disc: 0,
          tax: [],
          all_price: 0,
          all_tax: 0,
        },
      };
      this.table.data.push(empty_holder);
      this.getItem(null,this.table.data[i_new].formInputs)
      this.calculated = false;
    },
    handleRemoveItem(index) {
      if (index == 0 && this.table.data.length == 1) {
        this.table.data[index].formInputs[0].value = {};
        this.table.data[index].formInputs[0].parentId = 1;
        this.table.data[index].items_qty_hu = [];
        this.table.data[index].qty_hu = [];
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        this.table.data[index].hu_labels = [];
        this.table.data[index].list_price = [];
        this.table.data[index].reg_disc = [];
        this.table.data[index].promotion = [];
        this.table.data[index].tax = [];
        this.table.data[index].tax_rate = null;
        this.table.data[index].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
        this.table.data[index].data_combo = [];
      } else {
        this.table.data = this.table.data.filter((v, i) => {
          return i != index;
        });

        this.table.data.forEach((dt, i) => {
          dt.formInputs[0].parentId = (i+1);
        });
      }
      this.isExistSkuCombo = false;
      this.table.data.forEach(td => {
        console.log(td)
        if(td.data_combo.length > 0){
          this.isExistSkuCombo = true;
        }
      });
      this.calculated = false;
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "sales-order" });
    },
    showOTForm(){
      this.showOTCForm = true;
    },
    handleCloseOt() {
      this.showOTCForm = false;
    },
    getDataCustomer() {
      // console.log("this.selectedShippTo", this.selectedShippTo);

      if (this.selectedShippTo.ID != null) {
        let params = {
          length: 1,
          customer_id: this.selectedShippTo.customer_id,
        }
        this.isLoadingCus = true
        this.$http.get("/api/sfa/v1/customer/filter", {params: params}).then((resp) => {
            this.isLoadingCus =false
            if (resp.code == 200) {
              if (resp.data.records) {
                this.optionCustomer = resp.data.records;

                if (this.optionCustomer.length > 0) {
                  this.selectedCustomer = resp.data.records[0];
                  console.log(this.optionCustomer)
                  console.log("this.selectedCustomer", this.selectedCustomer)

                  if (this.selectedCustomer.delivery_type_id != 0) {
                    // set default delivery type
                    this.deliveryType.selected = this.deliveryType.all_options.filter((v) => {
                      if(v.id == this.selectedCustomer.delivery_type_id){
                        return v
                      }
                    });
                    this.deliveryType.selected = this.deliveryType.selected[0];
                  }

                } else {
                  this.optionCustomer = [];
                  this.selectedCustomer = { code:"", name:"" };
                }
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = { code:"", name:"" };
              }
            } else {
              // console.log(resp);
            }
            // console.log("this.selectedCustomer", this.selectedCustomer);
          });
      }
    },
    getItem(search,comp) {
      console.log("this.salesPersonal", this.salesPersonal)
      // console.log("this.salesPersonal.selected[0].ID", this.salesPersonal.selected[0].ID)
      // console.log("this.salesPersonal.selected.ID", this.salesPersonal.selected.ID)
      // console.log("this.salesPersonal.selected.ID == undefined ? ", this.salesPersonal.selected.ID == undefined)
      let idx =0
      if (comp) {
        idx = comp[0].parentId-1;
        this.table.data[idx].formInputs[0].isLoading =true
      }else{
        this.table.data.forEach((dt,i) => {
          this.table.data[i].formInputs[0].isLoading =true
        });
      }
      let searchItem =""
      if(search){
        searchItem = search
      }
      let params = {
        limit: 40,
        offset: 0,
        search: searchItem,
        co_type_code: this.soType != null ? this.soType.Code : "",
        territory_id: this.selectedTerritory != null ? this.selectedTerritory.id : null,
        // sales_personal_id: this.soType != null ? (this.soType.Code == "ZDCV" && this.salesPersonal.selected != null) ? this.salesPersonal.selected.ID : 0 : 0,
        sales_personal_id: this.soType != null ? (this.soType.Code == "ZDCV" && this.salesPersonal.selected != null) ? this.salesPersonal.selected[0].ID : 0 : 0,
      }
      this.$http
      .get("api/oms/v1/sfa-sales-order/get-item-available", { params: params,})
      .then((resp) => {
        // // console.log(resp)
        this.table.data[idx].formInputs[0].isLoading =false
        if (resp.code == 200) {
          let data =[]
          if (resp.data.records != null) {
            resp.data.records.forEach(dt=>{
              data.push(
                {
                  id:dt.item_id,
                  code:dt.sku_code,
                  text:'[' + dt.sku_code + '] ' + dt.item_name,
                  sku_name:dt.item_name,
                  is_combo:dt.is_combo,
                  type:dt.type,
                  type_name:dt.type_name,
                }
              )
            })
          }
          if (comp) {
            this.table.data[idx].formInputs[0].optionValue = data
          }else{
            this.table.data.forEach((dt,i) => {
              this.table.data[i].formInputs[0].optionValue  = data
              this.table.data[i].formInputs[0].isLoading    = false
            });
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Item option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          // console.log(resp);
        }
      });
    },
    getOnetimeCustomer() {
      this.$vs.loading();
      let params = {
        territory_id: this.selectedTerritory.id
      }
      this.$http.get("/api/oms/v1/sfa-sales-order/customer-onetime", { params: params,}).then(resp => {
        if (resp.code == 200) {
          this.selectedCustomer.code = resp.data.records.code + ' - ' + resp.data.records.name;
          this.selectedCustomer.name = "";
          this.oneTimeCust.customer = resp.data.records;
          this.selectedBillTo = resp.data.address_records;
          this.selectedShippTo = resp.data.address_records;
            // filter dist channel
            this.customerCategory.selected = this.customerCategory.all_options.filter((v) => {
              return v.ID == resp.data.records.customer_category_id;
            });
            this.customerCategory.selected = this.customerCategory.selected[0]
            // filter pricing group
            this.pricingGroup.selected = this.pricingGroup.all_options.filter((v) => {
              return v.ID == resp.data.records.pricing_group_id;
            });
            this.pricingGroup.selected = this.pricingGroup.selected[0]

            // set address
            this.oneTimeCust.address = {
              countryName: resp.data.records.country,
              provinceName: resp.data.records.province,
              cityName: resp.data.records.city,

            };
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Customer",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    handleCustOnetime(){
      /*
        Save temporary UI data Onetime Customer
        Check apakah form terisi dengan benar? jika ya :
        1. set column field Customer Name
        2. set column field Bill to
        3. Set column field Ship to
      */

      let form_empty = false;
      // Buat save customer onetime disini, ke tabel baru
      form_empty = (this.oneTimeCust.name == null || this.oneTimeCust.name == "") || form_empty;
      console.log("form_empty", form_empty)
      form_empty = (this.oneTimeCust.contact_person == null || this.oneTimeCust.contact_person == "") || form_empty;
      console.log("form_empty1", form_empty)
      form_empty = (this.oneTimeCust.nik == null || this.oneTimeCust.nik == "") || form_empty;
      console.log("form_empty2", form_empty)
      form_empty = (this.oneTimeCust.phone == null || this.oneTimeCust.phone == "") || form_empty;
      console.log("form_empty3", form_empty)

      if(form_empty){
        this.$vs.notify({
          title: "Error",
          text: "Please fill onetime customer form correctly !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return;
      }
      if (this.oneTimeCust.npwp&&(this.oneTimeCust.npwp.length != 15 && this.oneTimeCust.npwp.length != 16)) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "NPWP must be 15 or 16 characters!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }
      if (!this.oneTimeCust.nik||(this.oneTimeCust.nik&&this.oneTimeCust.nik.length != 16)) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "required 16 characters for NIK!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      this.selectedCustomer.name = this.oneTimeCust.name;
      this.$vs.notify({
        color: "success",
        title: "Success",
        text: "Set customer data success",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-check-circle",
      });
      window.scrollTo(0,0);
      // this.handleCloseOt();

    },
    submitCustOnetime(){
      return new Promise((resolve, reject) => {
        let form_empty;
        // Buat save customer onetime disini, ke tabel baru
        form_empty = (this.oneTimeCust.name == null || this.oneTimeCust.name == "") || form_empty;
        form_empty = (this.oneTimeCust.contact_person == null || this.oneTimeCust.contact_person == "") || form_empty;
        form_empty = (this.oneTimeCust.nik == null || this.oneTimeCust.nik == "") || form_empty;
        form_empty = (this.oneTimeCust.phone == null || this.oneTimeCust.phone == "") || form_empty;

        if(form_empty){
          this.$vs.notify({
            title: "Error",
            text: "Please fill form correctly !",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          return;
        }
        // this.$vs.loading();
        let params, url;
        // // console.log(this.oneTimeCust);
        url = this.oneTimeCust.id != null ?
          "/api/oms/v1/sfa-sales-order/sales-order/onetime_cust/" + this.oneTimeCust.id :
          "/api/oms/v1/sfa-sales-order/sales-order/onetime_cust/store";
        params = {
          // Default by Customer - ONETIME CUSTOMER
          type: this.oneTimeCust.customer.type,
          customer_id: this.oneTimeCust.customer.ID,
          payment_method_id: this.oneTimeCust.customer.payment_method_id,

          // Default init value, updated after SO created
          payment_term_id: this.oneTimeCust.customer.payment_term_id,

          // Value by input
          name: this.oneTimeCust.name,
          country: this.oneTimeCust.address.countryName,
          province: this.oneTimeCust.address.provinceName,
          city: this.oneTimeCust.address.cityName,
          district: this.oneTimeCust.address.districtName,
          sub_district: this.oneTimeCust.address.subdistrictName,
          address: this.oneTimeCust.address_manual,
          postal_code: this.oneTimeCust.address.postalCode,
          contact_person: this.oneTimeCust.contact_person,
          nik: this.oneTimeCust.nik,
          phone: this.oneTimeCust.phone,
          tax_id: this.oneTimeCust.tax_id,
          territory_id:this.selectedTerritory.id,
          domisili: this.oneTimeCust.domisili,
          npwp_type: this.oneTimeCust.npwp_type,
          npwp: this.oneTimeCust.npwp,
          npwp_name: this.oneTimeCust.npwp_name,
          sppkp: this.oneTimeCust.sppkp,
          tax_address: this.oneTimeCust.tax_address,
        };

        // // console.log('params', params)
        this.$http.post(url, params).then(resp => {
          // // console.log(url, resp);
          if (resp.code == 200) {
            /*
              Sukses
              1. set column field Customer Name
              2. set column field Bill to
              3. Set column field Ship to
            */
            this.oneTimeCust.id = this.oneTimeCust.id == null ? resp.data.ID : this.oneTimeCust.id;
            this.selectedCustomer.name = this.oneTimeCust.name;

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            resolve({
              line: "2332",
              resp: resp,
              status: "success"
            })
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject({
              line: "2346",
              resp: resp,
              status: "failed"
            })
          }
          // this.$vs.loading.close();
        });
      });
    },
    resetOTC_data(){
      let oneTimeCust = {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          provinceName: "",
          cityName: "",
          districtName: "",
          subdistrictName: "",
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      };
      this.oneTimeCust = oneTimeCust;
    },
    setDataAddress(val) {
      // console.log("val",val)
      // console.log("oneTimeCust.address", this.oneTimeCust.address)
      this.oneTimeCust.address = val;
    },
    calc_itemsLineQty(index){
      // // console.log('index', 1658)
      var uomConv, sumHU, sumUOM = 0;
      sumHU = this.table.data[index].items_hu.split(".");
      uomConv = this.table.data[index].hu_labels;

      if(uomConv.length == 0){
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        return
      } else if (sumHU.length != uomConv.length){
        // this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Invalid input !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }else{
        for (let i = 0; i < sumHU.length; i++) {
          sumUOM += parseInt(sumHU[i] * parseInt(uomConv[i].amount_uom));
          this.table.data[index].items_qty = sumUOM;
        }
        this.calculated = false;
      }
    },
    calc_itemsLineQtyConv(index,v){
      if (this.table.data[index].items_qty<0) {
        this.table.data[index].items_qty=0
      }
      if (this.table.data[index].isHolding) {
        this.table.data[index].items_qty=this.table.data[index].lastIN
        return;
      }
      this.table.data[index].isHolding = true;
      clearTimeout(this.table.data[index].timeout);

      this.table.data[index].timeout = setTimeout(() => {
        this.table.data[index].isHolding = false;
      }, 100);
      console.log('this.table.data[index].items_qty', this.table.data[index].items_qty)
      var uomConv, sumHU, amount_uom, sumUOM = 0;
      let dataCombo = []
      let val_input = this.table.data[index].items_qty;

      if (this.isExistSkuCombo) {
        dataCombo = this.table.data[index].data_combo
        dataCombo.forEach((el,i)=>{
          if (this.table.data[index].data_combo[i].Quantity) {
            var qtySum = this.table.data[index].data_combo[i].Quantity*parseInt(val_input)
            if (qtySum>0) {
              this.table.data[index].data_combo[i].total_qty = qtySum
            }else{
              this.table.data[index].data_combo[i].total_qty = 0
            }
          }
        })
      }
      sumHU = [];
      uomConv = this.table.data[index].hu_labels;
      sumUOM = parseInt(val_input);

      if(uomConv.length == 0){
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = 0;
        this.table.data[index].lastIN = 0;
        return
      }

      for (let i = 0; i < uomConv.length; i++) {
        amount_uom = parseInt(uomConv[i].amount_uom);
        if((sumUOM / amount_uom) > 0){
          sumHU.push(parseInt(sumUOM / amount_uom));
          sumUOM = sumUOM % amount_uom;
        }else{
          sumHU.push(0);
        }
      }
      this.calculated = false;
      this.table.data[index].items_hu = sumHU.join('.');
      this.table.data[index].items_qty_hu = sumHU;
      this.table.data[index].lastIN = v;
      this.table.data[index].data_combo.forEach((dc,i)=>{
          console.log("test2")
          this.table.data[index].data_combo[i].width = this.getWidthByValues(this.table.data[index].items_qty_hu,15,dc.total_qty)
          
        })
        if (this.table.data[index].data_combo.length==0) {
          this.table.data[index].width = this.getWidthByValues(this.table.data[index].items_qty_hu,7)
        }
    },
    calc_itemsLineQtyHU(index, hu_index){
      // console.log(hu_index)
      // console.log('this.table.data[index].items_qty_hu', this.table.data[index].items_qty_hu);
      // console.log('this.table.data[index].items_qty_hu[hu_index]', this.table.data[index].items_qty_hu[hu_index]);
      var qtyHU, uomConv, sumUOM = 0, lowest_hu;
      uomConv = this.table.data[index].hu_labels;
      qtyHU = this.table.data[index].items_qty_hu;
      lowest_hu = hu_index == (uomConv.length-1) ? true : false;
      if(qtyHU[hu_index] == undefined){
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        return
      } else if( isNaN( qtyHU[hu_index]) || qtyHU[hu_index] == '' ) {
        // console.log('Then')
        // console.log('this.table.data[index].items_qty_hu', this.table.data[index].items_qty_hu);
        // console.log('this.table.data[index].items_qty_hu[hu_index]', this.table.data[index].items_qty_hu[hu_index]);
        this.table.data[index].items_hu = '0';
        // this.table.data[index].items_qty = '0';
        this.table.data[index].items_qty_hu[hu_index] = 0;
        this.$vs.notify({
          title: "Error",
          text: "Invalid input !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }else{
        if(lowest_hu && uomConv.length != 1){
          qtyHU[hu_index] = parseInt(qtyHU[hu_index], 10);
          qtyHU[hu_index-1] = parseInt(qtyHU[hu_index-1], 10);
          // cek jika yang berubaha adalah lowest HU, dan jumlah yang diinput lebih dari amount_uom HU atasnya, maka ditambah ke UOM atasnya, dan value diset jadi mod nya
          if(qtyHU[hu_index] >= parseInt(uomConv[hu_index-1].amount_uom)){
            let divRes, modRes;
            divRes = qtyHU[hu_index] / parseInt(uomConv[hu_index-1].amount_uom);
            modRes = qtyHU[hu_index] % parseInt(uomConv[hu_index-1].amount_uom);
            // tambah uom atasnya
            qtyHU[hu_index-1] += divRes;
            // ubah input HU sekarang
            qtyHU[hu_index] = modRes;
          }
        }
        for (let i = 0; i < uomConv.length; i++) {
          qtyHU[i] = parseInt(qtyHU[i], 10);
          sumUOM += parseInt(qtyHU[i] * parseInt(uomConv[i].amount_uom));
        }
        this.table.data[index].items_hu = qtyHU.join('.');
        this.table.data[index].items_qty = sumUOM;
        this.calculated = false;
      }
      this.calc_itemsLineQtyConv(index)
    },
    formatCurrency(value) {
      if (value) {
        value = (value/1).toFixed(2).replace('.', ',');
        var final =value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        if (final) {
          return final;
        }else{
          return "0"
        }
      }else{
        return "0"
      }
    },
    getWidthByValues(s = [],width = 15,sc = 0){
      let finalWidth = 0
      let defaultWidth = 70

      if (s) {
        s.forEach((el)=>{
          let tempWidth = 0
          let length =0
          if (el) {
            length = el.toString().length
            console.log(el,"get length",length)
            if (length>2) {
              tempWidth = defaultWidth+(width* (length-2))
              if (finalWidth<tempWidth) {
                finalWidth = tempWidth
              }
            }
          }
          
        })
      }
      if (sc>0) {
        let tempWidth = 0
        let length =0
        length = sc.toString().length
        console.log(sc,"get length",length)
        if (length>2) {
          tempWidth = defaultWidth+(width* (length-2))
          if (finalWidth<tempWidth) {
            finalWidth = tempWidth
          }
        }
      }
      if (finalWidth==0) {
        console.log("get width failed", s)
        finalWidth = defaultWidth //default width
      }
      console.log("finalWidth", finalWidth)
      return finalWidth
    },
    resetShipBillto(){
      // console.log("saya terpanggil")
      this.optionBillTo = [];
      this.optionShippTo = [];
      this.selectedBillTo = {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
      }
      this.selectedShippTo = {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
      }
    },
    searchBillto(){
      let shipto = this.selectedShippTo;
      console.log("shipto", shipto);

      let params = {
        customer_id: shipto.customer_id,
        length: 1
      }

      var url2 = "/api/oms/v1/sfa-sales-order/customer-address/bill-to"
      this.$http.get(url2, {params: params,}).then((resp) => {
        if (resp.code == 200) {
          if (resp.data.records != null) {
            this.optionBillTo = resp.data.records;
            this.selectedBillTo = resp.data.records[0];
          } else {
            this.optionBillTo = [];
            this.selectedBillTo = {
              ID:null,
              customer_id:"",
              contact_name:"",
              code:"",
              address:"",
              search:"",
            };

            this.$vs.notify({
              title: "Warning",
              text: "Bill to addres not found.",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
            return;
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error on search bill to addres. " + resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
          return;
        }
        console.log(resp);
      });
    },
    handlerSearchShipto(search){
      // console.log("Masuk rene neh lho. search nya? ")
      // console.log("search nya? empty kah ?" , (search != ""))
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }

      let terrID = this.getUserLogin().territory_id;
      if (terrID == 0) {
        console.log (this.selectedTerritory);
        if (this.selectedTerritory != null) {
          terrID = this.selectedTerritory.id;
        }
      }
      this.selectedShippTo.search = search
      if(search != ""){
        let params = {
          territory_id: terrID,
          search: search,
          length: 40,
          so_type: this.isOneTime ? "ZDOT" : "non-ZDOT",
        }
        this.getAllAddress(params);
      }else{
        this.isLoadingShipto = false
      }
    },
    getAllAddress(params){
      // console.log("this.selectedTerritory", this.selectedTerritory);
      // console.log("params", params);
      const tempSearch = params.search
      this.isLoadingShipto = true
      // var url1 = "/api/sfa/v1/customer-address/list"
      var url2 = "/api/oms/v1/sfa-sales-order/customer-address/ship-to"
      this.$http.get(url2, {params: params,}).then((resp) => {
        // console.log(resp)
        if (tempSearch==this.selectedShippTo.search) {
          this.isLoadingShipto = false
        }
        if (resp.code == 200&&tempSearch==this.selectedShippTo.search) {
          if (resp.data.records != null) {
            this.optionShippTo = resp.data.records;
          } else {
            this.optionShippTo = [];
          }
        } else {
          // console.log(resp)
        }
      });
    },
    onInputRounding(index, i){
      // console.log("Disini 2509", index, i)
      this.calculated = false;
    },
    getCustDeposit(cust_id) {
      this.$vs.loading();
      this.$http.get("api/oms/v1/sfa-sales-order/cash-bank/customer-deposit/" + cust_id).then((resp) => {
        // console.log(resp);
        if (resp.status == "success") {
          if(resp.data.cashBank.length > 0){
            this.optionCustDeposit = resp.data.cashBank;
          } else {
            this.$vs.notify({
              title: "Warning",
              text: "No deposit found ! Please add or create one first !",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setNullPoDate(){
      this.po_date = null;
    },
    checkFormComplete(){
      let is_complete = true, msg_incomplete = [];

      if(this.selectedShippTo.ID == null){
        is_complete = false;
        msg_incomplete.push("Please select shipp to address !")
      } else {
        if(this.selectedCustomer == {} || this.selectedCustomer == null || this.selectedCustomer.code == "" || this.selectedCustomer.code == null){
          is_complete = false;
          msg_incomplete.push("Please select customer !")
        }

        if(this.selectedBillTo.ID == null){
          is_complete = false;
          msg_incomplete.push("Please select bill to address !")
        }

        if(this.selectedTerritory == null){
          is_complete = false;
          msg_incomplete.push("Please select territory !")
        }

        if(this.zone.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select zone !")
        }

        if(this.salesChannel.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select sales channel !")
        }

        // // console.log(this.salesPersonal)
        if(this.salesPersonal.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select salesman !")
        }

        if(this.customerCategory.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select distribution channel !")
        }

        if(this.pricingGroup.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select pricing group !")
        }

        if(this.paymentTerm.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select payment term !")
        }

        if(this.soType == null){
          is_complete = false;
          msg_incomplete.push("Please select SO type !")
        }

        if (this.deliveryType.selected == null) {
          is_complete = false;
          msg_incomplete.push("Please select delivery type !")
        }

        let complete_item = true;
        this.table.data.forEach(td => {
          if(td.formInputs[0].value.code == undefined){
            complete_item = false;
          }
        });

        if(!complete_item){
          is_complete = false;
          msg_incomplete.push("Please select item !")
        }

      }

      if(this.oneTimeCust.name == null && this.isOneTime){
        is_complete = false;
        msg_incomplete.push("Please input onetime customer name!")
      }

      if(this.oneTimeCust.address_manual == null && this.isOneTime){
        is_complete = false;
        msg_incomplete.push("Please input onetime customer address!")
      }

      return {
        is_complete: is_complete,
        msg_incomplete: msg_incomplete,
      }
    },
    checkSkuSelected(){
      let complete_item = false;
      this.table.data.forEach(td => {
        if(td.formInputs[0].value.code != undefined){
          complete_item = true;
        }
      });

      return complete_item;
    },
    isSampleCO(){
      if (this.soType != null) {
        if (this.soType.Code == "ZDSM") { this.allowPartial = 0; return true; }else{ return false; }
      } else {
        return false;
      }
    },
    expandDetail(i1,i2){
      console.log(i1,i2)
      if (this.table.data.length>0&&this.table.data[i1].list_price.length>0) {
        this.table.data[i1].list_price[i2].is_expand = !this.table.data[i1].list_price[i2].is_expand
        if (this.table.data[i1].list_price[i2].is_expand) {
          this.table.data[i1].rowSpanPlus++
        }else if(this.table.data[i1].rowSpanPlus>1){
          this.table.data[i1].rowSpanPlus--
        }
      }
    },
    getMoq() {
      console.log(this.selectedShippTo);
      let params = {
        customer_id:this.selectedShippTo.customer_id,
      }
      this.$http
      .get("/api/sfa/v1/customer-order/minimum-order-quantity", { params: params,})
      .then((resp) => {
        // // console.log(resp)
        if (resp.code == 200) {
          this.moq = resp.data
          console.log(this.moq);
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          // console.log(resp);
        }
      });
    },
    detailDiscShow(i,i2){
      this.isDetailDisc = true
      if (this.table.data[i]&&this.table.data[i].list_price[i2]) {
        console.log("this.table.data[i].list_price[i2]", this.table.data[i].list_price[i2])
        this.detailPricing = {
          "pricing_code": this.table.data[i].list_price[i2].price_rule_code,
          "price": this.table.data[i].list_price[i2].price,
          "sub_total": this.table.data[i].list_price[i2].sub_total,
          "tax": this.table.data[i].list_price[i2].tax,
          "rounding": this.table.data[i].list_price[i2].rounding,
          "nett": this.table.data[i].list_price[i2].sub_total-this.table.data[i].list_price[i2].discount+this.table.data[i].list_price[i2].rounding,
          "gross_up": this.table.data[i].list_price[i2].gross_up,
          "dpp": this.table.data[i].list_price[i2].dpp,
          "grand_total": this.table.data[i].list_price[i2].grand_total,
          "sku": this.table.data[i].list_price[i2].item_name+" "+this.table.data[i].list_price[i2].unit,
          "total_discount": this.table.data[i].list_price[i2].discount, // MIX2-2218
        }
        // this.pricingCode = this.table.data[i].list_price[i2].price_rule_code
        // this.pricing = this.table.data[i].list_price[i2].price
        // this.subtotal = this.table.data[i].subtotal.price[i2]
        // this.grossUp = this.table.data[i].list_price[i2].gross_up
        // this.tax = this.table.data[i].list_price[i2].tax
        // this.rounding = this.table.data[i].list_price[i2].rounding
        // this.sku = this.table.data[i].list_price[i2].item_name+" "+this.table.data[i].list_price[i2].unit
        this.detailDataDiscount= this.table.data[i].list_price[i2].promo_calculation
      }else{
        this.detailPricing = {}
        this.detailDataDiscount = []
      }
    },
    getStatusAllowPartial(lines){
      if (lines.list_price) {
        let allow_partial = false;
        lines.list_price.forEach(prices => {
          if (prices.promo_calculation) {
            prices.promo_calculation.forEach(calc => {
              if (calc.allow_partial_fulfill) {
                allow_partial = true;
              }
            });
          }
        });
        return allow_partial;
      }
    }
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return "0"
      }
    },
  },
  watch: {
    selectedTerritory: function () {
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }

      if(!this.isOneTime && this.selectedShippTo.ID == null){
        this.searchSales = "";
        this.getDataCustomer();
        this.getOptionSalesID(false);
        this.resetShipBillto();
      }

      if(this.isOneTime){
        this.getOnetimeCustomer();
      }

      this.zone.option = this.zone.all_options.filter((v) => {
        if(v.territory_code == this.selectedTerritory.code){
          console.log("v", v);
          console.log("v.territory_code", v.territory_code);
          console.log("this.selectedTerritory.code", this.selectedTerritory.code);
          console.log(true);
        }
        return v.territory_code == this.selectedTerritory.code;
      });
      this.zone.selected = this.zone.option[0];
    },
    selectedCustomer: function () {
      let select = this.selectedCustomer;
      this.selectedCustomer.default_payment_term = null;
      console.log("select",select);
      if (select != null && select.code != "") {
        // this.optionBillTo   = select.customer_address
        // this.optionShippTo  = select.customer_address

        let territory = (this.selectedTerritory != null && this.selectedTerritory.id) ? this.selectedTerritory : null;

        // // console.log(this.selectedCustomer);
        // filter dist channel
        this.customerCategory.selected = this.customerCategory.all_options.filter((v) => {
          return v.ID == select.customer_category_id;
        });
        this.customerCategory.selected = this.customerCategory.selected[0]
        // filter pricing group
        this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
          return v.ID == select.pricing_group_id;
        });
        this.pricingGroup.selected = this.pricingGroup.selected[0]
        // filter payment term
        this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
          return v.id == select.payment_term_id;
        });
        this.paymentTerm.selected = this.paymentTerm.selected[0];
        this.selectedCustomer.default_payment_term = this.paymentTerm.selected;
        this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
          return v.due_invoice_period <= this.paymentTerm.selected.due_invoice_period;
        });

        console.log("this.selectedCustomer", this.selectedCustomer)
        if (this.soType != null && this.soType.Code == "ZDCBD") {
          this.getCustDeposit(select.id);
          this.showCustDeposit = true;
        } else {
          this.showCustDeposit = false;
        }
      }

    },
    dateCo: function() {
      // convert m/d/y only
      let today, dateCo, rDeliveryDate, tempRdd;
      today = moment(new Date()).format("MM/DD/YYYY");
      today = new Date(today);
      if(this.dateCo != null) {
        dateCo = moment(this.dateCo).format("MM/DD/YYYY");
        dateCo = new Date(dateCo);
        if(dateCo > today) {
          this.dateCo = today;
          this.$vs.notify({
            title: "Warning",
            // text: "CO date cannot backdated",
            text: "You can only select a backward date",
            color: "warning",
            position: "top-right"
          });
        }
        if(this.rDeliveryDate != null) {
          rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
          rDeliveryDate = new Date(rDeliveryDate);

          tempRdd = new Date();
          tempRdd.setDate( this.dateCo.getDate() + 2)

          if(rDeliveryDate <= this.dateCo){
            // rDeliveryDate.setDate( this.dateCo.getDate() + 1)
            rDeliveryDate.setDate( this.dateCo.getDate() + 2) //default RDD H+2 dari dateCO
            this.rDeliveryDate = rDeliveryDate;
          }
        }
        this.disabledDates = {
          to: today
        }
      }
      console.log("this.disabledDates", this.disabledDates)
    },
    rDeliveryDate: function() {
      // convert m/d/y only
      // reqDelivDate minimal today+1 (besok) atau dateCo + 1 day
      let today, dateCo, rDeliveryDate, duedate, limit;
      today = moment(new Date()).format("MM/DD/YYYY");
      today = new Date(today);

      if(this.rDeliveryDate != null) {
        rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
        rDeliveryDate = new Date(rDeliveryDate);
        if(this.dateCo != null) {
          dateCo = moment(this.dateCo).format("MM/DD/YYYY");
          dateCo = new Date(dateCo);
          limit = dateCo;
          // limit.setDate( limit.getDate() + 1)
          if(rDeliveryDate < limit){
            this.rDeliveryDate = limit;
            this.$vs.notify({
              title: "Warning",
              text: "Request delivery date cannot less than CO date",
              color: "warning",
              position: "top-right"
            });
          }
        }
        if(rDeliveryDate < today) {
          this.rDeliveryDate = limit;
          this.$vs.notify({
            title: "Warning",
            text: "Request delivery date cannot backdated",
            color: "warning",
            position: "top-right"
          });
        }
      }
    },
    isOneTime: function() {
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        this.isOneTime = !this.isOneTime;
        return;
      }

      // reset selected Pricing Group
      this.pricingGroup.selected = null

      if(!this.isOneTime){
        // Reset All
        this.customerCategory.selected = null;
        // reset shipto
        this.selectedShippTo = {
          ID:null,
          customer_id:"",
          code:"",
          address:"",
        }

        // reset billto
        this.selectedBillTo = {
          ID:null,
          customer_id:"",
          code:"",
          address:"",
        }

        // reset custData
        this.selectedCustomer.id = null;
        this.selectedCustomer.name = "";
        this.selectedCustomer.code = "";

        // reset Payment Term and Deliv Type
        this.paymentTerm.option = [];
        this.paymentTerm.selected = null;
        this.deliveryType.option = this.deliveryType.all_options;
        this.deliveryType.selected = null;

        // reset SO type
        this.optionSoType = [];
        this.optionSoType = this.allOptionSoType
        this.soType = null;

        // reset OneTimeCustomer Data
        this.resetOTC_data();
        this.showOTCForm = false;
      }else{
        // Get onetimeCust master data

        if (this.selectedTerritory == null || this.selectedTerritory.id == null) {
          this.$vs.notify({
            title: "Warning",
            text: "Please select territory !",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
          this.showOTCForm = false;
          this.isOneTime = false;
          return
        }

        this.showOTCForm = true;
        this.getOnetimeCustomer();

        // Restart territory sampai pricing group, show all entry/field
        this.optionTerritory = this.AllOptionTerritory;
        // this.selectedTerritory = null;

        // this.zone.option = [];
        // this.zone.selected = null;

        this.salesPersonal.option = this.salesPersonal.all_options;
        this.salesPersonal.selected = null;

        // pembatasan Payment Term || if onetime, hanya boleh D0000
        this.paymentTerm.option = [];
        this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
          if(v.name == "D000"){
            return v
          }
        });
        this.paymentTerm.selected = this.paymentTerm.option[0];

        // pembatasan Delivery Type || if onetime, hanya boleh LCO
        this.deliveryType.option = [];
        this.deliveryType.option = this.deliveryType.all_options.filter((v) => {
          if(v.name == "LCO"){
            return v
          }
        });
        this.deliveryType.selected = this.deliveryType.option[0];

        // pembatasan SO type
        // if onetime, Type nya juga harus onetime
        // this.optionSoType = [];
        // this.optionSoType = this.allOptionSoType.filter((v) => {
        //   if(v.Code == "ZDOT"){
        //     return v
        //   }
        // });
        // this.soType = this.optionSoType[0];
      }
    },
    soType: function() {
      if(this.soType.Code == "ZDSM") {
        this.allowPartial = 0
      }
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      this.calculated = false;
      this.selectedCustDeposit  = [];
      this.optionCustDeposit    = [];
      if (this.soType.Code == "ZDSM") {
          // show expense type
          this.showExpenseType = true

        } else {
          // hide expense type
          this.showExpenseType = false
          // reset selected expense type
          this.expenseType.selected = { id :0, name :'', };
        }
      if (this.selectedCustomer.id && !this.isOneTime) {

        let defaultD000 = ["ZDCV", "ZDCO", "ZDSM"]
        let onlyD000 = ["ZDCV", "ZDCO", "ZDSM"]

        this.getItem();

        // Default payment term,
        // In BRD
        // i.	Payment term yang bisa dipilih adalah payment term customer dan payment term < default payment term customer.
        let cust_pt_id = this.selectedCustomer.payment_term_id
        let cust_pt = this.paymentTerm.all_options.filter((v) => {
          return v.id == cust_pt_id;
        });
        cust_pt = cust_pt[0];
        this.paymentTerm.selected = cust_pt
        this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
          return v.due_invoice_period <= cust_pt.due_invoice_period;
        });

        this.showCustDeposit = false;
        /*
          1. kalau canvas, maka item/sku nya hanya terbatas pada yang dibawa sales
          2. reset item yang kepilih dulu
          3. get data item nya dikasi param SOType
        */
        if (this.soType.Code == "ZDCV") {
          // console.log("this.salesPersonal", this.salesPersonal);
          // console.log("this.soType", this.soType);
          if (this.salesPersonal.selected == null || this.salesPersonal.selected.length == 0) {
            this.soType = null;
            this.$vs.notify({
              title: "Warning",
              text: "Please select salesman first !",
              color: "warning",
              position: "top-right"
            });
            return;
          } else {
            // re empty selected SKU
            this.table.data.forEach((dt,i) => {
              this.table.data[i].formInputs[0].value = {};
              this.table.data[i].items_qty_hu = [];
              this.table.data[i].qty_hu = [];
              this.table.data[i].items_hu = '0';
              this.table.data[i].items_qty = '0';
              this.table.data[i].hu_labels = [];
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].promotion = [];
              this.table.data[i].tax = [];
              this.table.data[i].tax_rate = null;
              this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
            });
          }
        }

        if (this.soType.Code == "ZDOT") {
          this.soType = null;
          this.$vs.notify({
            title: "Warning",
            text: "Please activate One Time switch first !",
            color: "warning",
            position: "top-right"
          });
        }

        if (this.soType != null && this.soType.Code == "ZDCBD") {
          this.getCustDeposit(this.selectedCustomer.id);
          this.showCustDeposit = true;
          this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
            return v.is_cbd || v.due_invoice_period <= cust_pt.due_invoice_period;
          });
          if (this.paymentTerm.option&&this.paymentTerm.option.length>0) {
            this.paymentTerm.selected = cust_pt
          }else{
            this.paymentTerm.selected = null
          }
        }

        if ( defaultD000.includes(this.soType.Code) ) {
          // filter payment term
          this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
            return v.name == "D000";
          });

          if (onlyD000.includes(this.soType.Code)) {
            // lock D000
            this.paymentTerm.option = this.paymentTerm.selected;
          }

          this.paymentTerm.selected = this.paymentTerm.selected[0];
        } else {
          this.paymentTerm.selected = cust_pt
        }

        if (this.soType.Code == "ZDTO") {
          // can't choose COD payment term
          // this.paymentTerm.option = this.paymentTerm.option.filter((v) => {
          //   return v.name != "D000";
          // });
          // this.paymentTerm.selected = this.paymentTerm.option[0];

          // (SIT 1 - 04/04/2024)
          // 10. [MIX2-0086] ketika default payment term customer D000, tidak bisa membuat SO type ZDTO
          if(this.selectedCustomer.default_payment_term && this.selectedCustomer.default_payment_term != null){
            if (this.selectedCustomer.default_payment_term.name == "D000"){
              this.soType = null;
              this.$vs.notify({
                title: "Info",
                text: "You can choose ZDCV, ZDCO, ZDSM, ZDCBD.",
                color: "warning",
                position: "top-right"
              });
              this.$vs.notify({
                title: "Warning",
                text: "Can't choose ZDTO for customer with default payment term D000 !",
                color: "danger",
                position: "top-right"
              });
              // set batasan SO type
              // this.optionSoType = this.allOptionSoType.filter((v) => {
              //   if(onlyD000.includes(v.Code)){
              //     return v
              //   }
              // });
              // this.soType = this.optionSoType[0];
            } else {
              this.optionSoType = this.allOptionSoType;
              // 12. [MIX2-0088] ketika default payment term customer != D000 dan diubah SO type menjadi ZDTO.
              // Maka default payment term berubah menjadi COD
              // due_invoice_period > 0 && is_cod = false && is_cbd = false;
              this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
                return v.due_invoice_period>0&&v.is_cod ==false&&v.is_cbd==false && v.due_invoice_period <= cust_pt.due_invoice_period;
              });
              if (this.paymentTerm.option&&this.paymentTerm.option.length>0) {
                this.paymentTerm.selected =  cust_pt
              }else{
                this.paymentTerm.selected = null
              }
              // this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
                // if(v.name == "Cash On Delivery (COD)" || v.is_cod){ //sementara di comment
                //   return v
                // }
              // });
              // this.paymentTerm.selected = this.paymentTerm.selected[0];
            }
          }
        }

        if (this.soType.Code == "ZDBB") {
          // filter delivery type, default FCO
          this.deliveryType.selected = this.deliveryType.all_options.filter((v) => {
            if(v.name == "FCO"){
              return v
            }
          });
          this.deliveryType.selected = this.deliveryType.selected[0];
        }
        if(this.soType.Code=="ZDFO"){
          this.flushOut = 1

          // opsi PaymentTerm tidak bisa pilih CBD
          this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
            return !v.is_cbd && v.due_invoice_period <= cust_pt.due_invoice_period;
          });
          if (this.paymentTerm.option&&this.paymentTerm.option.length>0) {
            this.paymentTerm.selected = cust_pt
          }else{
            this.paymentTerm.selected = null
          }
        }else{
          this.flushOut = 0
        }
      } else {
        if (this.soType != null && !this.isOneTime) {
          this.soType = null;
          // hide expense type
          this.showExpenseType = false
          // reset selected expense type
          this.expenseType.selected = { id :0, name :'', };
          this.$vs.notify({
            title: "Warning",
            text: "Please select customer first !",
            color: "warning",
            position: "top-right"
          });
          return;
        }
      }
    },
    "salesChannel.selected": function (){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      if (this.salesPersonal.selected == null) {
        this.searchSales = "";
        this.getOptionSalesID(false);
      }
    },
    calculated:function(v){
      if (!v) {
        // MIX2-2434
        // Allow Partial Sales Order selalu berubah menjadi No, ketika calculate ulang. Padahal sudah setup diawal Yes dan tidak ada promo
        // Create SO dengan Allow Partial=Yes
        // input SKU & qty (tanpa promo)
        // calculate (allow partial=Yes)
        // edit qty (tanpa promo)
        // calculate (maka allow partal=No)
        // ** allowPartial tidak direset menjadi = 0

        // this.allowPartial = 0
        this.table.free_goods = []
        this.existing_fg = false
      }
    }
  },
  mounted() {
    this.trxTimeUnix = moment().unix()
    console.log("trxTimeUnix",this.trxTimeUnix)
    console.log("this.$userLogin", this.$userLogin)
    this.dateCo = new Date();

    // Default reqDelivDate today + 1 day
    this.rDeliveryDate = new Date();
    // this.rDeliveryDate.setDate( this.rDeliveryDate.getDate() + 1)
    this.rDeliveryDate.setDate( this.rDeliveryDate.getDate() + 2) // default rdd H+2 from today or dateCO
    this.duedate = this.rDeliveryDate;

    this.getTerritory();
    this.getOptionZones();
    this.getOptionSalesChannel();
    this.getOptionCustomerCategory();
    // this.getOptionSalesID();
    this.getAllDataPricingGroup();
    this.getOptionPaymentTerm();
    this.getOptionDeliveryType();
    this.getItem();
    this.getSOTypes();
    // this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--thead{
  z-index:-1 !important;
  display: none;
}
.table-expend:hover,.table-expend-combo:hover td.combo-hover{
    background: #e9e9e9;
    cursor: pointer;
}
.table-expend:active,.table-expend-combo td.combo-hover:active{
    background: #e7e5e5;
}
.badge.badge-success {
  background-color: #28c76f;
}
.badge {
  font-weight: 400;
  color: #fff;
  background-color: #7367f0;
}
.badge-success {
  color: #fff;
  background-color: #28c76f;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.vs-input-number{
  margin: auto;
}
</style>
