<style>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: letter portrait;
        margin: 3%;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            /* font-size: 14px; */
            font-family: 'Courier New', Courier, monospace;
            height: 99%;
            /* zoom: 84%; */
        }
        .break-before{
            page-break-before:always;
        }
        .print-foot {
            display:block !important;
        }
        .page-number:before {
            content: "Page: " counter(page);
        }
        
        .contentPrint{
            display: block !important;
            margin:0px !important;
            max-width: 100% !important;
        }
        /* .print-foot-2 {
            bottom: 405px !important;
        } */
    }
    *{
        /* font-size: 14px; */
        font-family: 'Courier New', Courier, monospace;
        line-height: normal !important;
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    .title ,.title th{
        font-size: small;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .head p,.head th{
        font-size: small;
        font-weight: bold;
        color: black;
    }
    .body p,.body td{
        font-size: small;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
        line-height: 2;
    }

    .lh-1,.lh-1 th{
        line-height: 1;
    }
    .lh-1\/2,.lh-1\/2 p{
        line-height: 1.5;
    }

    .remove_border tr th{
        border: none;
    }
    .bb-dashed{
        border-bottom: dashed;
        border-bottom-width: medium;
        margin-top: 3px;

    }
    .bt-dashed{
        border-top: dashed !important;
        border-top-width: medium;
        margin-bottom: 3px;
    }
    .no-border th,.no-border td{
        border: none;
        padding: 2px;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }

    .text-uppercase {
        text-transform: uppercase !important;
    }
    .text-capitalize {
        text-transform: capitalize !important;
    }
    .print-foot {
        position: relative;
        left: 0;
        right: 0px;
        display: block;
    }
    .print-foot-hide {
        display:block
    }
    .contentPrint{
        margin:50px;
        max-width: 600px;
        display: none;
    }
</style>

<template>
    <div id="contentPrint">
        <div class="contentPrint">
            <table class="vx-row my-3">
                <tbody>
                    <tr class="no-border head">
                        <th class="align-top pl-6">
                            <p class="text-uppercase" style="margin: 0!important;">{{salesOrder.BillAddress.Zone.CompanyName }}</p>
                            <p class="text-capitalize" style="margin: 0!important;">Cab. {{capitalize(salesOrder.TerritoryName) }}</p>
                            <p>{{salesOrder.Territory.TerritoryGeo.Address }}</p>
                            <br />
                            <p>{{salesOrder.Customer.CustomerPhone }}</p>
                            <br />
                            <p>NPWP: {{salesOrder.Customer.Company.Npwp }}</p>
                            <br />
                            <p>Sold-To-Party</p>
                            <p>{{salesOrder.BillAddress.ContactName }}</p>
                            <p>{{salesOrder.Customer.AltName }}</p>
                            <p>{{salesOrder.BillAddress.Address }}</p>
                            <p>{{salesOrder.BillAddress.SubDistrict }}</p>
                            <p>{{salesOrder.BillAddress.PostalCode }}</p>
                            <br />
                            <p>NPWP: {{salesOrder.Customer.Npwp }}</p>
                            <br />
                        </th>
                        <th class="align-top" style="min-width: 255px">
                            <p class="text-uppercase title bb-dashed text-center" style="font-size: medium;">Sales Order</p>
                            <!-- <div class="vx-row mt-3 body"> -->
                            <table>
                                <tbody>
                                    <tr class="no-border">
                                        <td class="align-top">Order Type</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ salesOrder.Category }}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Number/Date</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ salesOrder.PoReferenceCode + "/ "+formatDate(salesOrder.Date)}}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Reference No.</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ salesOrder.Code }}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Ref. Date/ RDD</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ formatDate(salesOrder.PoReferenceDate) +"/ "+formatDate(salesOrder.RequestDeliveryDate) }}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Customer No. </td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ salesOrder.Customer.Code }}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Sales. No./ Name</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ salesOrder.SalesPersonalCodeExternal+"/ "+salesOrder.SalesPersonalName }}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Term of Payment</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ "Due within "+salesOrder.PaymentTerm.DueInvoicePeriod+" days" }}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Condition Delivery</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ salesOrder.DeliveryTypeName }}</td>
                                    </tr>
                                    <tr class="no-border">
                                        <td class="align-top">Tax Status / Currency</td>
                                        <td class="align-top"></td>
                                        <td class="align-top">{{ salesOrder.Customer.IsPkp=="0"?"Non PKP":"PKP" }}/IDR</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- </div> -->
                        </th>
                    </tr>
                </tbody>
            </table>
            <PrintTable :salesOrder="salesOrder" :page="1"></PrintTable>
            <!-- salesOrder.foot_top -->
            <div v-if=" 1 != salesOrder.total_page" class="vx-row m-3 print-foot print-foot-hide" :style="{'top':'20px !important'}">
                <table>
                    <tfoot>
                        <tr class="no-border head">
                            <th colspan="2"></th>
                            <th class="text-right">Page {{ "1 of "+ salesOrder.total_page }}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div v-for="(n) in salesOrder.total_page" :key="n">
                <div v-if="n > 1">
                    <PrintTable class="break-before" :salesOrder="salesOrder" :page="n"></PrintTable>
                    <div v-if="n < salesOrder.total_page&& n != salesOrder.total_page" class="vx-row m-3 print-foot print-foot-hide" :style="{'top':salesOrder.foot_top+'px !important'}">
                        <table>
                            <tfoot>
                                <tr class="no-border head">
                                    <th colspan="2"></th>
                                    <th class="text-right">Page {{ n+" of "+ salesOrder.total_page }}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>


            <div class="bb-dashed head lh-1/2">
                <p>SAY : {{ terbilang(salesOrder.Total) }}</p>
            </div>
            <div class="bb-dashed head lh-1/2">
                <p>Customer PO. No. : {{ salesOrder.Code }}</p>
                <p>Message : {{ salesOrder.Notes }}</p>
            </div>
            <div class="head lh-1/2">
                <p>Shipping Address :
                {{salesOrder.ShipAddress.ContactName }} -
                {{salesOrder.ShipAddress.Address }} -
                {{salesOrder.ShipAddress.SubDistrict }} -
                {{salesOrder.ShipAddress.City }} -
                {{salesOrder.ShipAddress.PostalCode }}</p>
            </div>
            <div class="vx-row m-3 print-foot print-foot-hide" :style="{'top':'20px !important'}">
                <table>
                    <tbody>
                        <tr class="no-border head">
                            <th class="align-bottom">Bank Account :</th>
                            <th></th>
                            <th class="text-center p-0">
                                SE & O <br /><br /><br />
                                (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                            </th>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="no-border head">
                            <th colspan="2"></th>
                            <th class="text-right mt-6 pt-6">Page {{ salesOrder.total_page + " of " + salesOrder.total_page }}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
            <!-- {{ print() }} -->
    </div>
</template>

<script>
import moment from "moment";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import PrintTable from "./print-table.vue"
// import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintPdf",
    components: {
        PrintTable
    },
    data() {
        return {
            id: null,
            co_code: null,
            so_id: null,
            reqCode: null,
            unlCode: null,
            selectedWarehouse: null,
            selectedSales: null,
            loadingDate: null,
            unloadingDate: null,
            salesOrder:{
                page:1,
                total_page:1,
                print_foot_top :200,
                foot_top:0,
            },
            // logoSDN: logoSDN,
            showInfo: false,
            say:"",
            max_per_item :35,
        }
    },
    watch:{
        salesOrder :function(v){
            if (this.salesOrder) {
                console.log(this.salesOrder)
                // this.$nextTick(() => {
                //     window.print();
                // });
                // this.print()
            }
        }
    },
    async mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            await this.getDataManage();
            Promise.all([
                this.getIdSo(),
            ]).then((r) => {
                console.log(r);
                // if(this.so_id != null && this.so_id != 0){
                // }
                this.getData();
                console.log("this.so_id", this.so_id)
          
                this.$vs.loading.close();
                this.$vs.notify({
                    color: "success",
                    title: "Get Data",
                    text: r[0].message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check-circle",
                  });
            }).catch(function (error) {
                this.$vs.notify({
                    color: "danger",
                    title: "Get Data",
                    text: "Error on get data",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                console.log(error);
            });
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
            };
        },
        print(){
            // this.getData()
            // let contentOri = document.querySelector('body').innerHTML
            let contentPrint = document.getElementById("contentPrint").innerHTML
            document.body.innerHTML = contentPrint
            window.print()
            // document.body.innerHTML = contentOri
        },
        async getDataManage() {
            let url = "/api/sfa/v1/customer-order/find-by-corequest-id2/" + this.id;
            this.$vs.loading();
            let resp = await this.$http.get(url);

            console.log(resp);
            if (resp.code == 200 && resp.data.records[0].customer_order_id != 0) {
                console.log(resp.data);
                this.co_code = resp.data.records[0].customer_order_code
                console.log("this.co_code", this.co_code)
            } else {
                console.log(resp.data);
            }
            console.log(resp.data);

            this.$vs.loading.close();
        },
        async getIdSo() {
            console.log("getIDSo")
            return new Promise((resolve, reject) => {
                let paramTable = {
                    search: this.co_code,
                    limit: 10,
                    offset: 0,
                    so_types: ["ZDCV"],
                    territory_code: "All",
                    is_use_approval_matrix: false,
                    progress_restriction: true,
                    progress_status: "allocated",
                    canvas_tab_type: "billing",
                    is_billed: 1,
                    // start_date: 2024-12-16T03:30:10.493Z,
                    // end_date: 2024-12-16T03:30:10.493Z,
                }
                this.$http.get("/api/oms/v1/sfa-sales-order/sales-order-mix2", {params:paramTable,}) .then((resp) => {
                    console.log(resp);
                    if (resp.code == 200) {
                        console.log(resp.data);
                        this.co_code = resp.data.SalesOrders[0].Code
                        console.log("this.co_code", this.co_code)

                        if(resp.data.SalesOrders.length > 0) {
                            this.so_id = resp.data.SalesOrders[0].DefaultAttribute.ID
                        }
                        console.log("this.so_id", this.so_id)
                        resolve(true);
                    } else {
                        console.log(resp.data);
                        reject(false);
                    }
                    console.log(resp.data);
                });

            });
        },
        async getData() {
            this.$vs.loading()
            // let paramTable = {
            //     sales_order_id: this.so_id,
            //     print:true,
            // };
            let paramTables = {
                sales_order_id: (this.so_id != null && this.so_id != 0) ? this.so_id : null,
                print:true,
            }
            await this.$http.get("/api/oms/v1/sfa-sales-order/sales-order-mix2", {params: paramTables}).then(resp => {
                this.$vs.loading.close();
                  console.log("resp", resp)
                  if (resp.code == 200) {
                      this.salesOrder = resp.data.SalesOrders[0];
                      this.salesOrder.page=1
                      this.salesOrder.total_page=1
                      this.salesOrder.foot_bottom=0
                      if (this.salesOrder) {
                        let totalItem =0
                        const totalItemMax = 7
                        const totalItemMax2 = 10
                        // const loopMax = 80 //for testing
                        const loopSplit = 20
                        const loopSplit2 = 25
                        let tempLoopSplit = loopSplit
                        // let item = {} //for testing
                        let totalDpp = 0
                        this.salesOrder.Items.forEach((el,i)=>{
                            if (el.lines) {
                                el.lines.forEach((el2,i2)=>{
                                    totalDpp +=
                                        (parseFloat(el2.subtotal))-
                                        parseFloat(el2.discount)+
                                        parseFloat(el2.rounding)+
                                        parseFloat(el2.gross_up)
                                    // item = el2//for testing
                                    // item.break =""//for testing
                                    this.salesOrder.Items[i].lines[i2].page = this.salesOrder.total_page
                                    if (totalItem==tempLoopSplit) {
                                        this.salesOrder.Items[i].lines[i2].break =".break-after"
                                        this.salesOrder.total_page++
                                        tempLoopSplit +=loopSplit2
                                    }
                                    totalItem++
                                })
                            }
                        })
                        this.salesOrder.total_dpp = totalDpp
                        // for testing
                        // if (this.salesOrder.Items) {
                        //     for (let i = 0; i < loopMax; i++) {
                        //         let tempBreak = ""
                        //         console.log(totalItem)
                        //         if (totalItem==tempLoopSplit) {
                        //             tempBreak = ".break-before"
                        //             item.break = tempBreak
                        //             this.salesOrder.total_page++
                        //             tempLoopSplit +=loopSplit2
                        //         }
                        //         console.log("item.break=>",item.break)
                        //         this.salesOrder.Items[0].lines.push({
                        //             item_name   :item.item_name,
                        //             qty         :item.qty,
                        //             unit        :item.unit,
                        //             price       :item.price,
                        //             discount    :item.discount,
                        //             break       :tempBreak,
                        //             page        :this.salesOrder.total_page,
                        //         })
                        //         totalItem++
                        //     }
                        // }
                        if (totalItem == 0) {
                            totalItem = 1
                        }
                        if (totalItem > 10) {
                            this.salesOrder.print_foot_top = 20
                        }else{
                            this.salesOrder.print_foot_top = 200
                        }
                        this.salesOrder.foot_top = this.salesOrder.print_foot_top 
                      }
                      console.log("this.salesOrder=>",this.salesOrder)
                      this.$nextTick(() => {
                            setTimeout(()=>{
                                this.print();
                            },2000)
                        });
                  } else {
                      this.$vs.notify({
                          color: "danger",
                          title: "Error",
                          text: resp.message,
                          position: "top-right",
                          iconPack: "feather",
                          icon: "icon-x-circle"
                      });
                  }
                //   this.print()
              });
        },
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1);
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        terbilang(angka) {
            return angkaTerbilang(angka).toUpperCase() + " RUPIAH"
        },
        capitalize(text){
            const word = text.toLowerCase().split(" ");
            for (var i = 0; i < word.length; i++) {
                word[i] = word[i].charAt(0).toUpperCase() + word[i].slice(1);
            }
            const new_text = word.join(" ");
            return new_text
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD.MM.YYYY');
            };
        },
    },
}
</script>