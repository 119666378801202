<!-- form mix2 -->
<template>
    <div class="vx-col w-11/12">
      <div class="vx-col w-11/12 ml-auto mr-auto">
        <span>
          View Customer
        </span>
        <vs-tabs>
          <vs-tab label="General" class="pb-20">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input v-if="data.codeExternal == ''" class="w-full readonly" label="Code" v-model="data.code" readonly/>
                <vs-input v-else class="w-full readonly" label="Code" v-model="data.codeExternal" readonly/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="NOO Code"
                  v-model="data.codeNoo"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="External Code"
                  v-model="data.codeExternal"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Territory*"
                  v-model="data.territory"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Sales*"
                  v-model="data.sales"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Outlet Name*"
                  name="Name"
                  v-model="data.name"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Date of Birth"
                  name="Name"
                  v-model="data.date_of_birth"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="NIK*"
                  v-model="data.nik"
                  readonly
                  name="NIK"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Nik Name"
                  v-model="data.nik_name"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Status"
                  v-model="data.status"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Created On"
                  v-model="data.created_at"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Distribution Channel Matrix*"
                  v-model="data.matrix_customer_group"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Customer Group 1*"
                  v-model="data.cg1"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Customer Group 2*"
                  v-model="data.cg2"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Customer Group 3*"
                  v-model="data.cg3"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Pricing Group*"
                  v-model="data.pricing_group"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Risk Category*"
                  v-model="data.risk_category"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Payment Type*"
                  v-model="data.payment_type"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Payment Term*"
                  v-model="data.payment_term"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Credit Limit*"
                  v-model="data.credit_limit"
                  name="CreditLimit"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Delivery Type*"
                  v-model="data.delivery_type"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Priority*"
                  v-model="data.priority"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Region SD*"
                  v-model="data.region_sd"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Status Block</label>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      v-model="StatusBlock.code"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">History Approval & Notes</vs-divider>
            <vs-table
              stripe
              border
              description
              :sst="true"
              :data="data.historyNotes"
            >
              <template slot="thead">
                <vs-th>Time</vs-th>
                <vs-th>User</vs-th>
                <vs-th>Notes</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td class="whitespace-no-wrap">{{ formatDateTime(tr.CreatedAt) }}</vs-td>
                  <vs-td class="whitespace-no-wrap">{{ tr.personal_name }}</vs-td>
                  <vs-td class="whitespace-no-wrap">{{ tr.notes }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
          <vs-tab label="Address">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Country*"
                  v-model="data.country"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Province*"
                  v-model="data.province"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="City*"
                  v-model="data.city"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="District*"
                  v-model="data.district"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Subdistrict*"
                  v-model="data.subdistrict"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Address*"
                  v-model="data.address"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Postal Code*"
                  v-model="data.postal_code"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Transport Zone"
                  v-model="data.transport_zone"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="RT / RW (Format 000/000)"
                  v-model="data.rt_rw"
                  name="RT/RW"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Street No"
                  v-model="data.street_no"
                  readonly
                />
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Lat Long">
            <div class="tab-text">
              <map-component :data="data.map"></map-component>
            </div>
          </vs-tab>
          <vs-tab label="Contact">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Contact Name*"
                  v-model="data.contact_name"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Phone*"
                  v-model="data.phone"
                  name="Phone"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Mobile*"
                  v-model="data.mobile"
                  name="Mobile"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Email"
                  v-model="data.email"
                  name="Email"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Fax"
                  v-model="data.fax"
                  name="Fax"
                  readonly
                />
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Tax & Invoice">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  label="Max Open Bill* (0 = unlimited)"
                  v-model="data.max_open_bill"
                  name="MaxOpenBill"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Invoice Model*</label>
                <br />
                <ul class="centerx">
                  <li>
                    <vs-radio
                      v-model="data.invoice_model"
                      vs-value="When Shipping"
                      disabled
                      >When Shipping</vs-radio
                    >&nbsp;&nbsp;&nbsp;
                  </li>
                  <li>
                    <vs-radio
                      v-model="data.invoice_model"
                      vs-value="Separated"
                      disabled
                      >Separated</vs-radio
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Tax Collector</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.is_tax_collector"
                  disabled
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Tax Exempt</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.is_tax_exempt"
                  disabled
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">PKP</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.is_pkp"
                  disabled
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Gross up</label>
                <vs-switch
                  color="dark"
                  icon-pack="feather"
                  vs-icon-on="icon-check-circle"
                  vs-icon-off="icon-slash"
                  v-model="data.gross_up"
                  disabled
                >
                  <span slot="on">YES</span>
                  <span slot="off">NO</span>
                </vs-switch>
              </div>
            </div>
            <template>
              <div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      label="Tax Type"
                      v-model="data.tax_type"
                      name="Tax Type"
                      readonly
                    />
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      label="NPWP Type"
                      v-model="data.npwp_type"
                      name="NPWP Type"
                      readonly
                    />
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      label="NPWP"
                      v-model="data.npwp"
                      name="NPWP"
                      readonly
                    />
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      label="NPWP Name"
                      v-model="data.npwp_name"
                      readonly
                    />
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      label="SPPKP"
                      v-model="data.sppkp"
                      name="SPPKP"
                      readonly
                    />
                  </div>
                </div>
                <div class="vx-row mb-3">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      label="Tax Address"
                      v-model="data.tax_address"
                      name="TaxAddress"
                      readonly
                    />
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full readonly"
                      label="Domisili"
                      v-model="data.domisili"
                      name="domisili"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </template>
          </vs-tab>
          <vs-tab label="Upload">
            <vs-divider style="width: 100%; margin-left: 0%">KTP Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full" @click="showImage('KTP')">
                <img style="max-height: 170px; max-width: auto" :src="data.urlKtp" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">Outlet Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <img style="max-height: 170px; max-width: auto" :src="data.urlOutlet" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">NPWP Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full" @click="showImage('NPWP')">
                <img style="max-height: 170px; max-width: auto" :src="data.urlNpwp" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">SPPKP Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full" @click="showImage('SPPKP')">
                <img style="max-height: 170px; max-width: auto" :src="data.urlSppkp" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">Invoice Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <img style="max-height: 170px; max-width: auto" :src="data.urlInvoice" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">CL Image</vs-divider>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <img style="max-height: 170px; max-width: auto" :src="data.urlCl" />
              </div>
            </div>
            <vs-divider style="width: 100%; margin-left: 0%">Additional File</vs-divider>
            <!-- additional file -->
            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
              <table class="vs-table vs-table--tbody-table">
                <template v-for="(tr, i) in data.additionalFile">
                  <tr
                    class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.name"
                  >
                    <td class="td vs-table--td">{{ tr.name }}</td>
                    <td class="td vs-table--td">
                      <vx-tooltip text="Download" v-if="tr.path != ''">
                        <vs-button
                          type="line"
                          icon-pack="feather"
                          @click.stop="downloadFileAwsS3(tr.path)"
                          icon="icon-download"
                        />
                      </vx-tooltip>
                    </td>
                    <td class="td vs-table--td">
                      <template>
                        <vx-tooltip text="Delete">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            color="red"
                            @click.stop="handleDeleteAttachment(tr.ID, i)"
                            icon="icon-trash"
                          />
                        </vx-tooltip>
                      </template>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </vs-tab>
          <vs-tab label="Virtual Account">
            <template>
              <div class="vx-row mb-2">
                <div class="vx-col w-6/12">
                  <label class="vs-input--label">Bank</label>
                </div>
                <div class="vx-col w-3/12">
                  <label class="vs-input--label">VA Number</label>
                </div>
              </div>
              <div class="vx-col sm:w-12/12 w-full" v-for="(tr, indextr) in data.dataVa" :key="indextr">
                <div class="vx-row mb-2">
                  <div class="vx-col w-6/12">
                    <vs-input
                      class="w-full readonly"
                      v-model="data.dataVa[indextr].bank.Name"
                      name="VA Number"
                      placeholder="VA Number"
                      readonly
                    />
                  </div>
                  <div class="vx-col w-5/12">
                    <vs-input
                      class="w-full readonly"
                      v-model="data.dataVa[indextr].account_number"
                      name="VA Number"
                      placeholder="VA Number"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </template>
            <br><br><br><br><br>
          </vs-tab>
        </vs-tabs>
      </div>
      <vs-prompt
        :active.sync="activeModalImage"
      >
        <div class="con-exemple-prompt">
          <img style="max-height: 800px; max-width: 900px; margin:auto; display: block;" :src="urlShow" />
        </div>
        <div>
          <vs-input
            class="w-full"
            :label="labelDialog"
            v-model="valueDialog"
            readonly
          />
        </div>
      </vs-prompt>
    </div>
  </template>
  <script>
  import PerfectScrollbar from "vue-perfect-scrollbar";
  import PaymentMethod from "../form-component/PaymentMethod";
  import BussinessPartner from "../form-component/BussinessPartner";
  import Status from "../form-component/Status";
  import PriorityType from "../form-component/PriorityType";
  import AddressComponent from "@/components/master/Address";
  import Datepicker from "vuejs-datepicker";
  import MapComponent from "../form-component/Map";
  import moment from "moment";

  export default {
    components: {
      PerfectScrollbar,
      PaymentMethod,
      BussinessPartner,
      Status,
      PriorityType,
      AddressComponent,
      Datepicker,
      MapComponent,
    },
    props: {
      customerId: {
        type: Number,
      },
    },
    data() {
      return this.initialState();
    },
    methods: {
      formatDate(s) { //
        const m = moment.parseZone(s);
        return m.format("YYYY-MM-DD");
      },
      formatDateTime(s){
        const m = moment.parseZone(s);
        return m.format('YYYY-MM-DD HH:mm:ss')
      },
      showImage(type) {
        if (type == "KTP") {
          this.urlShow = this.data.urlKtp
          this.valueDialog = this.data.nik_name + " / " + this.data.nik
        } else if (type == "NPWP") {
          this.urlShow = this.data.urlNpwp
          this.valueDialog = this.data.npwp+ " / " + this.data.tax_address
        } else if (type == "SPPKP") {
          this.urlShow = this.data.urlSppkp
          this.valueDialog = this.data.npwp+ " / " + this.data.tax_address
        }

        this.labelDialog = type
        this.activeModalImage = true
      },
      codeWithDescription ({ description, code }) {
        if (description != undefined) {
          return `${code} - ${description}`
        }
        return `${code}`
      },
      codeWithName ({ name, code }) {
        if (name != undefined) {
          return `${code} - ${name}`
        }
        return `${code}`
      },
      customLabelHierarchy({ hierarchy_code, hierarchy_name }) {
        if (hierarchy_name != undefined) {
          return `${hierarchy_code} - ${hierarchy_name}`
        }
        return `${hierarchy_code}`
      },
      initialState() {
        return {
          urlShow: "",
          activeModalImage: false,
          valueDialog: "",
          labelDialog: "",
          baseUrl: "/api/oms/v1/sfa-master/customer",
          baseUrlUpdate: "/api/oms/v1/sfa-master/customer-web",
          baseUrlMatrixCg: "/api/oms/v1/sfa-master/matrix-customer-group-selected",
          baseUrlMatrixCustomerGroup: "/api/oms/v1/sfa-master/matrix-customer-group/",
          dataLoaded: false,
          status_disabled: false,
          data: {
            code: "auto generate",
            codeNoo: "auto generate",
            codeExternal: "",
            territory: "",
            sales: "",
            name: "",
            date_of_birth: "",
            nik: "",
            nik_name: "",
            status: "",
            created_at: "auto",
            matrix_customer_group: "",
            cg1: "",
            cg2: "",
            cg3: "",
            pricing_group: "",
            risk_category: "",
            payment_type: "",
            payment_term: "",
            credit_limit: 0,
            delivery_type: "",
            priority: "",
            region_sd: "",
            historyNotes: [],
            country: "",
            province: "",
            city: "",
            district: "",
            subdistrict: "",
            address: "",
            postal_code: "",
            transport_zone: "",
            rt_rw: "",
            street_no: "",
            map: {
              latitude: "",
              longitude: "",
            },
            contact_name: "",
            phone: "",
            mobile: "",
            email: "",
            fax: "",
            max_open_bill: "0",
            invoice_model: "",
            is_tax_collector: false,
            is_tax_exempt: false,
            is_pkp: false,
            gross_up: false,
            tax_type: "",
            npwp_type: "",
            npwp: "",
            npwp_name: "",
            sppkp: "",
            tax_address: "",
            domisili: "",
            dataVa: [
              {
                "bank": [],
                "account_number": ""
              }
            ],
            urlKtp: "",
            urlOutlet: "",
            urlNpwp: "",
            urlSppkp: "",
            urlInvoice: "",
            urlCl: "",
            additionalFile: [],
          },
          StatusBlock: { id: 0, code: "0" },
          StatusBlockOption: [
            { id: 0, code: "0 - Un-Block" },
            { id: 1, code: "1 - Order Block" },
            { id: 2, code: "2 - Return Block" },
            { id: 3, code: "3 - All Block" }
          ],
        };
      },
      isNumber (evt) {
        const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'];
        const keyPressed = evt.key;

        if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault()
        }
      },
      notify(color, title, message){
        this.$vs.notify({
          color: color,
          title: title,
          text: message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      },
      getDataTaxType() {
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/tax-type", {
            params: {
              order: "code",
              sort: "asc",
              is_customer: true,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.taxType.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      handleClose() {
        window.scrollTo(0, 0);
        this.$emit("close");
      },
      getRegionSd(code) { //
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/region-sd/"+code)
          .then((resp) => {
            if (resp.status == "success") {
              this.data.region_sd = resp.data.code + " " + resp.data.description
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getTransportZone(cityID) { //
        this.$vs.loading();
        let param = {
          length: "all",
          search: this.search,
          city_id: cityID,
        };
        this.$http
          .get("/api/oms/v1/setting-user/city", {
            params: param,
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.data.transport_zone = resp.data.records[0].transport_zone
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getTaxType(id) { //
        this.$vs.loading();
        this.$http.get("/api/oms/v1/sfa-master/tax-type/" + id).then((resp) => {
          if (resp.status == "success") {
            this.data.tax_type = resp.data.code + " " + resp.data.name
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      getDeliveryType(id) { //
        this.$vs.loading();
        this.$http.get("/api/oms/v1/sfa-master/delivery-type/" + id).then((resp) => {
          if (resp.status == "success") {
            this.data.delivery_type = resp.data[0].name
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      getMatrixCg(id) {
        this.$vs.loading();
        this.$http
        .get(this.baseUrlMatrixCustomerGroup+id)
        .then((resp) => {
          if (resp.status == "success") {
            this.data.matrix_customer_group = resp.data.hierarchy_code + " " + resp.data.hierarchy_name
            this.data.cg1 = resp.data.cg1_code + " " + resp.data.cg1_name
            this.data.cg2 = resp.data.cg2_code + " " + resp.data.cg2_name
            this.data.cg3 = resp.data.cg3_code + " " + resp.data.cg3_name
            this.data.pricing_group = resp.data.pricing_code + " " + resp.data.pricing_name
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      getPaymentTerm(id) { //
        this.$vs.loading();
        this.$http.get("/api/oms/v1/sfa-master/payment-term/" + id).then((resp) => {
          if (resp.status == "success") {
            this.data.payment_term = resp.data.name
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      getRiskCategory(code) { //
        this.$vs.loading();
        this.$http
          .get("/api/oms/v1/sfa-master/customer-risk-category/"+code)
          .then((resp) => {
            if (resp.status == "success") {
              this.data.risk_category = resp.data.code + " " + resp.data.description
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataTerritory(id) {
        this.$vs.loading();
        this.$http.get("/api/oms/v1/setting-user/territory/" + id).then((resp) => {
          if (resp.status == "success") {
            this.data.territory = resp.data.code + " " + resp.data.name;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      getDataSales(personalID) { // 
        this.$vs.loading();
        let url = "/api/oms/v1/sfa-master/personal/"
        this.$http
          .get(url + personalID)
          .then((resp) => {
            if (resp.status == "success") {
              this.data.sales = resp.data.CodeExternal + " " + resp.data.Name
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      setUrl(type, url) {
        if(type == "ktp"){
          this.data.urlKtp = url
        } else if(type == "npwp"){
          this.data.urlNpwp = url
        } else if(type == "sppkp"){
          this.data.urlSppkp = url
        } else if(type == "outlet"){
          this.data.urlOutlet = url
        } else if(type == "invoice"){
          this.data.urlInvoice = url
        } else if(type == "cl"){
          this.data.urlCl = url
        }
      },
      getData() {
        this.$vs.loading();
        if (this.customerId) {
          this.$http
            .get(this.baseUrl + "/" + this.customerId)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.data.dataVa = []
                if (resp.data.customer_account != null) {
                  this.data.dataVa = resp.data.customer_account
                }

                this.StatusBlock = this.StatusBlockOption.find(
                  option => option.id === resp.data.status_block
                );

                this.data.code = resp.data.code;
                this.data.codeExternal = resp.data.code_external;
                this.data.codeNoo = resp.data.code_noo;
                if (resp.data.customer_address.territory_id) {
                  this.getDataTerritory(resp.data.customer_address.territory_id);
                }
                if (resp.data.customer_address.sales_personal_id) {
                  this.getDataSales(resp.data.customer_address.sales_personal_id);
                }
                this.data.name = resp.data.name;
                this.data.date_of_birth = this.formatDate(resp.data.date_of_birth);
                this.data.nik = resp.data.nik;
                this.data.nik_name = resp.data.nik_name;
                this.data.status = resp.data.status;
                this.data.created_at = this.formatDateTime(resp.data.CreatedAt);
                this.getMatrixCg(resp.data.matrix_customer_group_id)
                this.getRiskCategory(resp.data.risk_category_code);
                this.data.payment_type = resp.data.payment_type
                this.getPaymentTerm(resp.data.payment_term_id);
                this.data.credit_limit = (resp.data.customer_limit.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.getDeliveryType(resp.data.delivery_type_id);
                this.data.priority = resp.data.priority_type;
                this.getRegionSd(resp.data.region_sd_code)
                this.data.historyNotes = resp.data.history_note;

                // =====================================================
                this.data.country = resp.data.country;
                this.data.province = resp.data.province;
                this.data.city = resp.data.city;
                this.data.district = resp.data.district;
                this.data.subdistrict = resp.data.sub_district;
                this.data.address = resp.data.address;
                this.data.postal_code = resp.data.postal_code;
                this.getTransportZone(resp.data.city_id)
                this.data.rt_rw = resp.data.rt_rw;
                this.data.street_no = resp.data.street_no;

                // =====================================================
                this.data.map.latitude = resp.data.latitude;
                this.data.map.longitude = resp.data.longitude;

                // =====================================================
                this.data.contact_name = resp.data.contact_name;
                this.data.phone = resp.data.phone;
                this.data.mobile = resp.data.mobile;
                this.data.email = resp.data.email;
                this.data.fax = resp.data.fax;

                // =====================================================
                this.data.max_open_bill = resp.data.max_open_bill;
                this.data.invoice_model = resp.data.invoice_model;
                if (resp.data.is_tax_collector == "1") {
                  this.data.is_tax_collector = true;
                } else {
                  this.data.is_tax_collector = false;
                }
                if (resp.data.is_tax_exempt == "1") {
                  this.data.is_tax_exempt = true;
                } else {
                  this.data.is_tax_exempt = false;
                }
                if (resp.data.is_pkp == "1") {
                  this.data.is_pkp = true;
                } else {
                  this.data.is_pkp = false;
                }
                this.data.gross_up = false;
                if (resp.data.gross_up == 1) {
                  this.data.gross_up = true;
                }
                this.getTaxType(resp.data.tax_type_id);
                this.data.npwp_type = resp.data.npwp_type;
                this.data.npwp = resp.data.npwp;
                this.data.npwp_name = resp.data.npwp_name;
                this.data.sppkp = resp.data.sppkp;
                this.data.tax_address = resp.data.tax_address;
                this.data.domisili = resp.data.domisili;

                // ======================================================
                var setUrl = this.setUrl;
                resp.data.customer_image.forEach(function (item) {
                  setUrl(item.description, item.photo_url);
                }, setUrl);

                this.data.additionalFile = resp.data.additional_file;
              } else {
                this.notify("danger", "Error", resp.message);
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      },
    },
    mounted() {
      if (this.customerId) {
        this.getData()
      } else {
        this.status_disabled = false
      }
    },
    computed: {},
    watch: {
      customerId(v) {
        Object.assign(this.$data, this.initialState());
        if (v) {
          this.getData();
        }
      },
    },
  };
</script>
<style >
.ps {
  height: 800px;
}

.readonly > div > input {
  background-color: #ebebeb;
}

.vs-dialog {
  max-width: 50% !important;
  max-height: 70% !important;
}
</style>
