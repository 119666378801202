<template>
  <div class="vx-row mb-2">
    <div class="core vx-col w-full mb-base">
      <div v-for="(item, index) in data.records.all_visits" :key="index" class="my-2">
        <span class="text-lg text-bold">{{ item.schedule_date }}</span>
        <vs-table
          stripe
          border
          :data="item.visits"
          :sst="false"
          :max-items="item.visits.length"
          class="mt-2"
        >
          <template slot="thead">
            <vs-th>Visit Date</vs-th>
            <vs-th>Customer</vs-th>
            <vs-th>Route Status</vs-th>
            <vs-th>Start Time</vs-th>
            <vs-th>End Time</vs-th>
            <vs-th>Duration (minute)</vs-th>
            <vs-th>Visited</vs-th>
            <vs-th>Effective Call</vs-th>
            <vs-th>Document Reference</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ tr.visit_date }}</vs-td>
              <vs-td>{{ tr.customer_code +" - "+tr.customer_name }}</vs-td>
              <vs-td>{{ tr.route_status }}</vs-td>
              <vs-td>{{ tr.start_time }}</vs-td>
              <vs-td>{{ tr.end_time }}</vs-td>
              <vs-td>{{ tr.duration }}</vs-td>
              <vs-td>{{ tr.is_visited }}</vs-td>
              <vs-td>{{ tr.effective_call }}</vs-td>
              <vs-td>{{ tr.document_reference }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
